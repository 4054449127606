import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeleteTicketComponent} from './delete-ticket/delete-ticket.component';

const routes: Routes = [{ path: 'delete-ticket', component: DeleteTicketComponent },];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeleteTicketRoutingModule { }
