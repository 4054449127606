
<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" class="toastZindex" key="tr"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top" >
          <div class="card-body">
            <div class="float-lg-left">
              <h4 class="card-title">Home > User > Create</h4>
            </div>
            <div class="float-lg-right" *ngIf="loginUserId==user.userId">
              <button type="button" class="btn btn-primary btn-sm" (click)="ChangePassword()">Change Password</button>
            </div>
            <div class="float-lg-right">
              <button type="button" class="btn btn-primary btn-sm" style="margin-right: 8px;" (click)="GoToUserPage()">Back</button>
            </div>
          </div>  
        </div>
        <div class="card">
          <div class="card-body border-primary-top">
            <form class="form-sample" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
              <div class="row">
                <div class="form-group col-4">
                  <label>First Name</label>
                  <input type="text" name="firstName" class="form-control" [(ngModel)]="user.firstName" #firstName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required>
                  <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                    <div *ngIf="firstName.errors.required">First Name is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Last Name</label>
                  <input type="text" name="lastName" class="form-control" [(ngModel)]="user.lastName">
                </div>
                <div class="form-group col-4">
                  <label>Email</label>
                  <input type="email" name="email" class="form-control" [(ngModel)]="user.email" #email="ngModel" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                         [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required>
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.invalid">Email must be a valid email address</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Mobile No</label>
                  <input type="text" name="mobileNo" class="form-control" [(ngModel)]="user.mobileNo" #mobileNo="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && mobileNo.invalid }" required>
                  <div *ngIf="f.submitted && mobileNo.invalid" class="invalid-feedback">
                    <div *ngIf="mobileNo.errors.required">Phone No is required</div>
                  </div>
                </div>
                <div class="form-group col-4">
                  <label>Role</label>
                  <select name="roleId" class="form-control" [(ngModel)]="user.roleId" #roleId="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && user.roleId==0 }"
                          required>
                    <option value="0">Select Role Type</option>
                    <option *ngFor="let obj of roleList" [ngValue]="obj.roleId">{{obj.roleName}}</option>
                  </select>
                  <div *ngIf="f.submitted && user.roleId==0" class="invalid-feedback">
                    <div *ngIf="user.roleId==0">Role Type is required</div>
                  </div>
                </div>

                <div class="col-md-4">
                  <label for="validationServer05" class="form-label">Status</label>
                  <div *ngIf="loginUserId==user.userId">
                    <div style="margin-top: 12px;">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline1" name="isActive" disabled="disabled" class="custom-control-input"
                               [(ngModel)]="user.isActive"
                               #isActive="ngModel"
                               [value]="true">
                        <label class="custom-control-label" for="customRadioInline1">Active</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline2" name="isActive"  disabled="disabled" class="custom-control-input"
                               [(ngModel)]="user.isActive"
                               #isActive="ngModel"
                               [value]="false">
                        <label class="custom-control-label" for="customRadioInline2">Inactive</label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="loginUserId!=user.userId">
                    <div style="margin-top: 12px;">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline1" name="isActive" class="custom-control-input"
                               [(ngModel)]="user.isActive"
                               #isActive="ngModel"
                               [value]="true">
                        <label class="custom-control-label" for="customRadioInline1">Active</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline2" name="isActive" class="custom-control-input"
                               [(ngModel)]="user.isActive"
                               #isActive="ngModel"
                               [value]="false">
                        <label class="custom-control-label" for="customRadioInline2">Inactive</label>
                      </div>
                    </div>
                  </div>
                
                </div>
                <div class="col-md-12 headerChild" style="margin-bottom: 10px;">
                  <div class="col-md-4" style="margin-top:4px;">
                    <label style="margin-bottom: 4px;" class="form-label">User Credentials</label>
                  </div>
                </div>
                <div class="form-group col-4">
                  <label>User Name</label>
                  <input type="text" name="userName" class="form-control" [disabled]="user.userId>0" [(ngModel)]="user.userName" #userName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && userName.invalid }" required>
                  <div *ngIf="f.submitted && userName.invalid" class="invalid-feedback">
                    <div *ngIf="userName.errors.required">First Name is required</div>
                  </div>
                </div>
                <div class="form-group col-4">
                  <label>Password</label>
                  <input type="password" name="passKey" class="form-control" [disabled]="user.userId>0" [(ngModel)]="user.passKey" #passKey="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && passKey.invalid }" required>
                  <div *ngIf="f.submitted && passKey.invalid" class="invalid-feedback">
                    <div *ngIf="passKey.errors.required">First Name is required</div>
                  </div>
                </div>

              </div>
              <div class="col-12">

                <button class="btn btn-primary float-right ml-2" (click)="GoToUserPage()" type="button">Cancel</button>
                <button class="btn btn-primary float-right" type="submit">Save</button>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<p-dialog [contentStyle]="{'height': '150px','width':'950px' }" [(visible)]="changePasswordPopup" header="Password Change"
          [closable]="false" [modal]="true">
  <div style="margin-top: 10px;">
    <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;">
      
      <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
        <tbody>
          <tr>
            <td>
              User Name
            </td>
            <td>
              {{loginUserName}}
            </td>
            </tr>
            <tr>
            <td >
              Password
            </td>
            <td >
              <input type="password" name="passKey" class="form-control"  [(ngModel)]="passwordChange">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton label="Update" (click)="savePassword()" ></button>
    <button type="button" pButton  label="Close" (click)="ClosePassword()" ></button>
  </p-footer>
</p-dialog>

