import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import { PrintSetting } from '../../model/Setting';

@Injectable({
  providedIn: 'root'
})

export class PrintSettingService {
  header: HttpHeaders;
  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });
  }

  SavePrintSetting(printSetting: PrintSetting) {
    const url = `${this._auth.baseURL}PrintSetting/SavePrintSetting`;
    let headers = this.header;
    return this.http.post<any>(url, printSetting, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  GetPrintSetting(placeId: number) {
    const url = `${this._auth.baseURL}PrintSetting/GetPrintSetting/` + placeId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }
}
