import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
//import { LoginModule } from './login/login.module';
import { DashboardModule } from './Modules/dashboard/dashboard.module';
import { HomeModule } from './Modules/home/home.module';
//import { OnlineticketModule} from './Modules/onlineticket/onlineticket.module'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule
    ,FormsModule
    ,BrowserModule
    ,HttpClientModule
    //,LoginModule
    , DashboardModule
    , HomeModule
    //, OnlineticketModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
