<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Home </span> ><span>User</span></h4>
            </div>
            <div class="float-lg-right" style="padding: 15px;" *ngIf="roleName=='Developer' || roleName=='Admin'">
              <button type="button" (click)="Navigate('create-user',0)" pTooltip="Add New User" class="btn btn-primary btn-sm">Add</button>
            </div>
          </div>
        </div>

        <p-confirmDialog [style]="{width: '40vw'}" [baseZIndex]="10000"></p-confirmDialog>

        <div class="card cardheight border-primary-top">
          <div class="card-body main-content">
            <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;">
              <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                <thead>
                  <tr>
                    <th class="tabltth">Name</th>
                    <th>Email</th>
                    <th>Mobile No</th>
                    <th>Role Name</th>
                    <th>UserName</th>
                    <th>Place Name</th>
                    <th>Status</th>
                    <th>Created/Updated</th>
                    <th>Created/Updated Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of listUserViewModel">
                    <td>{{obj.firstName}} {{obj.lastName}}</td>
                    <td>{{obj.email}}</td>
                    <td>{{obj.mobileNo}}</td>
                    <td>{{obj.roleName}}</td>
                    <td>{{obj.userName}}</td>
                    <td>{{obj.placeName}}</td>

                    <td>
                      <span *ngIf="obj.isActive"><label class="badge badge-success">Active</label></span>
                      <span *ngIf="!obj.isActive"><label class="badge badge-danger">DeActive</label></span>
                    </td>
                    <td>
                      <span><b>By </b>{{obj.createdBy}}</span><br />
                      <span><b>By </b>{{obj.updatedBy}}</span>
                    </td>
                    <td>
                      <span><b>On </b>{{obj.createdDate | date:'dd-MM-yyyy hh:mm:ss'}}</span><br />
                      <span><b>On </b>{{obj.updatedDate | date:'dd-MM-yyyy hh:mm:ss'}}</span>
                    </td>
                    <td>
                      <div>
                        <i (click)="GetUserPlaceMapping(obj.userId)" *ngIf="obj.roleName!=roleName" class="ti-link editIcon iconLinkColor" pTooltip="Mapping With Place">
                        </i>
                        <i (click)="EditUser(obj.userId)" pTooltip="Edit" class="ti-pencil editIcon iconEditColor"></i>
                        <i *ngIf="obj.isShowDelete" class="ti-trash deleteIcon iconDeleteColor" pTooltip="Delete" (click)="DeleteUserconfirm(obj.userId)"></i>
                      </div>

                    </td>
                  </tr>
                </tbody>

              </table>


            </div>

          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
  <p-dialog [contentStyle]="{'height': '400px','width':'950px' }" [(visible)]="showDialog" header="Place Mapping"
            [closable]="false" [modal]="true">
    <div style="margin-top: 10px;">
      <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;">
        <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
          <thead>
            <tr>
              <th class="tabltth">Place Name</th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let obj of listUserPlaceMapping">
              <td >
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" [disabled]="obj.isNotAvilable" [checked]="obj.isSelected" (change)="obj.isSelected = !obj.isSelected" ([ngModel])="obj.isSelected">
                    {{obj.companyName}}
                    <i class="input-helper"></i>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
          <!--<tbody *ngIf="roleName=='Admin'">
            <tr>
              <td>
                <div style="margin-top: 12px;">
                 
                  <select name="PlaceId" class="form-control" [(ngModel)]="dropdowncompanyId" (change)="CheckBoxChange()">
                    <option value="0">Select Place</option>
                    <option *ngFor="let obj of listUserPlaceMapping" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>-->

        </table>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton (click)="SaveUpdateUserPlaceMapping()" label="Save"></button>
      <button type="button" pButton (click)="showDialog=false" label="Close"></button>
    </p-footer>
  </p-dialog>
</div>
