import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../Modules/Service/CommonService';
import { authenticationService } from '../../login/authenticationService.service';
import {Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: string;
  roleName: string;
  constructor(public cmnSrv: CommonService, private authservice: authenticationService,private router:Router) { }

  ngOnInit(): void {
    this.userName = this.authservice.username;
    this.roleName = this.authservice.userRole;
    if (this.roleName == undefined) {
      this.roleName = '';
    }
  }

  Logout() {
    this.authservice.logout();
    this.router.navigateByUrl("/");
  }
}
