import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlineticketComponent } from './onlineticket/onlineticket.component';
import { PaymentSuccessfulComponent } from './payment-successful/payment-successful.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';


const routes: Routes = [
  { path: 'online-ticket', component: OnlineticketComponent }
  , { path: 'payment-successful/:order_id', component: PaymentSuccessfulComponent }
  , { path: 'payment-failed', component: PaymentFailedComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlineticketRoutingModule { }
