import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from '../../Service/userService';
import { authenticationService } from '../../../login/authenticationService.service';
import { UserViewModel,UserPlaceMapping} from '../../../model/userModel';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [ConfirmationService]
})
export class UsersComponent implements OnInit {
  showLoader: boolean;
  listUserViewModel: Array<UserViewModel>;
  listUserPlaceMapping: Array<UserPlaceMapping>;
  loginUserId: number;
  dropdowncompanyId: number=0;
  public showDialog: boolean;
  roleName: string;
  constructor(private router: Router
    , private confirmationService: ConfirmationService
    , private messageService: MessageService
    , private userService: UserService
    , private authService: authenticationService) { }

  ngOnInit(): void {
    document.body.className = "sidebar-icon-only";  
    this.showLoader = false;
    this.roleName = this.authService.userRole;
    console.log(this.roleName);
    this.listUserViewModel = new Array<UserViewModel>();
    this.listUserPlaceMapping = new Array<UserPlaceMapping>();
    this.loginUserId = this.authService.userId;
    this.GetAllUsers();
  }

  Navigate(e, id) {
    if (e == 'create-user' && id == 0) {
      this.router.navigate(['create-user']);
    }
    else if (e == 'create-user' && id > 0) {
      this.router.navigate(['create-user',id]);
    }
  }

  GetAllUsers() {
    this.showLoader = true;
    this.userService.GetAllUser(this.loginUserId).subscribe(m => {
      if (m.statusCode = 200) {
        this.listUserViewModel = m.result; this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get All users successfully.' });
        })
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  EditUser(userId: number) {
    this.Navigate('create-user', userId);
  }
  DeleteUserconfirm(userId: number) {

  }

  GetUserPlaceMapping(userId: number) {
    this.showLoader = true;
    this.userService.GetUserPlaceMapping(userId,this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this.listUserPlaceMapping = m.result;
        this.showLoader = false;
        this.showDialog = true;
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  SaveUpdateUserPlaceMapping() {
    this.showLoader = true;
    this.userService.saveUpdatePlaceUserMapping(this.listUserPlaceMapping).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
        this.showDialog = false;
        this.GetAllUsers();
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  CheckBoxChange() {
    this.listUserPlaceMapping.forEach((value) => { 
      if (value.companyId == this.dropdowncompanyId) {
        value.isSelected = true;
      }
    }) 
    
  }


}
