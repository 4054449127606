import { Component, OnInit, APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { roleModel } from '../../../model/roleModel';
import { RoleService } from './../../Service/RoleService';
import { CommonService } from '../../Service/CommonService';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.css']
})
export class RoleCreateComponent implements OnInit {
  toast: boolean; showLoader: boolean = false;
_roleModel = new roleModel();
  constructor(private _roleService: RoleService
    , private _commonService: CommonService
    , private route: ActivatedRoute
    , private router: Router
  ,private messageService: MessageService
  ) { }

  ngOnInit(): void {
    document.body.className = "sidebar-icon-only";  
    this._roleModel = new roleModel();
    var id = this.route.snapshot.params['id'];
    if (id) {
      this.GetSingleRole(id);
    }
  }

  onSubmit() {
    this.showLoader = true;
    this._roleService.saveRole(this._roleModel).subscribe(m => {
      if (m.statusCode == 200) {
        this._roleModel = new roleModel();
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Add/Update Role', detail: m.result });
        })
        this.router.navigate(['roles']);
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Add/Update Role', detail: m.result });
        })
      }
    })
  }

  GetSingleRole(id) {
    this._roleModel = new roleModel();
    this._roleService.getSingelRole(id).subscribe(m => {
      console.log(m.result);
      this._roleModel = m.result;
     
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'success', summary: 'Get Single', detail: 'Get single record succefully.' });
      })
    })
  }


  GoToRolesPage() {
    this.router.navigate(['roles']);
  }
}
