export class StringHelper {
  static isAvailable(value: string): boolean {
    if (value == null) {
      return false;
    }

    if (value.trim() === "") {
      return false;
    }

    return true;
  }

  static throwIsAvailableError(value: string, propertyName: string): void {
    if (!this.isAvailable(value)) {
      throw new Error(`The property '${propertyName}' cannot be null, empty, or whitespace.`);
    }
  }

  static clean(value: string): string {
    const cleanValue = this.isAvailable(value)
      ? value.trim()
      : "";

    return cleanValue;
  }

  static removeNonAlphaNumericCharacters(value: string): string {
    if (!this.isAvailable(value)) {
      return "";
    }

    // This will also keep underscores.
    // https://stackoverflow.com/a/9364527/2573621
    return value.replace(/\W/g, "");
  }

  static replaceAll(value: string, find: string, replace: string): string {
    // https://stackoverflow.com/a/1144788/2573621
    const safeFind = StringHelper.escapeRegExp(find);
    const regEx = new RegExp(safeFind, "g");
    const result = value.replace(regEx, replace);
    return result;
  }

  static toTitleCase(value: string) {
    const cleanValue = this.clean(value);
    if (!this.isAvailable(cleanValue)) {
      return "";
    }

    const words = cleanValue.split(/\s+/g);
    const titleCaseWords = words.map(word => {
      const firstLetter = word.substring(0, 1);
      const upperCaseFirstLetter = firstLetter.toUpperCase();

      const otherLetters = word.substring(1);
      const lowerCaseLetters = otherLetters.toLowerCase();

      const titleCaseWord = `${upperCaseFirstLetter}${lowerCaseLetters}`;
      return titleCaseWord;
    });

    const phrase = titleCaseWords.join(" ");
    return phrase;
  }

  private static escapeRegExp(value: string): string {
    const result = value.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return result;
  }
}
