import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RolesComponent } from './roles/roles.component';
import { RoleCreateComponent } from './role-create/role-create.component';


const routes: Routes = [
  { path: 'roles', component: RolesComponent },
  { path: 'role-create', component: RoleCreateComponent }
  , { path: 'role-create/:id', component: RoleCreateComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }
