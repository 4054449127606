import { Component, OnInit } from '@angular/core';
import { CompanyModelView } from '../../../model/comapny';
import { authenticationService } from '../../../login/authenticationService.service';
import { CompanyService } from '../../Service/CompanyService';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ReportService } from '../../Service/report-service.service';
import { ReportParam, TicketReport } from '../../../model/Report';
import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [ConfirmationService]
})
export class ReportsComponent implements OnInit {
  showLoader: boolean = false;
  StartDate: Date;
  EndDate: Date;
  TotalVisitor: number;
  TotalEquipment: number;
  TotalCoutnterAmount: number;
  TotalOnlineAmount: number;
  TotalAmount: number;

  dropdowncompanyId: number = 0;
  _companyViewModel: Array<CompanyModelView>;
  _reportParam = new ReportParam();
  _listReport = new Array<TicketReport>();

  constructor(private authService: authenticationService
    , private _companyService: CompanyService
    , private messageService: MessageService
    , private reportService: ReportService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.TotalVisitor = 0;
    this.TotalEquipment = 0;
    this.TotalAmount = 0;
    this.TotalCoutnterAmount = 0;
    this.TotalOnlineAmount = 0;
    this._listReport = new Array<TicketReport>();
    this.StartDate = new Date();
    this.EndDate = new Date();
    this._reportParam = new ReportParam();
    this._reportParam.startDate = this.convertDateToString(this.StartDate.toString());
    this._reportParam.endDate = this.convertDateToString(this.EndDate.toString());
    this._reportParam.loginUserId = this.authService.userId;
    this.dropdowncompanyId = 0;
    document.body.className = "sidebar-icon-only";
    this.GetUserPlaceMapping();
  }
  SetStartDate(e) {
    this._reportParam.startDate = this.convertDateToString(e);
    this.GetAllTicket();
  }

  SetEndDate(e) {
    this._reportParam.endDate = this.convertDateToString(e);
    this.GetAllTicket();
  }

  convertDateToString(date: string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }

  GetUserPlaceMapping() {
    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompany(this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
        if (this._companyViewModel != null && this._companyViewModel.length > 0) {
          this.dropdowncompanyId = 0;
          this._reportParam.companyId = this.dropdowncompanyId;
          this.GetAllTicket();
        }

        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    })
  }
  PlaceChangeEvent() {
    this._reportParam.companyId =this.dropdowncompanyId;
    this.GetAllTicket();
  }
  GetAllTicket() {
    this.TotalVisitor = 0;
    this.TotalEquipment = 0;
    this.TotalAmount = 0;
    this.TotalCoutnterAmount = 0;
    this.TotalOnlineAmount = 0;
    this._listReport = new Array<TicketReport>();
    this.showLoader = true;
    console.log(this._reportParam.companyId);
    this.reportService.GetAllTicket(this._reportParam).subscribe(m => {
      if (m.statusCode == 200) {
        this._listReport = m.result;
        this.showLoader = false;

        this.TotalVisitor = this._listReport.reduce((subtotal, item) => subtotal + item.totalVisitor, 0);
        this.TotalEquipment = this._listReport.reduce((subtotal, item) => subtotal + item.totalEquipment, 0);
        this.TotalCoutnterAmount = this._listReport.filter(x => x.ticketBuyFrom==1).reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);
        this.TotalOnlineAmount = this._listReport.filter(x => x.ticketBuyFrom == 2).reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);
        this.TotalAmount = this._listReport.reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);

        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Report get successfully.' });
        })
      }
      else {
        console.log(m.result);
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  ExportClass() {
    //this.showLoader = true;
    this.reportService.ExportReportCSV(this._reportParam).subscribe(response => {

      console.log(response);
      this.downLoadFile(response, "text/csv;charset=utf-8;")
      this.showLoader = false;

      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'success', summary: 'Success', detail: 'Report get successfully.' });
      })

    }, err => {
      console.log(err.message);
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    navigator.userAgent.indexOf('Chrome') == -1;
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "AISReport.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.showLoader = false;
  }

}
