import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { CommonService } from '../Service/CommonService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';

import { authenticationService } from '../../login/authenticationService.service';

import { VisitorCategoryRoutingModule } from './visitor-category-routing.module';
import { VisitorcategoryComponent } from './visitorcategory/visitorcategory.component';
import { CreateVisitorcategoryComponent } from './create-visitorcategory/create-visitorcategory.component';



@NgModule({
  declarations: [VisitorcategoryComponent, CreateVisitorcategoryComponent],
  imports: [
    CommonModule,
    VisitorCategoryRoutingModule,
    SharedModule
    , ToastModule
    , BrowserAnimationsModule
    , ConfirmDialogModule
    , TooltipModule
    , FormsModule
  ],
  providers: [MessageService, CommonService, authenticationService]
})
export class VisitorCategoryModule { }
