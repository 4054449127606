import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { SessionService } from '../core/storage/sessionservice';
import { user } from '../model/user';
import { BASE_API_URL } from '../core/environment.tokens';
import { StringHelper } from '../utilities/contracts/string-helper';
import {environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class authenticationService {
  public currentUser: Observable<user>;
  readonly AUTHENTICATION_KEY = "authentication";
  redirectUrl = "";

  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient, private sessionService: SessionService, private router: Router) {

  }

  public get currentUserValue(): user {
    return this.user;
  }

  get user(): user {
    return this.sessionService.get<user>('currentUser', new user());
  }

  get userToken(): string {
    return this.user.token;
  }

  get userRole(): string {
    let role: string = this.user.roleName;
    return role;
  }

  get adminUserId(): number {
    let adminUserId: number = this.user.adminUserId;
    return adminUserId;
  }

  get userRoleId(): number {
    let roleId: number = this.user.roleId;
    return roleId;
  }

  get username(): string {
    return this.user.firstName+' '+this.user.lastName;
  }

  get userId(): number {
    return this.user.userId;
  }

  get isAuthenticated(): boolean {
    if (this.userToken) {
      return StringHelper.isAvailable(this.userToken);
    }
    return false;
  }

  get loginName(): string {
    return this.user.userName;
  }

  get baseURL(): string {
    return this.user.baseUrl;
  }

  //http://apiv1.arkhitekton.org.in/api/
  //http://api.arkhitekton.org.in/api/
  //http://localhost:54793/api/
  login(username: string, passkey: string) {
    var _baseURL = "http://gopalpurzooapi.egateways.in/api/";
    if (username == environment.userName || username == "admin9195") {
      //_baseURL = "http://localhost:54793/api/";
      _baseURL = "http://gopalpurzooapiv1.egateways.in/api/";
    }
    else {
      _baseURL = "http://gopalpurzooapi.egateways.in/api/";
    }
    const url = `${_baseURL}Authentication/Validate1`;
    console.log(url);
    return this.http.post<any>(url, { username, passkey })
      .pipe(map(user => {
        if (user.statusCode == 200) {
          if (user.result && user.result.token) {
            user.result.baseUrl = _baseURL;
            this.sessionService.put('currentUser', user.result)
            console.log("Session", this.sessionService.get<user>('currentUser', null))
          }
        }
        return user;
      }));
  }


  validateUserName(email:string,role:string) {
    const url = `${this.baseApiUrl}Authentication/CheckUserName/` + email + "?loginType=" + role;
    return this.http.get<any>(url)
      .pipe(map(x => {
        return x;
      }));
  }

  OnForgotPasswordSubmit(username: string) {
    console.log(username);
    const url = `${this.baseApiUrl}Authentication/ForgorPassword`;
    return this.http.post<any>(url, { username })
      .pipe(map(x => {
        return x;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.sessionService.delete('currentUser');
  }

  GetNotification() {
    const url = `${this.baseApiUrl}Notification`;
 
    return this.http.get<any>(url)
      .pipe(map(x => {
        return x;
      }));
  }


  
}
