import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../../shared/shared.module';
import { CommonsModule } from '../../Common/common.module';

import { TicketRoutingModule } from './ticket-routing.module';
import { TicketsComponent } from './tickets/tickets.component';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CommonService } from '../Service/CommonService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';

import { DatePipe } from '@angular/common'

@NgModule({
  declarations: [TicketsComponent],
  imports: [
    CommonModule,
    TicketRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    CommonsModule,
    ToastModule,
    ConfirmDialogModule,
    TooltipModule,
    DialogModule,
    CalendarModule
  ],
  providers: [MessageService, CommonService, DatePipe]
})
export class TicketModule { }
