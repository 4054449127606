import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../../shared/shared.module';
import { CompanyRoutingModule } from './company-routing.module';
import { CommonsModule} from '../../Common/common.module';

import { CompanyComponent } from './company/company.component';
import { CreateCompanyComponent } from './create-company/create-company.component';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CommonService } from '../Service/CommonService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
@NgModule({
  declarations: [CompanyComponent, CreateCompanyComponent],
  imports: [
    CommonModule,
    CompanyRoutingModule,
    SharedModule,
    ToastModule,
    BrowserAnimationsModule,
    FormsModule,
    ConfirmDialogModule,
    TooltipModule,
    DialogModule,
    CommonsModule
  ],
  providers: [MessageService, CommonService]
})
export class CompanyModule { }
