
export class VisitorTickets {
  visitorsTicketDetails: VisitorsTicketDetails;
  visitorCategoryTypeDetailCollection: Array<VisitorCategoryTypeDetail>;
  ticketAmountDetails: TicketAmountDetails;
  cCVResponse: CCVResponse;
}

export class VisitorCategoryTypeDetail {
  public categoryMappingId: number;
  public visitorCategoryId: number;
  public companyId: number;
  public visitorCategory: string;
  public isHourly: boolean = false;
  public isLateFeeApplicable: boolean = false;
  public hourly: boolean = false;
  public lateFee: boolean = false;
  public hoursInMint: number;
  public lateFeeInMint: number;
  public visitorFee: number;
  public visitorLateFee: number;
  public numberofVisitor: number;
  public totalAmount: number;
  public startTime: Date;
  public endTime: Date;
  public totalMinutes: string;
  public totalLateMinutes: string;
  public totalLateAmount: number;
  public visitorCategoryTypeId: number;
}

//export class NewTickets {
//  visitorCategoryId: number;//
//  categoryMappingId: number;//
//  companyId: number;//
//  visitorCategory: string;
//  isHourly: boolean = false;
//  isLateFeeApplicable: boolean = false;
//  isAdvancePaymentApplicable: boolean = false;
//  isSelected: boolean = false;
//  hourly: boolean = false;
//  lateFee: boolean = false;
//  advancePayment: boolean = false;
//  hoursInMint: number;
//  lateFeeInMint: number;
//  visitorFee: number;
//  visitorLateFee: number;
//  advancePaymentAmount: number;
//  numberofVisitor: number;
//  totalAmount: number;
//  totalLateMinutes: number;
//  totalLateAmount: number;
//  totalMinutes: number;
//  startTime: Date;
//  endTime: Date;
//}

export class VisitorsTicketDetails {
  ticketId: number;
  companyId: number=0;
  ticketNo: string;
  visitorName: string;
  visitorMobileNo: string;
  visitorAddress: string;
  bookingDate: string;
  bookingTime: string;
  email: string;
  city: string;
  vState: string;
  ticketBookDate: Date;
  isHourly: boolean = false;
  isEqupement: boolean = false;
  isTicketClosed: boolean = false;
  loginUserId: number;
}
export class TicketAmountDetails {
  ticketAmountDetailId: number;
  ticketId: number;
  totalVisitor: number;
  totalEquipment: number;
  totalVisitorAmount: number;
  totalEquipmentAmount: number;
  totalLateCharges: number;
  dimagePartFineAmount: number;
  totalFinalAmount: number;
}
export class CCVResponse {
  public order_id: string;
  public tracking_id: string;
  public bank_ref_no: string;
  public order_status: string;
  public failure_message: string;
  public payment_mode: string;
  public card_name: string;
  public status_code: string;
  public status_message: string;
  public currency: string;
  public amount: string;
  public response_code: string;
}


