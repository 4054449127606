import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../../shared/shared.module';

import { SettingRoutingModule } from './setting-routing.module';
import { SettingsComponent } from './settings/settings.component';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CommonService } from '../Service/CommonService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CommonModule,
    SettingRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastModule,
    ConfirmDialogModule,
    TooltipModule,
    DialogModule,
    TabViewModule
  ],
  providers: [MessageService, CommonService]
})
export class SettingModule { }
