import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpHeaderResponse  } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import { ReportParam } from '../../model/Report';
import { Observable } from 'rxjs';





@Injectable({
  providedIn: 'root'
})
export class ReportService {

  header: HttpHeaders;
  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });
  }

  GetAllTicket(reportParam: ReportParam) {
    const url = `${this._auth.baseURL}Report/GetTicketReport`;
    let headers = this.header;
    return this.http.post<any>(url, reportParam, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  DeleteTicketReport(reportParam: ReportParam) {
    const url = `${this._auth.baseURL}Report/DeleteTicketReport`;
    let headers = this.header;
    return this.http.post<any>(url, reportParam, { headers })
      .pipe(map(x => {
        return x;
      }));
  }


  DeleteTicket(ticketId: number, comapnyId: number) {
    const url = `${this._auth.baseURL}Report/DeleteTicket/` + comapnyId + '/' + ticketId ;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  //ExportReport(): Observable<Blob> {
  //  const url = `${this.baseApiUrl}Report/ExportReportExcel`;
  //  let headers = this.header;
  //  return this.http.get(url, {responseType: 'blob',headers: headers })
  //    .pipe(map(x => {
  //      return x;
  //    }));
  //}
  //public downloadFile(docFile: string): Observable<Blob> {
  //  return this.http.get(this.url + '/GetFile?docFile=' + docFile, {
  //    responseType: 'blob'
  //  });
  //}

  downloadTicketAttachment() {
    let headers = this.header;
    return this.http
      .post(this._auth.baseURL + 'Report/ExportReportExcel', { responseType: 'blob', headers, observe: "response" })
      .pipe(catchError(this._errorhandler));
  }

  _errorhandler(err: Response) {
    return Observable.throw(err.json());
  }

  ExportReportCSV(reportParam: ReportParam) {
    const url = `${this._auth.baseURL}Report/ExportReportExcel`;
    let headers = this.header;
    return this.http.post(url, reportParam, { responseType: 'blob', headers: headers })
      .pipe(map(x => {
        return x;
      }));
  }
}
