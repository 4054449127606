<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Home </span> ><span>Place</span></h4>
            </div>
            <div class="float-lg-right" style="padding: 15px;">
              <button type="button" *ngIf="roleName=='Developer'" (click)="Navigate('company-create',0)" pTooltip="Add New Place" class="btn btn-primary btn-sm">Add</button>
            </div>
          </div>
        </div>

        <p-confirmDialog [style]="{width: '40vw'}" [baseZIndex]="10000"></p-confirmDialog>

        <div class="card cardheight border-primary-top">
          <div class="card-body main-content">
            <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;">
              <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                <thead>
                  <tr>
                    <th class="tabltth">Place Name</th>
                    <th>Address</th>
                    <th>Email</th>
                    <th>Phone No</th>
                    <th>Website</th>
                   
                    <th>Active</th>
                    <th>Created/Updated</th>
                    <th>Created/Updated Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of _companyViewModel">
                    <td>{{obj.companyName}}</td>
                    <td>
                      <span>{{obj.address1}}, {{obj.address2}}, <br />{{obj.city}}, {{obj.companyState}}, {{obj.zipCode}}</span>
                    </td>
                    <td>{{obj.email}}</td>
                    <td>{{obj.phoneNo}}</td>
                    <td>{{obj.website}}</td>
                    <td>
                      <span *ngIf="obj.isActive">Yes</span>
                      <span *ngIf="!obj.isActive">No</span>
                    </td>
                    <td>
                      <span><b>By </b>{{obj.createdBy}}</span><br />
                      <span><b>By </b>{{obj.updateBy}}</span>
                    </td>
                    <td>
                      <span><b>On </b>{{obj.createdDate | date:'dd-MM-yyyy hh:mm:ss'}}</span><br />
                      <span><b>On </b>{{obj.updatedDate | date:'dd-MM-yyyy hh:mm:ss'}}</span>
                    </td>
                    <td>
                      <div>
                        <i (click)="GetAllPlaceMappingData(obj.companyId)" class="ti-link editIcon iconLinkColor" *ngIf="roleName=='Developer'" pTooltip="Mapping with visitor category">
                        </i>
                        <i (click)="EditCompany(obj.companyId)" pTooltip="Edit Place" class="ti-pencil editIcon iconEditColor"></i>
                        <i class="ti-trash deleteIcon iconDeleteColor" pTooltip="Delete" *ngIf="roleName=='Developer' && obj.isPlaceLock==false" (click)="DeleteCompanyconfirm(obj.companyId)"></i>
                      
                      <i class="ti-lock deleteIcon iconDeleteColor" pTooltip="Can't delete" *ngIf="roleName=='Developer' && obj.isPlaceLock==true"></i>
                    </div>

            </td>
            </tr>
            </tbody>

            </table>


          </div>

          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
  <p-dialog [contentStyle]="{'height': '400px','width':'950px' }" [(visible)]="showDialog" header="Place Mapping"
            [closable]="false" [modal]="true">
    <div style="margin-top: 10px;">
      <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;">
        <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
          <thead>
            <tr>
              <th class="tabltth">Visitor Category</th>
              <th>Hourly</th>
              <th>Late Fee</th>
             
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let obj of _placeMapping">
              <td>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" [checked]="obj.isSelected" (change)="obj.isSelected = !obj.isSelected" ([ngModel])="obj.isSelected">
                    {{obj.visitorCategory}}
                    <i class="input-helper"></i>
                  </label>
                </div>
              </td>
              <td>
                <div style="margin-top: 12px;" *ngIf="obj.isHourly">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline15{{obj.visitorCategoryId}}" name="hourly{{obj.visitorCategoryId}}" class="custom-control-input"
                           [(ngModel)]="obj.hourly"
                           [value]="true">
                    <label class="custom-control-label" for="customRadioInline15{{obj.visitorCategoryId}}">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline25{{obj.visitorCategoryId}}" name="hourly{{obj.visitorCategoryId}}" class="custom-control-input"
                           [(ngModel)]="obj.hourly"
                           [value]="false">
                    <label class="custom-control-label" for="customRadioInline25{{obj.visitorCategoryId}}">No</label>
                  </div>
                </div>
              </td>
              <td>
                <div style="margin-top: 12px;" *ngIf="obj.isLateFeeApplicable">
                  <div class="custom-control custom-radio custom-control-inline">

                    <input type="radio" id="customRadioInline11{{obj.visitorCategoryId}}" name="lateFee{{obj.visitorCategoryId}}" class="custom-control-input"
                           [(ngModel)]="obj.lateFee"
                           [value]="true">
                    <label class="custom-control-label" for="customRadioInline11{{obj.visitorCategoryId}}">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline22{{obj.visitorCategoryId}}" name="lateFee{{obj.visitorCategoryId}}" class="custom-control-input"
                           [(ngModel)]="obj.lateFee"
                           [value]="false">
                    <label class="custom-control-label" for="customRadioInline22{{obj.visitorCategoryId}}">No</label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton (click)="SaveUpdatePlaceMappingCategory()" label="Save"></button>
      <button type="button" pButton (click)="showDialog=false" label="Close"></button>
    </p-footer>
  </p-dialog>


</div>
