import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { CommonService } from '../Service/CommonService';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';

import { DeleteTicketRoutingModule } from './delete-ticket-routing.module';
import { DeleteTicketComponent } from './delete-ticket/delete-ticket.component';


@NgModule({
  declarations: [DeleteTicketComponent],
  imports: [
    CommonModule,
    DeleteTicketRoutingModule,
    FormsModule,
    SharedModule,
    ToastModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    TooltipModule,
    DialogModule,
  ],
  providers: [CommonService, MessageService]
})
export class DeleteTicketModule { }
