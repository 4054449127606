import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { CreateCompanyComponent } from './create-company/create-company.component';


const routes: Routes = [{ path: 'company', component: CompanyComponent },
  { path: 'company-create', component: CreateCompanyComponent }
  , { path: 'company-create/:id', component: CreateCompanyComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
