import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CommonService } from '../Service/CommonService';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';

import { ReportRoutingModule } from './report-routing.module';
import { ReportsComponent } from './reports/reports.component';

import { CalendarModule } from 'primeng/calendar';
import { DatePipe } from '@angular/common'

@NgModule({
  declarations: [ReportsComponent],
  imports: [
    CommonModule,
    ReportRoutingModule,
    FormsModule,
    SharedModule,
    ToastModule,
    BrowserAnimationsModule,
    CalendarModule
  ],
  providers: [CommonService, MessageService, DatePipe]
})
export class ReportModule { }
