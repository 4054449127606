import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { RoleService } from './../../Service/RoleService';
import { roleModel, roleViewModel } from '../../../model/roleModel';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  roleViewModel: Array<roleViewModel>;
  showLoader: boolean = false;
  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private _roleService: RoleService) { }

  ngOnInit(): void {
    document.body.className = "sidebar-icon-only";  
    this.getAllRoles();
  }

  Navigate(e,id) {
    if (e == 'role-create' && id == 0) {
      this.router.navigate(['role-create']);
    }
    else if (e == 'role-create' && id > 0) {
      this.router.navigate(['role-create', id]);
    }
    //setTimeout(() => {
    //  this.document.location.reload();
    //},
    //  10);
  }

  getAllRoles() {
    this.showLoader = true;
    this.roleViewModel = new Array<roleViewModel>();
    this._roleService.getAllRoles().subscribe(m => {
      if (m.statusCode == 200) {
        this.roleViewModel = m.result;
        this.showLoader = false;
      }
      console.log(m);
    })
  }

  EditRole(id) {
    this.Navigate('role-create', id);
  }
}
