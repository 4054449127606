<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>

    <div class="main-panel">
      <div class="content-wrapper" style="margin-bottom: 50px;">
        <div class="card mb-2" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Dashboard </span></h4>
            </div>
            <div class="float-lg-right" style="padding: 15px;">
              <!--<button type="button" class="btn btn-primary btn-sm">Add</button>-->
            </div>
          </div>
        </div>

        <div class="card cardheight">
          <div class="card-body main-content border-primary-top">
            <div class="row">
              <div class="form-group col-2 ticket-label">
                <select name="PlaceId" class="form-control ticket-input-dropdown" [(ngModel)]="_reportParam.companyId" (change)="PlaceChangeEvent()">
                  <option [ngValue]=0>Select All</option>
                  <option *ngFor="let obj of _companyViewModel" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                </select>
              </div>

              <div class="form-group col-2 ticket-label">
                <select name="PlaceId" class="form-control ticket-input-dropdown" [(ngModel)]="_reportParam.selectedType" (change)="RangeChangeEvent()">
                  <option [ngValue]=2>Weekly</option>
                  <option [ngValue]=3>Bi-Weekly</option>
                  <!--<option [ngValue]=4>Date</option>
      <option [ngValue]=1>Month</option>-->

                </select>
              </div>

              <div class="form-group col-2 ticket-label" *ngIf="_reportParam.selectedType==4">
                <p-calendar [readonlyInput]="true" placeholder="Start Date" class="pClander" [(ngModel)]="StartDate" dateFormat="dd-mm-yy"
                            yearRange="2021:2022" (onSelect)="SetStartDate($event)">
                </p-calendar>
              </div>
              <div class="form-group col-2 ticket-label" *ngIf="_reportParam.selectedType==4">
                <p-calendar [readonlyInput]="true" placeholder="End Date" class="pClander" [(ngModel)]="EndDate" dateFormat="dd-mm-yy"
                            yearRange="2021:2022" (onSelect)="SetEndDate($event)">
                </p-calendar>
              </div>
            </div>
              <div  class="row">
                <div class="col-md-2 col-sm-4 col-xs-8">
                  <div class="info-box">
                    <div class="info-box-content">
                      <span class="info-box-text">Total Visitor</span>
                      <span class="info-box-number">{{TotalVisitor}}<small></small></span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <div class="col-md-2 col-sm-4 col-xs-8">
                  <div class="info-box" style="background-color:#dd4b39 !important">
                    <div class="info-box-content">
                      <span class="info-box-text">Total Equipment</span>
                      <span class="info-box-number">{{TotalEquipment}}<small></small></span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <div class="col-md-2 col-sm-4 col-xs-8">
                  <div class="info-box" style="background-color:#f39c12 !important">
                    <div class="info-box-content">
                      <span class="info-box-text">Total Counter Amount</span>
                      <span class="info-box-number">{{TotalCoutnterAmount}}<small> INR</small></span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!--<div class="col-md-2 col-sm-4 col-xs-8">
                  <div class="info-box" style="background-color:#3337b2 !important">
                    <div class="info-box-content">
                      <span class="info-box-text">Total Online Amount</span>
                      <span class="info-box-number">{{TotalOnlineAmount}}<small> INR</small></span>
                    </div>
                  </div>
                </div>-->
                <div class="col-md-2 col-sm-4 col-xs-8">
                  <div class="info-box" style="background-color:#00a65a !important">
                    <div class="info-box-content">
                      <span class="info-box-text">Total Amount</span>
                      <span class="info-box-number">{{TotalAmount}}<small> INR</small></span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
              </div>
              <div class="col-lg-12">
                <div class="col-lg-5" *ngFor="let item of SalesData" style="float:left;">
                  <p-chart type="bar" [data]="item._data" [options]="options"></p-chart>
                  <div style="text-align:center">
                    <b> {{item.agenyName}}</b>
                  </div>
                </div>
              </div>

           
          </div>
        </div>
      </div>
          <app-footer></app-footer>
        </div>
      </div>
  </div>

