import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpHeaderResponse  } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import { ReportParam } from '../../model/Report';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  header: HttpHeaders;

  constructor(@Inject(BASE_API_URL) private readonly _baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });

  }

  GetDashboardReport(reportParam: ReportParam) {
    const url = `${this._auth.baseURL}Dashboard/GetDashboardReport`;
    let headers = this.header;
    return this.http.post<any>(url, reportParam, { headers })
      .pipe(map(x => {
        return x;
      }));
  }
}
