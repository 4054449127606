import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { LoginComponent } from './login/login.component';
//import { LoginModule } from './login/login.module';
import { DashboardModule } from './Modules/dashboard/dashboard.module';
import { RoleModule } from './Modules/role/role.module';
import { CompanyModule} from './Modules/company/company.module';
import { VisitorCategoryModule } from './Modules/visitor-category/visitor-category.module';
import { UserModule } from './Modules/user/user.module';
import { SettingModule } from './Modules/setting/setting.module';
import { TicketModule } from './Modules/ticket/ticket.module';
import { HomeComponent } from './Modules/home/home/home.component';
import { HomeModule } from './Modules/home/home.module';
import { LoginModule } from './login/login.module';
import { ReportModule } from './Modules/report/report.module';
import { OnlineticketModule } from './Modules/onlineticket/onlineticket.module';
import { DeleteTicketModule } from './Modules/delete-ticket/delete-ticket.module';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'dashboard', loadChildren: () => import(`./Modules/dashboard/dashboard.module`).then(m => m.DashboardModule) },
  { path: 'empLogin', loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule) },
  { path: 'role', loadChildren: () => import(`./Modules/role/role.module`).then(m => m.RoleModule) },
  { path: 'company', loadChildren: () => import(`./Modules/company/company.module`).then(m => m.CompanyModule) },
  { path: 'visitor', loadChildren: () => import(`./Modules/visitor-category/visitor-category.module`).then(m => m.VisitorCategoryModule) },
  { path: 'user', loadChildren: () => import(`./Modules/user/user.module`).then(m => m.UserModule) },
  { path: 'setting', loadChildren: () => import(`./Modules/setting/setting.module`).then(m => m.SettingModule) },
  { path: 'ticket', loadChildren: () => import(`./Modules/ticket/ticket.module`).then(m => m.TicketModule) },
  { path: 'report', loadChildren: () => import(`./Modules/report/report.module`).then(m => m.ReportModule) },
  { path: 'online-ticket', loadChildren: () => import(`./Modules/onlineticket/onlineticket.module`).then(m => m.OnlineticketModule) },
  { path: 'delete-ticket', loadChildren: () => import(`./Modules/delete-ticket/delete-ticket.module`).then(m => m.DeleteTicketModule) },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [DashboardModule
    , RoleModule
    , CompanyModule
    , VisitorCategoryModule
    , UserModule
    , SettingModule
    , TicketModule
    , HomeModule
    , LoginModule
    , ReportModule
    , OnlineticketModule
    , DeleteTicketModule
    , RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
