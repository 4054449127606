
<nav class="sidebar sidebar-offcanvas" id="sidebar" style="margin-top: -23px;" [class.hide]="cmnSrv.dashboardState.sidebarToggle">
  <ul class="nav" *ngIf="this.roleName!=''">
    <li class="nav-item">
      <a class="nav-link">
        <i class="ti-shield menu-icon"></i>
        <span class="menu-title" (click)="Navigate('dashboard')">Dashboard</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
        <i class="ti-palette menu-icon"></i>
        <span class="menu-title">User Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="ui-basic">
        <ul class="nav flex-column sub-menu">
          <li *ngIf="roleName=='Developer'" class="nav-item"> <a class="nav-link" (click)="Navigate('roles')">Role</a></li>
          <li class="nav-item" *ngIf="roleName=='Developer' || roleName=='Admin'"> <a class="nav-link" (click)="Navigate('company')">Place</a></li>
          <li *ngIf="roleName=='Developer'" class="nav-item"> <a class="nav-link" (click)="Navigate('visitorcategory')">Visitor Category</a></li>
          <li class="nav-item"> <a class="nav-link" (click)="Navigate('users')">User</a></li>
          <li *ngIf="roleName=='Admin'" class="nav-item"> <a class="nav-link" (click)="Navigate('setting')">Setting</a></li>
          <li *ngIf="roleName=='Manager' || roleName=='Developer' || roleName=='Admin'" class="nav-item"> <a class="nav-link" (click)="Navigate('delete-ticket')">Delete Ticket</a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="roleName!='Developer'">
      <a class="nav-link" data-toggle="collapse" href="#ui-ticket" aria-expanded="false" aria-controls="ui-ticket">
        <i class="ti-write menu-icon"></i>
        <span class="menu-title">Tickets</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="ui-ticket">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"> <a class="nav-link" (click)="Navigate('ticket')">Ticket</a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="collapse" href="#ui-report" aria-expanded="false" aria-controls="ui-report">
        <i class="ti-view-list-alt menu-icon"></i>
        <span class="menu-title">Reports</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="ui-report">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"> <a class="nav-link" (click)="Navigate('reports')">Report</a></li>
        </ul>
      </div>
    </li>
    <!--<li class="nav-item">
      <a class="nav-link" href="pages/forms/basic_elements.html">
        <i class="ti-layout-list-post menu-icon"></i>
        <span class="menu-title">Form elements</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="pages/charts/chartjs.html">
        <i class="ti-pie-chart menu-icon"></i>
        <span class="menu-title">Charts</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="pages/tables/basic-table.html">
        <i class="ti-view-list-alt menu-icon"></i>
        <span class="menu-title">Tables</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="pages/icons/themify.html">
        <i class="ti-star menu-icon"></i>
        <span class="menu-title">Icons</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
        <i class="ti-user menu-icon"></i>
        <span class="menu-title">User Pages</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="auth">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"> <a class="nav-link" href="pages/samples/login.html"> Login </a></li>
          <li class="nav-item"> <a class="nav-link" href="pages/samples/login-2.html"> Login 2 </a></li>
          <li class="nav-item"> <a class="nav-link" href="pages/samples/register.html"> Register </a></li>
          <li class="nav-item"> <a class="nav-link" href="pages/samples/register-2.html"> Register 2 </a></li>
          <li class="nav-item"> <a class="nav-link" href="pages/samples/lock-screen.html"> Lockscreen </a></li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="documentation/documentation.html">
        <i class="ti-write menu-icon"></i>
        <span class="menu-title">Documentation</span>
      </a>
    </li>-->
  </ul>
  <ul class="nav" *ngIf="this.roleName==''">
    <li class="nav-item">
      <a class="nav-link">
        <i class="ti-shield menu-icon"></i>
        <span class="menu-title">Book Ticket</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link">
        <i class="ti-shield menu-icon"></i>
        <span class="menu-title">RePrint Ticket</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link">
        <i class="ti-shield menu-icon"></i>
        <span class="menu-title">Cancel Ticket</span>
      </a>
    </li>

  </ul>
</nav>
