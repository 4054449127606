
<div id="page-top" data-spy="scroll" data-target=".navbar-fixed-top">

  <!-- banner -->
  <div id="home">
    <!-- color scheme -->
    <div class="blast-box">
      <div class="blast-frame">
        <p>color schemes</p>
        <div class="blast-colors d-flex justify-content-center">
          <div class="blast-color">#15396A</div>
          <div class="blast-color">#15396A</div>
          <div class="blast-color">#15396A</div>
          <div class="blast-color">#15396A</div>
          <div class="blast-color">#15396A</div>
          <!-- you can add more colors here -->
        </div>
        <p class="blast-custom-colors">Choose Custom color</p>
        <input type="color" name="blastCustomColor" value="#d3b800">
      </div>
      <!--<div class="blast-icon"><span class="fa fa-paint-brush" aria-hidden="true"></span></div>-->
    </div>
    <!-- //color scheme -->
    <!-- header -->
    <header>
      <nav class="navbar navbar-expand-lg navbar-light fixed-top navbar-expand-lg navbar-light navbar-fixed-top">
        <h1>
          <a class="navbar-brand" data-blast="color" style="color: #d3b800; font-size: 0.4em;">
            DHAULADHAR NATURE PARK, WILDLIFE DIVISION HAMIRPUR (HP)
          </a>
        </h1>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div style="margin-left: 424px;">
            <ul class="navbar-nav mx-lg-auto text-center">
              <li class="nav-item active">
                <a class="nav-link" href="index.html" data-blast="color">
                  Home
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item  mt-lg-0 mt-3 active">
                <a class="nav-link scroll" style="cursor:pointer;" (click)="Navigate('empLogin')">Employee Login</a>
              </li>
            </ul>
          </div>
        </div>

      </nav>
    </header>
    <!-- //header -->
    <div class="callbacks_container">
      
      <ul class="rslides" id="slider3">
       
        <li class="banner banner2" *ngIf="slideImage2" style="position: fixed; display: block; float: none; height: 100%;">
          <div style="padding-top: 96px;float: right;padding-right: 25px;">
            <h3 style="font-size: 15px;text-transform: capitalize;color: #fff; word-spacing: 5px;letter-spacing: 1px;font-weight: 700;line-height: 1.3;"></h3>
          </div>
          <div style="position: fixed; left: 86px; bottom: 50px; width: 100%;">
            <p style="text-transform: capitalize; color: #fff; word-spacing: 5px; letter-spacing: 1px; font-weight: 700; line-height: 1.3;font-style: italic; "></p>
          </div>
          <div class="container">
            <div class="banner-text">
              <div class="slider-info">
                <!--<h3 style="font-size: 30px;" >Respect Protect & Conserve Wildlife</h3>Respect Protect & Conserve Wildlife   second line We move the world to protect Wildlife
  <span class="line"></span>
  <p>We move the world to protect Wildlife</p>
  <a class="btn bg-theme mt-4 w3_pvt-link-bnr scroll" data-blast="bgColor" style="cursor:pointer;" (click)="Navigate('onlineticket')"
     role="button">
    Online Ticket Booking
  </a>-->
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
  <!-- js -->
  <script src="../../../../assets/home/js/jquery-2.2.3.min.js"></script>
  <!-- //js -->
  <!-- Scrolling Nav JavaScript -->
  <script src="../../../../assets/home/js/scrolling-nav.js"></script>
  <script src="../../../../assets/home/js/counter.js"></script>


  <!-- Placed at the end of the document so the pages load faster -->
  <script src="../../../../assets/home/js/bootstrap.js"></script>
</div>
<app-footer></app-footer>
