<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Home </span> ><span>Visitor Category</span></h4>
            </div>
            <div class="float-lg-right" style="padding: 15px;">
              <button type="button" (click)="Navigate('visitorcategory-create',0)" pTooltip="Add New Visitor Category" class="btn btn-primary btn-sm">Add</button>
            </div>
          </div>
        </div>
        <p-confirmDialog [style]="{width: '40vw'}" [baseZIndex]="10000"></p-confirmDialog>

        <div class="card cardheight border-primary-top">
          <div class="card-body main-content">
            <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;">
              <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                <thead>
                  <tr>
                    <th class="tabltth">Visitor Category</th>
                    <th>Visitor Category Type</th>
                    <th>Hourly</th>
                    <th>Late Fee Applicable</th>
                    <th>Created/Updated</th>
                    <th>Created/Updated Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of visitorCategoryViewModel">
                    <td>{{obj.visitorCategory}}</td>
                    <td>
                      {{obj.visitorCategoryType}}
                    </td>
                    <td>
                      <span *ngIf="obj.isHourly">Yes</span>
                      <span *ngIf="!obj.isHourly">No</span>
                    </td>
                    <td>
                      <span *ngIf="obj.isLateFeeApplicable">Yes</span>
                      <span *ngIf="!obj.isLateFeeApplicable">No</span>
                    </td>
                    <td>
                      <span><b>By </b>{{obj.createdBy}}</span><br />
                      <span><b>By </b>{{obj.updatedBy}}</span>
                    </td>
                    <td>
                      <span><b>On </b>{{obj.createdDate | date:'dd-MM-yyyy hh:mm:ss'}}</span><br />
                      <span><b>On </b>{{obj.updatedDate | date:'dd-MM-yyyy hh:mm:ss'}}</span>
                    </td>
                    <td>
                      <div>
                        <i (click)="EditVisitorCategory(obj.visitorCategoryId)" pTooltip="Edit Company" class="ti-pencil editIcon iconEditColor"></i>
                        <i class="ti-trash deleteIcon iconDeleteColor" pTooltip="Delete Company" (click)="Deleteconfirm(obj.visitorCategoryId)"></i>
                      </div>

                    </td>
                  </tr>
                </tbody>

              </table>
            </div>

          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
