import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NumberDirective } from './NumberDirective';
import { CustomFilter} from './CustomFilter';
@NgModule({
  imports: [],
  declarations: [NumberDirective, CustomFilter],
  exports: [NumberDirective, CustomFilter]
})

export class CommonsModule { }
