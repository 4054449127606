export class user {
  userId: number;
  userName: string;
  token: string;
  loginName: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNo: string;
  isManager: boolean;
  roleName: string;
  roleId: number;
  adminUserId: number;
  baseUrl: string;
}
