<div class="container-scroller">
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end" style="width: calc(100% - 0px);">
      <div class="col-12">
        <div class="col-6"  style="float:left;">
          <span style="font-size: 30px; color: #fff;">Zoo Or Park Name</span>
        </div>
        <div class="col-5" style="float:left;">
          <ul class="navbar-nav mr-lg-2" style="float: right;">
            <li class="nav-item nav-search d-none d-lg-block">
              <a style="font-size: 21px; color: #fff;cursor:pointer;">Print Ticket</a>
            </li>
            <li class="nav-item nav-search d-none d-lg-block">
              <a style="font-size: 21px; color: #fff;cursor:pointer;">Cancel Ticket</a>
            </li>
            <li class="nav-item nav-search d-none d-lg-block">
              <a style="font-size: 21px; color: #fff;cursor:pointer;cursor:pointer;" (click)="Navigate()">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="container-fluid page-body-wrapper">
    <!--<app-navmenu></app-navmenu>-->
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel" style="width: calc(100% - 0px);">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper" style="margin-bottom: 50px;">
        <div class="card cardheight">
          <div class="card-body main-content">
            <div class="row">
              <form class="form-sample" style="width: 100%;" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="col-12">
                  <fieldset style="border-radius: 3px; box-shadow: 0px 0px 0px #ECECEC inset !important; border: 2px solid rgb(19 116 85) !important;">
                    <legend class="field-lable" style="width: 80px">Place : </legend>
                    <div class="form-group col-2 ticket-label" style="float:left;">
                      <select name="PlaceId" class="form-control ticket-input-dropdown" [(ngModel)]="dropdowncompanyId" (change)="PlaceChangeEvent()" [ngModelOptions]="{standalone: true}">
                        <option value="0">Select Place</option>
                        <option *ngFor="let obj of _companyViewModel" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                      </select>
                    </div>
                    <div class="form-group col-1 ticket-label" style="float:left;">
                      <p-calendar [readonlyInput]="true" [minDate]="minimumDate" placeholder="Booking Date" [(ngModel)]="bookingDate" dateFormat="dd-mm-yy" yearRange="2021:2022" (onSelect)="setDate($event)" [ngModelOptions]="{standalone: true}">
                      </p-calendar>
                    </div>
                    <div class="form-group col-1 ticket-label" style="float:left;">
                      <p-calendar showTime="true" [timeOnly]="true" placeholder="Time" [ngModelOptions]="{standalone: true}" [(ngModel)]="bookingTime" (onSelect)="setTime($event)" inputId="timeonly" hourFormat="12"></p-calendar>
                    </div>
                  </fieldset>
                </div>

                <div class="col-12" *ngIf="dropdowncompanyId>0">
                  <fieldset style="border-radius: 3px; box-shadow: 0px 0px 0px #ECECEC inset !important; border: 2px solid rgb(19 116 85) !important;">
                    <legend class="field-lable" style="width: 200px">Personal Details : </legend>

                    <div class="form-group col-2 " style="float:left;">

                      <input type="text" name="visitorName" class="form-control ticket-input" #visitorName="ngModel" [(ngModel)]="_visitorTicket.visitorsTicketDetails.visitorName" placeholder="Visitor Name" pTooltip="Enter Visitor Name" [ngClass]="{ 'is-invalid': f.submitted && visitorName.invalid }" required>
                      <div *ngIf="f.submitted && visitorName.invalid" class="invalid-feedback">
                        <div *ngIf="visitorName.errors.required">Visitor Name is required</div>
                      </div>
                    </div>
                    <div class="form-group col-2 ticket-label" style="float:left;">
                      <input type="text" #phone name="visitorMobileNo" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.visitorMobileNo" placeholder="Visitor Mobile No" pTooltip="Enter Visitor Mobile No" #visitorMobileNo="ngModel" [ngClass]="{ 'is-invalid': f.submitted && visitorMobileNo.invalid }" [pattern]="mobNumberPattern" required>
                      <div *ngIf="f.submitted && visitorMobileNo.invalid" class="invalid-feedback">
                        <div *ngIf="visitorMobileNo.errors.required">Mobile No is required</div>
                        <div *ngIf="visitorMobileNo.errors.pattern">Mobile No not valid</div>
                      </div>
                    </div>

                    <div class="form-group col-2 " style="float:left;">
                      <input type="email" name="email" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.email" placeholder="Email " pTooltip="Enter Visitor Email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" required>
                      <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                        <div *ngIf="email.errors.required">Email is required</div>
                        <div *ngIf="email.invalid">Email must be a valid email address</div>
                      </div>
                    </div>

                    <div class="form-group col-2 ticket-label" style="float:left;">
                      <input type="text" name="visitorAddress" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.visitorAddress" placeholder="House No, Street/Area" pTooltip="House No, Street/Area" #visitorAddress="ngModel" [ngClass]="{ 'is-invalid': f.submitted && visitorAddress.invalid }" required>
                      <div *ngIf="f.submitted && visitorAddress.invalid" class="invalid-feedback">
                        <div *ngIf="visitorAddress.errors.required">Address is required</div>
                      </div>
                    </div>
                    <div class="form-group col-2 ticket-label" style="float:left;">
                      <input type="text" name="city" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.city" placeholder="City/Town" pTooltip="City/Town" #city="ngModel" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required>
                      <div *ngIf="f.submitted && city.invalid" class="invalid-feedback">
                        <div *ngIf="city.errors.required">City is required</div>
                      </div>
                    </div>
                    <div class="form-group col-2 ticket-label" style="float:left;">
                      <input type="text" name="vState" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.vState" placeholder="State" pTooltip="Enter State" #vState="ngModel" [ngClass]="{ 'is-invalid': f.submitted && vState.invalid }" required>
                      <div *ngIf="f.submitted && vState.invalid" class="invalid-feedback">
                        <div *ngIf="vState.errors.required">State is required</div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="col-12" *ngIf="dropdowncompanyId>0">
                  <fieldset style="border-radius: 3px; box-shadow: 0px 0px 0px #ECECEC inset !important; border: 2px solid rgb(19 116 85) !important;">
                    <legend class="field-lable" style="width: 100px">Visitor's Detail: </legend>
                    <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;" *ngIf="dropdowncompanyId>0">
                      <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                        <thead>
                          <tr>
                            <th rowspan="2">S.No</th>
                            <th rowspan="2">Visitor Type</th>
                            <th [attr.colspan]="numberOfColumnSpan" style="text-align:center;">
                              Visitor Charges Details
                            </th>

                            <th rowspan="2"># of Visitor</th>
                            <th rowspan="2">Total Amount</th>
                          </tr>
                          <tr>
                            <th>Fee</th>
                            <th>Hourly</th>
                            <th *ngIf="this.isHourly">Hours (in Minutes)</th>
                            <th *ngIf="this.isLateCharges">Late Charges</th>
                            <th *ngIf="this.isLateCharges">Time (in Minutes)</th>
                            <th *ngIf="this.isLateCharges">Late Charges(Amt)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let obj of _visitorTicket.visitorCategoryTypeDetailCollection; index as i">
                            <td>{{i+1}}</td>
                            <td>{{obj.visitorCategory}}</td>
                            <td>&#x20b9; {{obj.visitorFee}}.00</td>
                            <td>
                              <span *ngIf="obj.hourly">
                                Yes
                              </span>
                              <span *ngIf="!obj.hourly">
                                No
                              </span>
                            </td>
                            <td *ngIf="obj.hourly">
                              <div style="width:70px;float:left">
                                <input type="number" [disabled]="obj.hourly" class="form-control ticket-input" [(ngModel)]="obj.hoursInMint" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" [ngModelOptions]="{standalone: true}" />

                              </div>
                              <div style="float:left;margin-top:9px;margin-left:2px;">
                                {{obj.hoursInMint/60 |number:'0.2-3'}} hr
                              </div>
                            </td>
                            <td *ngIf="!obj.hourly && isHourly">
                            </td>
                            <td *ngIf="obj.lateFee">
                              <span *ngIf="obj.lateFee">
                                Yes
                              </span>
                              <span *ngIf="!obj.lateFee">
                                No
                              </span>
                            </td>
                            <td *ngIf="!obj.lateFee && isLateCharges">
                            </td>
                            <td *ngIf="obj.lateFee">
                              <input type="number" [disabled]="obj.lateFee" class="form-control ticket-input" [(ngModel)]="obj.lateFeeInMint" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" [ngModelOptions]="{standalone: true}" />
                            </td>
                            <td *ngIf="!obj.lateFee && isLateCharges">
                            </td>
                            <td *ngIf="obj.lateFee">
                              <input type="number" [disabled]="obj.lateFee" class="form-control ticket-input" [(ngModel)]="obj.visitorLateFee" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" [ngModelOptions]="{standalone: true}" />
                            </td>
                            <td *ngIf="!obj.lateFee && isLateCharges">
                            </td>
                            <td>
                              <input type="number" class="form-control ticket-input" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" [(ngModel)]="obj.numberofVisitor" (keyup)="CalculateVisitorAmount()" (blur)="CalculateVisitorAmount()" numbersOnly="false" pattern="[0-9]*"
                                     (keypress)="numberOnly($event);" pTooltip="# of visitor" style="width: 60px;" maxlength="4" [ngModelOptions]="{standalone: true}" />
                            </td>
                            <td>&#x20b9;  {{obj.totalAmount}}</td>
                          </tr>
                          <tr>
                            <td [attr.colspan]="numberOfColumnSpan+2" style="text-align:right;"><b>Total</b></td>
                            <td>
                              {{_visitorTicket.ticketAmountDetails.totalVisitor}}
                            </td>
                            <td>
                              &#x20b9;  {{_visitorTicket.ticketAmountDetails.totalVisitorAmount}}
                            </td>
                          </tr>
                          <tr>
                            <td [attr.colspan]="numberOfColumnSpan+2" style="text-align:right;"><b>Final Amount</b></td>
                            <td>
                            </td>
                            <td>
                              &#x20b9;  {{_visitorTicket.ticketAmountDetails.totalFinalAmount}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </fieldset>
                </div>
               
                <div class="clearfix"></div>
                <div class="col-lg-12" *ngIf="dropdowncompanyId>0" style="margin-top: 20px;">
                  <div style="float: left; padding-left: 65%;">
                    <ngx-recaptcha2 #captchaElem
                                    [siteKey]="'6LeO4I0aAAAAAM22O1MQ42FQKgP3g46yGPd6qICY'"
                                    (success)="handleSuccess($event)"
                                    [useGlobalDomain]="false"
                                    [size]="size"
                                    [hl]="lang"
                                    [theme]="theme"
                                    [type]="type">
                    </ngx-recaptcha2>
                  </div>
                  <div style="float: right; margin-top: 12px;">
                    <button class="float-right m-2" pButton label="Pay & Book"></button>
                    <button type="button" class="float-right m-2" pButton (click)="Clear()" label="Clear"></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>

<p-dialog [contentStyle]="{'height': '500px','width':'950px' }" [(visible)]="TicketConfmBeforeSave" header="CAUTION"
          [closable]="false" [modal]="true">
  <div style="margin-top: 10px;">
    <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;">
      <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
        <tbody>
          <tr>
            <td>
              Name
            </td>
            <td>
              {{_visitorTicket.visitorsTicketDetails.visitorName}}
            </td>
            <td colspan="2">
              Mobile No
            </td>
            <td colspan="2">
              {{_visitorTicket.visitorsTicketDetails.visitorMobileNo}}
            </td>
          </tr>
          <tr>
            <td>
              Address
            </td>
            <td>
              <span> {{_visitorTicket.visitorsTicketDetails.visitorAddress}}</span>, <span>{{_visitorTicket.visitorsTicketDetails.city}}</span>, <span>{{_visitorTicket.visitorsTicketDetails.vState}}</span>
            </td>
            <td colspan="2">
              Date/Time
            </td>
            <td colspan="2">
              {{_visitorTicket.visitorsTicketDetails.bookingDate}} {{_visitorTicket.visitorsTicketDetails.bookingTime}}
            </td>
          </tr>
          <tr>
            <td>
              No of Visitor
            </td>
            <td>
              {{totalVisitor}}
            </td>
            <td colspan="2">
              No of Equipment
            </td>
            <td colspan="2">
              {{totalEquipment}}
            </td>
          </tr>
          <tr *ngIf="TempvisitorCategoryTypeDetailCollection.length>0">
            <th colspan="5"><div style="text-align:center;">Equipment Details</div></th>
          </tr>
          <tr *ngIf="TempvisitorCategoryTypeDetailCollection.length>0">
            <th>
              S.No
            </th>
            <th>
              Equipment Type
            </th>
            <th>No of Equipment</th>
            <th>
              Tariff
            </th>
            <th>
              Amount
            </th>
          </tr>
          <tr *ngFor="let obj of TempvisitorCategoryTypeDetailCollection; index as i">
            <td>{{i+1}}</td>
            <td>{{obj.visitorCategory}}</td>
            <td>{{obj.numberofVisitor}}</td>
            <td>&#x20b9; {{obj.visitorFee}} (per/{{obj.hoursInMint/60}} HR)</td>
            <td>&#x20b9; {{obj.totalAmount}}</td>
          </tr>
          <tr *ngIf="totalVisitor>0">
            <th colspan="5"><div style="text-align:center;">Visitor Details</div></th>
          </tr>
          <tr *ngIf="totalVisitor>0">
            <th>S.No</th>
            <th>Visitor Type</th>
            <th>No of Visitor</th>
            <th>Tariff</th>
            <th>Amount</th>
          </tr>
          <tr *ngFor="let obj of TempvisitorCategoryTypeDetailCollectionNoneHourly; index as i">
            <td>{{i+1}}</td>
            <td>{{obj.visitorCategory}}</td>
            <td>{{obj.numberofVisitor}}</td>
            <td>&#x20b9; {{obj.visitorFee}} </td>
            <td>&#x20b9; {{obj.totalAmount}}</td>
          </tr>
          <tr>
            <td colspan="4"><div style="text-align:right;"><b>Total</b></div> </td>
            <td> &#x20b9;  {{_visitorTicket.ticketAmountDetails.totalFinalAmount}}</td>
          </tr>
          <!--<tr>
            <td colspan="5">
              <div style="font-size: 18px; color: red;">
                Be alert! You are about to book & print this ticket. After that you can't edit or delete.
              </div>
            </td>
          </tr>-->
        </tbody>
      </table>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton label="Payment" (click)="SaveTicketPrint()"></button>
    <button type="button" pButton (click)="TicketConfmBeforeSave=false" label="Close"></button>
  </p-footer>
</p-dialog>


<form #form1 ngNoForm
      id="paymentForm"
      method="post"
      name="redirect"
      action="{{CheckoutUrl}}">
  <input type="hidden" id="encRequest" name="encRequest" value="{{EncryptionRequest}}">
  <input type="hidden" name="access_code" id="access_code" value="{{AccessCode}}">
</form>
