
<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" [class.hidden]="cmnSrv.dashboardState.navbarToggle" [class.sidebar-hidden]="cmnSrv.dashboardState.sidebarToggle">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">

    <a class="navbar-brand brand-logo mr-5"><img src="assets/images/logo-left.png" style="height: 57px;width: 60px;" class="mr-2" alt="logo" /></a>
    <a class="navbar-brand brand-logo-mini" ><img src="assets/images/logo-left.png" style="height: 57px;width: 60px;" alt="logo" /></a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" [class.open]="!cmnSrv.dashboardState.sidebarToggle" (click)="cmnSrv.sidebarToggle()">
      <span class="ti-view-list" style="color:#fff;"></span>
    </button>
    <!--<a class="navbar-brand brand-logo-mini" href="index.html"><img src="assets/images/logo-mini.png" style="height: 53px; width:70px;" alt="logo" /></a>-->
    <!--<ul class="navbar-nav mr-lg-2">
    <li class="nav-item nav-search d-none d-lg-block">
      <div class="input-group">
        <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
          <span class="input-group-text" id="search">
            <i class="ti-search"></i>
          </span>
        </div>
        <input type="text" class="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search" aria-describedby="search">
      </div>
    </li>
  </ul>-->
    <ul class="navbar-nav navbar-nav-right">
      <!--<li class="nav-item dropdown mr-1">
        <a class="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-toggle="dropdown">
          <i class="ti-email mx-0" style="color:#fff"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="messageDropdown">
          <p class="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
          <a class="dropdown-item">
            <div class="item-thumbnail">
              <img src="images/faces/face4.jpg" alt="image" class="profile-pic">
            </div>
            <div class="item-content flex-grow">
              <h6 class="ellipsis font-weight-normal">
                David Grey
              </h6>
              <p class="font-weight-light small-text text-muted mb-0">
                The meeting is cancelled
              </p>
            </div>
          </a>
          <a class="dropdown-item">
            <div class="item-thumbnail">
              <img src="images/faces/face2.jpg" alt="image" class="profile-pic">
            </div>
            <div class="item-content flex-grow">
              <h6 class="ellipsis font-weight-normal">
                Tim Cook
              </h6>
              <p class="font-weight-light small-text text-muted mb-0">
                New product launch
              </p>
            </div>
          </a>
          <a class="dropdown-item">
            <div class="item-thumbnail">
              <img src="images/faces/face3.jpg" alt="image" class="profile-pic">
            </div>
            <div class="item-content flex-grow">
              <h6 class="ellipsis font-weight-normal">
                Johnson
              </h6>
              <p class="font-weight-light small-text text-muted mb-0">
                Upcoming board meeting
              </p>
            </div>
          </a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
          <i class="ti-bell mx-0" style="color:#fff"></i>
          <span class="count"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="notificationDropdown">
          <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
          <a class="dropdown-item">
            <div class="item-thumbnail">
              <div class="item-icon bg-success">
                <i class="ti-info-alt mx-0"></i>
              </div>
            </div>
            <div class="item-content">
              <h6 class="font-weight-normal">Application Error</h6>
              <p class="font-weight-light small-text mb-0 text-muted">
                Just now
              </p>
            </div>
          </a>
          <a class="dropdown-item">
            <div class="item-thumbnail">
              <div class="item-icon bg-warning">
                <i class="ti-settings mx-0"></i>
              </div>
            </div>
            <div class="item-content">
              <h6 class="font-weight-normal">Settings</h6>
              <p class="font-weight-light small-text mb-0 text-muted">
                Private message
              </p>
            </div>
          </a>
          <a class="dropdown-item">
            <div class="item-thumbnail">
              <div class="item-icon bg-info">
                <i class="ti-user mx-0"></i>
              </div>
            </div>
            <div class="item-content">
              <h6 class="font-weight-normal">New user registration</h6>
              <p class="font-weight-light small-text mb-0 text-muted">
                2 days ago
              </p>
            </div>
          </a>
        </div>
      </li>-->
      <li class="nav-item nav-profile dropdown">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
          <div *ngIf="roleName!=''">
            <div style="float:left;color:#fff;">
              <span>
                <span>{{userName}}</span><br />
                <span>({{roleName}})</span>
              </span>
            </div>
            <div style="float:right;margin-top: 12px;">
              <img src="assets/images/logo-left.png" alt="profile" />
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
          <!--<a class="dropdown-item">
            <i class="ti-settings text-primary"></i>
            Settings
          </a>-->
          <a class="dropdown-item" (click)="Logout()">
            <i class="ti-power-off text-primary"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
      <span class="ti-view-list"></span>
    </button>
  </div>
</nav>
