import { Component, OnInit } from '@angular/core';
import { CommonService } from '../Service/CommonService';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyService } from '../Service/CompanyService';
import { CompanyModelView } from '../../model/comapny';
import { DashboardService } from '../Service/DashboardService';
import { authenticationService } from '../../login/authenticationService.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { dasboardReport } from '../../model/dashboard';
import {  ReportParam } from '../../model/Report';

@Component({
  selector:'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ConfirmationService]
})
export class DashboardComponent implements OnInit {
  showLoader: boolean = false;
  constructor(private router: Router
    , public cmnSrv: CommonService
    , private _companyService: CompanyService
    , private authService: authenticationService
    , private messageService: MessageService, public datepipe: DatePipe
    , private dashboardService: DashboardService
  ) { }
  data: any;
  dasboardReport :Array<dasboardReport>;
  options: any;
  _companyViewModel: Array<CompanyModelView>;
  _reportParam = new ReportParam();
  StartDate: Date;
  EndDate: Date;
  SalesData: Array<{ _data: any, agenyName: string }>;

  TotalVisitor: number;
  TotalEquipment: number;
  TotalCoutnterAmount: number;
  TotalOnlineAmount: number;
  TotalAmount: number;


  ngOnInit(): void {
    this.TotalVisitor = 0;
    this.TotalEquipment = 0;
    this.TotalAmount = 0;
    this.TotalCoutnterAmount = 0;
    this.TotalOnlineAmount = 0;

    this._reportParam.companyId = 0;
    this._reportParam.selectedType = 2;
    this.dasboardReport = new Array<dasboardReport>();
    this._companyViewModel = new Array<CompanyModelView>();
    this.StartDate = new Date();
    this.EndDate = new Date();

    this.SalesData = new Array<{ _data: any, agenyName: string }>();
   
    this._reportParam.startDate = this.convertDateToString(this.StartDate.toLocaleDateString());
    this._reportParam.endDate = this.convertDateToString(this.EndDate.toLocaleDateString());
    this._reportParam.loginUserId = this.authService.userId;
    document.body.className = "sidebar-icon-only";  
    this.router.events.subscribe((obj: any) => {
      if (obj instanceof NavigationEnd) {
        this.cmnSrv.dashboardState.navbarToggle = false;
        this.cmnSrv.dashboardState.sidebarToggle = true;
        window.scrollTo(0, 0);
      }
    });

    this.GetUserPlaceMapping();
    //"'January', 'February', 'March', 'April', 'May', 'June', 'July'";
    var date = Array<string>();
    date.push('January'); date.push('February'); date.push('March'); date.push('April'); date.push('May'); date.push('June'); date.push('July')
    var val = Array<number>();
    val.push(650);
    val.push(330);
    val.push(65);
    val.push(44);
    val.push(650);
    val.push(66);
    val.push(650);
    console.log(val)
    this.data = {
      labels: date,
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: '#42A5F5',
          data: val
        }
      ]
    };
    
//,
//    {
//      label: 'My Second dataset',
//        backgroundColor: '#FFA726',
//          data: val
//    }
    this.options = {
      legend: {
        labels: {
          fontColor: '#090909'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#090909'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }],
        yAxes: [{
          ticks: {
            callback: function (label) {
              return label ;
            },
            fontColor: '#090909'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }]
      }
    };
  }

  GetUserPlaceMapping() {
    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompany(this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
        this.showLoader = false;
        this.BindDashbard();
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    })
  }

  BindDashbard() {
    this.TotalVisitor = 0;
    this.TotalEquipment = 0;
    this.TotalAmount = 0;
    this.TotalCoutnterAmount = 0;
    this.TotalOnlineAmount = 0;
    this.dasboardReport = new Array<dasboardReport>();
    this.showLoader = true;
    console.log(this._reportParam.companyId);
    this.dashboardService.GetDashboardReport(this._reportParam).subscribe(m => {
      if (m.statusCode == 200) {
        this.dasboardReport = m.result;
        this.showLoader = false;
        console.log(this.dasboardReport);
        this.BindChart();

        this.TotalVisitor = this.dasboardReport.reduce((subtotal, item) => subtotal + item.totalVisitor, 0);
        this.TotalEquipment = this.dasboardReport.reduce((subtotal, item) => subtotal + item.totalEquipment, 0);
        this.TotalCoutnterAmount = this.dasboardReport.reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);
        this.TotalOnlineAmount = this.dasboardReport.reduce((subtotal, item) => subtotal + item.onlineTotalFinalAmount, 0);
        this.TotalAmount = (this.TotalCoutnterAmount + this.TotalOnlineAmount)

        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Report get successfully.' });
        })
      }
      else {
        console.log(m.result);
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  SetStartDate(e) {
    let ss = this.convertDateToString(e);
    
  }

  SetEndDate(e) {
    let ss = this.convertDateToString(e);
   
  }
  convertDateToString(date: string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }
  PlaceChangeEvent() {
    this.BindDashbard();
  }

  RangeChangeEvent() {
    this.BindDashbard();
  }

  BindChart() {
    this.SalesData = new Array<{ _data: any, agenyName: string }>();

    var distinctPlace = this.dasboardReport.map(item => item.companyName)
      .filter((value, index, self) => self.indexOf(value) === index);

    distinctPlace.forEach((pname) => {
      let date = Array<string>();
      let counteramount = Array<number>();
      let Onlineamount = Array<number>();
      let DailyPolicyCountChardata: any;
      var templist = this.dasboardReport.filter(x => x.companyName == pname);
      if (templist) {
        templist.forEach((val) => {
          date.push(this.convertDateToString(val.createdDate));
          counteramount.push(val.totalFinalAmount);
          Onlineamount.push(val.onlineTotalFinalAmount);
        });

        DailyPolicyCountChardata = {
          labels: date,
          datasets: [
            {
              label: 'Counter',
              backgroundColor: '#42A5F5',
              data: counteramount
            }
          ]
        };
        this.SalesData.push({ _data: DailyPolicyCountChardata, agenyName: pname });
      }
    })
  }
}
//,
//{
//  label: 'Online',
//    backgroundColor: '#FFA726',
//      data: Onlineamount
//}
