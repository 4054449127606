import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './header/header.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { FooterComponent } from './footer/footer.component';



@NgModule({
  declarations: [HeaderComponent, NavmenuComponent, FooterComponent],
  exports: [HeaderComponent, NavmenuComponent, FooterComponent],
  imports: [
    CommonModule//, BrowserModule
  ]
  
})
export class SharedModule { }
