import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CompanyModelView } from '../../../model/comapny';
import { authenticationService } from '../../../login/authenticationService.service';
import { CompanyService } from '../../Service/CompanyService';
import { ReportService } from '../../Service/report-service.service';
import { ReportParam, TicketReport } from '../../../model/Report';
@Component({
  selector: 'app-delete-ticket',
  templateUrl: './delete-ticket.component.html',
  styleUrls: ['./delete-ticket.component.css'],
  providers: [ConfirmationService]
})
export class DeleteTicketComponent implements OnInit {

  showLoader: boolean = false;
  TotalVisitor: number;
  TotalEquipment: number;
  TotalCoutnterAmount: number;
  TotalOnlineAmount: number;
  TotalAmount: number;

  dropdowncompanyId: number = 0;
  _companyViewModel: Array<CompanyModelView>;
  _reportParam = new ReportParam();
  _listReport = new Array<TicketReport>();

  constructor(private authService: authenticationService
    , private _companyService: CompanyService
    , private messageService: MessageService
    , private reportService: ReportService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.TotalVisitor = 0;
    this.TotalEquipment = 0;
    this.TotalAmount = 0;
    this.TotalCoutnterAmount = 0;
    this.TotalOnlineAmount = 0;
    this._listReport = new Array<TicketReport>();
    this._reportParam = new ReportParam();
    this._reportParam.loginUserId = this.authService.userId;
    this.dropdowncompanyId = 0;
    document.body.className = "sidebar-icon-only";
    this.GetUserPlaceMapping();

  }

  GetUserPlaceMapping() {
    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompany(this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
        if (this._companyViewModel != null && this._companyViewModel.length > 0) {
          this.dropdowncompanyId = 0;
          this._reportParam.companyId = this.dropdowncompanyId;
          this.GetAllTicket();
        }

        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    })
  }
  PlaceChangeEvent() {
    this._reportParam.companyId = this.dropdowncompanyId;
    this.GetAllTicket();
  }

  DeleteTicketconfirm(ticketId: number) {
    console.log("ticketId", ticketId);
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.DeleteTicket(ticketId);
      },
      reject: () => {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'info', summary: 'Rejected', detail: 'You have rejected' });
        })
      }
    });
  }


  DeleteTicket(ticketId: number) {
    this.showLoader = true;
    let loginuserid: number = this.authService.userId;
    this.reportService.DeleteTicket(ticketId, this._reportParam.companyId ).subscribe(m => {
      if (m.statusCode == 200) {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
        this.showLoader = false;
        this.GetAllTicket();
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: m.result });
        })
        this.showLoader = false;
      }
    })
  }

  GetAllTicket() {
    if (this._reportParam.companyId > 0) {
      this.TotalVisitor = 0;
      this.TotalEquipment = 0;
      this.TotalAmount = 0;
      this.TotalCoutnterAmount = 0;
      this.TotalOnlineAmount = 0;
      this._listReport = new Array<TicketReport>();
      this.showLoader = true;
      console.log(this._reportParam.companyId);
      this.reportService.DeleteTicketReport(this._reportParam).subscribe(m => {
        if (m.statusCode == 200) {
          this._listReport = m.result;
          console.log("m.result", m.result);
          this.showLoader = false;

          this.TotalVisitor = this._listReport.reduce((subtotal, item) => subtotal + item.totalVisitor, 0);
          this.TotalEquipment = this._listReport.reduce((subtotal, item) => subtotal + item.totalEquipment, 0);
          this.TotalCoutnterAmount = this._listReport.filter(x => x.ticketBuyFrom == 1).reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);
          this.TotalOnlineAmount = this._listReport.filter(x => x.ticketBuyFrom == 2).reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);
          this.TotalAmount = this._listReport.reduce((subtotal, item) => subtotal + item.totalFinalAmount, 0);

          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'success', summary: 'Success', detail: 'Report get successfully.' });
          })
        }
        else {
          console.log(m.result);
          this.showLoader = false;
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
          })
        }
      }, err => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
        })
      })
    }
  }

}
