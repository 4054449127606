<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-1 border-primary-top" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Home </span> ><span>Report</span></h4>
            </div>
            <div class="float-lg-right" style="padding: 15px; display:none;">
              <div class="search">
                <input type="text" placeholder="Ticket No.." style="text-transform: uppercase;" name="search2" class="ticket-input">
              </div>

            </div>
            <div class="float-lg-right" style="padding: 15px;" *ngIf="_listReport.length>0">
              <button type="button" (click)="ExportClass()" class="btn btn-primary btn-sm">Export Excel</button>
            </div>
          </div>
        </div>

        <!--<p-confirmDialog [style]="{width: '40vw'}" [baseZIndex]="10000"></p-confirmDialog>-->
        <div class="card cardheight">
          <div class="card-body main-content border-primary-top">
            <div class="row">

              <div class="form-group col-2 ticket-label">
                <select name="PlaceId" class="form-control ticket-input-dropdown" [(ngModel)]="dropdowncompanyId" (change)="PlaceChangeEvent()">
                  <option [ngValue]=0>All Place</option>
                  <option *ngFor="let obj of _companyViewModel" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                </select>
              </div>
              <div class="form-group col-2 ticket-label">
                <p-calendar [readonlyInput]="true" placeholder="Start Date" class="pClander" [(ngModel)]="StartDate" dateFormat="dd-mm-yy"
                            yearRange="2021:2022" (onSelect)="SetStartDate($event)">
                </p-calendar>
              </div>
              <div class="form-group col-2 ticket-label">
                <p-calendar [readonlyInput]="true" placeholder="End Date" class="pClander" [(ngModel)]="EndDate" dateFormat="dd-mm-yy"
                            yearRange="2021:2022" (onSelect)="SetEndDate($event)">
                </p-calendar>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-4 col-xs-8">
                <div class="info-box">
                  <div class="info-box-content">
                    <span class="info-box-text">Total Visitor</span>
                    <span class="info-box-number">{{TotalVisitor}}<small></small></span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <div class="col-md-2 col-sm-4 col-xs-8">
                <div class="info-box" style="background-color:#dd4b39 !important">
                  <div class="info-box-content">
                    <span class="info-box-text">Total Equipment</span>
                    <span class="info-box-number">{{TotalEquipment}}<small></small></span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <div class="col-md-2 col-sm-4 col-xs-8">
                <div class="info-box" style="background-color:#f39c12 !important">
                  <div class="info-box-content">
                    <span class="info-box-text">Total Counter Amount</span>
                    <span class="info-box-number">{{TotalCoutnterAmount}}<small> INR</small></span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <!--<div class="col-md-2 col-sm-4 col-xs-8">
            <div class="info-box" style="background-color:#3337b2 !important">
              <div class="info-box-content">
                <span class="info-box-text">Total Online Amount</span>
                <span class="info-box-number">{{TotalOnlineAmount}}<small> INR</small></span>
              </div>
            </div>
          </div>-->
              <div class="col-md-2 col-sm-4 col-xs-8">
                <div class="info-box" style="background-color:#00a65a !important">
                  <div class="info-box-content">
                    <span class="info-box-text">Total Amount</span>
                    <span class="info-box-number">{{TotalAmount}}<small> INR</small></span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;width: 100%;">
                <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;" *ngIf="_listReport.length>0">
                  <thead>
                    <tr>
                      <th class="tabltth">Ticket No</th>
                      <th>Place</th>
                      <th>Visitor Name</th>
                      <th>Visitor Mobile No</th>
                      <th>Visitor Address</th>
                      <th>Booking Date</th>
                      <!--<th>Booking Time</th>-->
                      <th>Total Visitor</th>
                      <th>Total Equipment</th>
                      <th>Total Amount</th>
                      <th>Ticket Status</th>
                      <th>Created Date/By</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let obj of _listReport">
                      <td>{{obj.ticketNo}}</td>
                      <td>
                        {{obj.companyName}}
                      </td>
                      <td>{{obj.visitorName}}</td>
                      <td>{{obj.visitorMobileNo}}</td>
                      <td>{{obj.visitorAddress}}</td>
                      <td>{{obj.bookingDate | date:'dd-MM-yyyy'}}</td>
                      <!--<td>{{obj.bookingTime| date:'hh:mm:ss a'}}</td>-->
                      <td>{{obj.totalVisitor}}</td>
                      <td>{{obj.totalEquipment}}</td>
                      <td>&#x20b9; {{obj.totalFinalAmount}}</td>
                      <td>
                        <span *ngIf="obj.isTicketClosed">Closed</span>
                        <span *ngIf="!obj.isTicketClosed">Open</span>
                      </td>
                      <td>
                        <span><b>On </b>{{obj.createdDate | date:'dd-MM-yyyy hh:mm:ss'}}</span><br />
                        <span><b>By </b>{{obj.firstName}}</span> &nbsp; <span>{{obj.lastName}}</span>
                      </td>
                    </tr>
                  </tbody>

                </table>


              </div>
            </div>
          </div>


        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
