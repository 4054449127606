export class PrintHeader
{
  id: number;
  headerName: string;
}

export class PrintSetting {
  placeId: number;
  printHeader: Array<PrintHeader>;
  showCommentonTop: string = "";
  showVisitorAddress: boolean = false;
  showRemark: boolean = false;
  remarkComment: string = "";
  showSignature: boolean = false;
  signatureHeaderName: Array<Signature>;
  showAnyNotes: boolean = false;
  notes: Array<Notes>;
  showWebsiteName: string;
  showPhoneNumber: string;
}
export class Signature {
  id: number;
  signatureHeaderName: string;
}
export class Notes {
  id: number;
  notes: string;
}
