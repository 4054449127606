
<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top">
          <div class="card-body">
            <div class="float-lg-left">
              <h4 class="card-title">Home > Roles</h4>
            </div>
            <div class="float-lg-right">
              <button type="button" (click)="Navigate('role-create',0)" class="btn btn-primary btn-sm">Add</button>
            </div>
          </div>
        </div>

        <div class="card cardheight border-primary-top">
          <div class="card-body main-content">
            <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;">
              <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Manager</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of roleViewModel">
                    <td>{{obj.roleName}}</td>
                    <td>
                      <span *ngIf="obj.isManager">Yes</span>
                      <span *ngIf="!obj.isManager">No</span>
                    </td>
                    <td>{{obj.createdDate | date:'dd-MM-yyyy hh:mm:ss'}}</td>
                    <td>
                      <div>
                        <i (click)="EditRole(obj.roleId)" title="Edit" class="ti-pencil icon"></i>
                        <i *ngIf="obj.isLock==false" title="Delete" class="ti-trash icon"></i>
                        <i *ngIf="obj.isLock==true" title="Can't delete" class="ti-lock icon"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
            <div class="table-responsive pt-3 ">
    <ul class="pagination float-right">
      <li class="page-item disabled"><a class="page-link" href="#">Previous</a></li>
      <li class="page-item"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" href="#">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item"><a class="page-link" href="#">Next</a></li>
    </ul>
  </div>
          </div>

        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>

  </div>
