
<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" class="toastZindex" key="tr"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top">
          <div class="card-body">
            <div class="float-lg-left">
              <h4 class="card-title">Home > Place > Create</h4>
            </div>
            <div class="float-lg-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="GoToCompanyPage()">Back</button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body border-primary-top">
            <form class="form-sample" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
              <div class="row">
                <div class="form-group col-4">
                  <label>Place Name</label>
                  <input type="text" name="companyName" class="form-control" [(ngModel)]="company.companyName" #companyName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && companyName.invalid }" required>
                  <div *ngIf="f.submitted && companyName.invalid" class="invalid-feedback">
                    <div *ngIf="companyName.errors.required">Place Name is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Address</label>
                  <input type="text" name="address1" class="form-control" [(ngModel)]="company.address1" #address1="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && address1.invalid }" required>
                  <div *ngIf="f.submitted && address1.invalid" class="invalid-feedback">
                    <div *ngIf="address1.errors.required">Address1 is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Address 1</label>
                  <input type="text" name="address2" class="form-control" [(ngModel)]="company.address2" #address2="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && address2.invalid }" required>
                  <div *ngIf="f.submitted && address2.invalid" class="invalid-feedback">
                    <div *ngIf="address2.errors.required">Address2 is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>City</label>
                  <input type="text" name="city" class="form-control" [(ngModel)]="company.city" #city="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required>
                  <div *ngIf="f.submitted && city.invalid" class="invalid-feedback">
                    <div *ngIf="city.errors.required">City is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>State</label>
                  <input type="text" name="companyState" class="form-control" [(ngModel)]="company.companyState" #companyState="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && companyState.invalid }" required>
                  <div *ngIf="f.submitted && companyState.invalid" class="invalid-feedback">
                    <div *ngIf="companyState.errors.required">State is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Zip Code</label>
                  <input type="number" name="zipCode" class="form-control" [(ngModel)]="company.zipCode" #zipCode="ngModel" (keypress)="numberOnly($event)" 
                         [ngClass]="{ 'is-invalid': f.submitted && zipCode.invalid }" required>
                  <div *ngIf="f.submitted && zipCode.invalid" class="invalid-feedback">
                    <div *ngIf="zipCode.errors.required">Zip Code is required</div>
                    <div *ngIf="zipCode.errors.minlength">Zip Code must be 6 characters</div>
                    <div *ngIf="zipCode.errors.maxlength">Zip Code should be 6 characters</div>
                  </div>
                </div>


                <div class="form-group col-4">
                  <label>Email</label>
                  <input type="email" name="email" class="form-control" [(ngModel)]="company.email" #email="ngModel" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                         [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required>
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.invalid">Email must be a valid email address</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Phone No</label>
                  <input type="text" name="phoneNo" class="form-control" [(ngModel)]="company.phoneNo" #phoneNo="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && phoneNo.invalid }" required>
                  <div *ngIf="f.submitted && phoneNo.invalid" class="invalid-feedback">
                    <div *ngIf="phoneNo.errors.required">Phone No is required</div>
                  </div>
                </div>

                <div class="form-group col-4">
                  <label>Website</label>
                  <input type="text" name="website" class="form-control" [(ngModel)]="company.website" >
                 
                </div>
                <div class="col-md-4">
                  <label for="validationServer05" class="form-label">Status</label>
                  <div style="margin-top: 12px;">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline1" name="isActive" class="custom-control-input"
                             [(ngModel)]="company.isActive"
                             #isActive="ngModel"
                             [value]="true">
                      <label class="custom-control-label" for="customRadioInline1">Active</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline2" name="isActive" class="custom-control-input"
                             [(ngModel)]="company.isActive"
                             #isActive="ngModel"
                             [value]="false">
                      <label class="custom-control-label" for="customRadioInline2">Inactive</label>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-12">

                <button class="btn btn-primary float-right ml-2" (click)="GoToCompanyPage()" type="button">Cancel</button>
                <button class="btn btn-primary float-right" type="submit">Save</button>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

