import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../../shared/shared.module';
import { CommonsModule } from '../../Common/common.module';

import { OnlineticketRoutingModule } from './onlineticket-routing.module';
import { OnlineticketComponent } from './onlineticket/onlineticket.component';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CommonService } from '../Service/CommonService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { NgxCaptchaModule  } from 'ngx-captcha';

import { DatePipe } from '@angular/common';
import { PaymentSuccessfulComponent } from './payment-successful/payment-successful.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component'

@NgModule({
  declarations: [OnlineticketComponent, PaymentSuccessfulComponent, PaymentFailedComponent],
  imports: [
    CommonModule,
    OnlineticketRoutingModule
    , FormsModule
    , BrowserAnimationsModule
    , SharedModule
    , CommonsModule
    , ToastModule
    , ConfirmDialogModule
    , TooltipModule
    , DialogModule
    , CalendarModule, NgxCaptchaModule
  ],
  providers: [MessageService, CommonService, DatePipe]
})
export class OnlineticketModule { }
