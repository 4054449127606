import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../../model/userModel';
import { MessageService } from 'primeng/api';
import { UserService } from '../../Service/userService'
import { Router, ActivatedRoute } from '@angular/router';
import { roleViewModel } from '../../../model/roleModel';
import { RoleService } from '../../Service/RoleService';
import { authenticationService} from '../../../login/authenticationService.service';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  showLoader: boolean = false;
  user = new UserModel();
  roleList = new Array<roleViewModel>();
  loginUserId: number;
  public changePasswordPopup: boolean;
  loginUserName: string = "";
  passwordChange: string = "";
  constructor(private messageService: MessageService, private userService: UserService, private router: Router
    , private route: ActivatedRoute, private roleService: RoleService, private authService: authenticationService) { }


  ngOnInit(): void {
    document.body.className = "sidebar-icon-only";
    this.user = new UserModel();
    this.roleList = new Array<roleViewModel>();
    this.loginUserId = this.authService.userId;
    this.loginUserName = this.authService.user.userName;
    console.log("this.authService.loginName", this.authService.user.userName);
    this.GetAllRoles();
    var id = this.route.snapshot.params['id'];
    if (id) {
      this.GetUserByuserId(id);
    }
  }

  GetUserByuserId(userId: number) {
    this.showLoader = true;
    this.user = new UserModel();
    this.userService.GetUserByuserId(userId).subscribe(m => {
      if (m.statusCode == 200) {
        this.user = m.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'User', detail: 'Get Single user data successfully.' });
        })
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'User', detail: 'Data not found.' });
        })
      }
    },
      error => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
        })
      })
  }
  onSubmit() {
    this.showLoader = true;
    this.user.loginUserId = this.loginUserId;
    this.user.adminUserId = this.authService.adminUserId;
    this.userService.saveUpdateUser(this.user).subscribe(m => {
      if (m.statusCode == 200) {
        this.user.firstName = '';
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'User', detail: m.result });
        })
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'warn', summary: 'warn', detail: m.result });
        })
        this.showLoader = false;
      }
    },
      error => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
        })
      })
  }

  GetAllRoles() {
    this.showLoader = true;
    this.roleService.GetRolesByLoginUserId(this.loginUserId).subscribe(m => {
      if (m.statusCode == 200) {
        this.roleList = m.result;
        this.showLoader = false;
      }
    },
      error => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
        })
      })
  }

  GoToUserPage() {
    this.router.navigate(['users']);
  }

  ChangePassword() {
    this.changePasswordPopup = true;
  }

  ClosePassword() {
    this.changePasswordPopup = false;
  }
  savePassword() {
    this.showLoader = true;
    if (this.passwordChange == null || this.passwordChange == undefined) {
      alert("Please enter the password");
      this.showLoader = false;
      return false;
    }
    else {
      var param = {
        userId: this.loginUserId,
        password: this.passwordChange
      }

      this.userService.UpdatePassword(param).subscribe(m => {
        if (m.statusCode == 200) {
          this.showLoader = false;
          this.changePasswordPopup = false;
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'success', summary: 'Password', detail: m.result });
          })
        }
        else {
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'warn', summary: 'warn', detail: m.result });
          })
          this.showLoader = false;
        }
      },
        error => {
          this.showLoader = false;
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
          })
        })
    }
  }
}
  

