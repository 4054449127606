import { Component, OnInit, HostListener } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PlaceMapping } from '../../../model/place-mapping';
import { authenticationService } from '../../../login/authenticationService.service';
import { CompanyService } from '../../Service/CompanyService';
import { TicketService} from '../../Service/TicketService';
import { VisitorTickets, VisitorCategoryTypeDetail, TicketAmountDetails, VisitorsTicketDetails } from '../../../model/Tickets';
import { DatePipe } from '@angular/common';
import { CompanyModelView } from '../../../model/comapny';
import { PrintSetting, PrintHeader, Signature, Notes } from '../../../model/Setting';
import { PrintSettingService } from '../../Service/PrintSettingService';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css'],
  providers: [ConfirmationService]
})
export class TicketsComponent implements OnInit {
  showLoader: boolean=false;
  _placeMapping: Array<PlaceMapping>;
  _visitorTicket: VisitorTickets;

  _printVisitorTicket: VisitorTickets;

  _companyViewModel: Array<CompanyModelView>;
  TicketInvoice: boolean = false;
  _ticketInvoice: VisitorTickets;

  isHourly: boolean = false;
  isLateCharges: boolean = false;
  numberOfColumnSpan: number = 8;
  dropdowncompanyId: number;
  invalidDates: Array<Date>

  totalVisitor: number=0;
  totalEquipment: number=0;
  public TicketConfmBeforeSave: boolean;
  TempvisitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
  TempvisitorCategoryTypeDetailCollectionNoneHourly = new Array<VisitorCategoryTypeDetail>();

  _tempInvoiceHourlyCategory = new Array<VisitorCategoryTypeDetail>();
  _tempInvoiceNoneHourlyCategory = new Array<VisitorCategoryTypeDetail>();

  bookingDate: Date;
  bookingTime: Date;
  searchTicketNo: string;
  _printSetting: PrintSetting;

  constructor(private authService: authenticationService
    , private _companyService: CompanyService
    , private messageService: MessageService
    , private _visitorTicketService: TicketService, public datepipe: DatePipe
  ,private printSettingService: PrintSettingService) { }

  ngOnInit(): void {
    this.searchTicketNo = "";
    this.bookingDate = new Date();
    this.bookingTime = new Date();
    this._visitorTicket = new VisitorTickets();
    this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();

    this._ticketInvoice = new VisitorTickets();
    this._ticketInvoice.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._ticketInvoice.ticketAmountDetails = new TicketAmountDetails();
    this._ticketInvoice.visitorsTicketDetails = new VisitorsTicketDetails();

    this._printVisitorTicket = new VisitorTickets();
    this._printVisitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._printVisitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._printVisitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();

    this.TempvisitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this.TempvisitorCategoryTypeDetailCollectionNoneHourly = new Array<VisitorCategoryTypeDetail>();

    this._tempInvoiceHourlyCategory = new Array<VisitorCategoryTypeDetail>();
    this._tempInvoiceNoneHourlyCategory = new Array<VisitorCategoryTypeDetail>();

    document.body.className = "sidebar-icon-only";

    this._printSetting = new PrintSetting();
    this._printSetting.printHeader = new Array<PrintHeader>();
    this._printSetting.signatureHeaderName = new Array<Signature>();
    this._printSetting.notes = new Array<Notes>();

    this.GetUserPlaceMapping();

    let today = new Date();
    let invalidDate = new Date();
    invalidDate.setDate(today.getDate() - 1);
    this.invalidDates = [today, invalidDate];

  }

  GetUserPlaceMapping() {
    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompany(this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
        if (this._companyViewModel != null && this._companyViewModel.length > 0) {
          this.dropdowncompanyId = this._companyViewModel[0].companyId;
          this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
          this.GetPrintSetting(this.dropdowncompanyId);
        }

        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Place not map with you. Please contact your Admin.' });
        })
      }
    })
  }

  PlaceChangeEvent() {
    this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
    this.GetPrintSetting(this.dropdowncompanyId);
  }

  setDate(e) {
    this._visitorTicket.visitorsTicketDetails.bookingDate = this.convertDateToString(e);
  }

  setTime(e) {
    this._visitorTicket.visitorsTicketDetails.bookingTime = this.ConvertDateTimeToString(e);
  }

  convertDateToString(date:string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }

  ConvertDateTimeToString(date:string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy hh:mm:ss a');
  }

  GetAllPlaceVisitorFee(companyId: number) {
    this.numberOfColumnSpan = 2;
    this._placeMapping = [];
    this.isHourly = false;
    this.isLateCharges = false;
    this.showLoader = true;
    let ticketNo: string = "-1";

    this._visitorTicket = new VisitorTickets();
    this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
    this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();

    this.bookingDate = new Date();
    this.bookingTime = new Date();

    this._visitorTicketService.VisitorTicket(companyId, this.authService.userId, ticketNo).subscribe(m => {
      if (m.statusCode == 200) {
        console.log("result",m.result);
        this._visitorTicket.visitorCategoryTypeDetailCollection = m.result.visitorCategoryTypeDetailCollection;
        this._visitorTicket.ticketAmountDetails = m.result.ticketAmountDetails;
        this._visitorTicket.visitorsTicketDetails = m.result.visitorsTicketDetails;
        
        if (this._visitorTicket.ticketAmountDetails==null) {
          this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
        }
        if (this._visitorTicket.visitorsTicketDetails == null) {
          this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
        }
        console.log(this._visitorTicket);
        if (this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly === true).length > 0) {
          this.isHourly = true;
        }
        if (this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.lateFee === true).length > 0) {
          this.isLateCharges = true;
        }
        if (this.isHourly) {
          this.numberOfColumnSpan = this.numberOfColumnSpan + 1;
        }
        if (this.isLateCharges) {
          this.numberOfColumnSpan = this.numberOfColumnSpan + 3;
        }
        
        console.log(this._placeMapping);
        this.showLoader = false;
      }
      else {
        if (m.statusCode == 404) {
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message visitor type does not map with company. Please contact with Developer.' });
          })
        }
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  //onKeypressEvent(event: any) {
  //  if (event.target.value.length > 3) {
  //    return false;
  //  }
  //}

  CalculateVisitorAmount() {
    this._visitorTicket.visitorCategoryTypeDetailCollection.forEach(function (value) {
      value.totalAmount = (value.numberofVisitor * value.visitorFee);
    });

    this._visitorTicket.ticketAmountDetails.totalVisitor = this._visitorTicket.visitorCategoryTypeDetailCollection.reduce((subtotal, item) => subtotal + item.numberofVisitor, 0);
    this._visitorTicket.ticketAmountDetails.totalVisitorAmount = this._visitorTicket.visitorCategoryTypeDetailCollection.reduce((subtotal, item) => subtotal + item.totalAmount, 0);

    this._visitorTicket.ticketAmountDetails.totalFinalAmount = this._visitorTicket.ticketAmountDetails.totalVisitorAmount;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else if (event.target.value.length > 3) {
      return false;
    }
    return true;

  }

  SaveTicket() {
    this.TempvisitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this.TempvisitorCategoryTypeDetailCollectionNoneHourly = new Array<VisitorCategoryTypeDetail>();
    this.totalVisitor = 0;
    this.totalEquipment = 0;
    this._visitorTicket.visitorsTicketDetails.companyId = this.dropdowncompanyId;
    this._visitorTicket.visitorsTicketDetails.loginUserId = this.authService.userId;
    this.totalEquipment = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => (x.visitorCategoryTypeId==2)).reduce((subtotal, item) => subtotal + item.numberofVisitor, 0);
    if (this.totalEquipment > 0) {
      this.TempvisitorCategoryTypeDetailCollection = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly == true || x.visitorCategoryTypeId == 2 && x.numberofVisitor > 0);
    }

    this.totalVisitor = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => (x.visitorCategoryTypeId == 1)).reduce((subtotal, item) => subtotal + item.numberofVisitor, 0);

    if (this.totalVisitor > 0) {
      this.TempvisitorCategoryTypeDetailCollectionNoneHourly = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => (x.hourly == false && x.visitorCategoryTypeId == 1) && x.numberofVisitor > 0);
    }

    if (!this._visitorTicket.visitorsTicketDetails.bookingDate) {
      this._visitorTicket.visitorsTicketDetails.bookingDate = this.convertDateToString(this.bookingDate.toLocaleDateString());
    }
    if (!this._visitorTicket.visitorsTicketDetails.bookingTime) {
      this._visitorTicket.visitorsTicketDetails.bookingTime = this.bookingDate.toLocaleTimeString();
    } else {
      this._visitorTicket.visitorsTicketDetails.bookingTime = this.bookingDate.toLocaleTimeString();
    }

    if (this.totalVisitor > 0 || this.totalEquipment>0) {
      this.TicketConfmBeforeSave = true;
    }
    else {
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Please enter visitor details.' });
      })
    }

    //this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
  }

  SaveTicketPrint() {
    this.TicketConfmBeforeSave = false;
    this.showLoader = true;
    this._visitorTicketService.SaveUpdateTicket(this._visitorTicket).subscribe(m => {
      if (m.statusCode == 200) {
        this.TicketConfmBeforeSave = false;
        console.log(m.result);
        let ticketNo = m.result;
        this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
        this.PrintTicket(ticketNo);
       
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  private utcToLocal(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  PrintTicket(ticketNo) {
    if (ticketNo) {
      this.numberOfColumnSpan = 2;
      this._placeMapping = [];
      this.isHourly = false;
      this.isLateCharges = false;
      this.showLoader = true;
    
      this._printVisitorTicket = new VisitorTickets();
      this._printVisitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
      this._printVisitorTicket.ticketAmountDetails = new TicketAmountDetails();
      this._printVisitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();

      this._visitorTicketService.VisitorTicket(this.dropdowncompanyId, this.authService.userId, ticketNo).subscribe(m => {
        if (m.statusCode == 200) {

          this._printVisitorTicket.visitorCategoryTypeDetailCollection = m.result.visitorCategoryTypeDetailCollection;
          this._printVisitorTicket.ticketAmountDetails = m.result.ticketAmountDetails;
          this._printVisitorTicket.visitorsTicketDetails = m.result.visitorsTicketDetails;

          this.bookingDate = new Date(this._printVisitorTicket.visitorsTicketDetails.bookingDate);
          this.bookingTime = new Date(this._printVisitorTicket.visitorsTicketDetails.bookingTime);

          if (this._printVisitorTicket.ticketAmountDetails == null) {
            this._printVisitorTicket.ticketAmountDetails = new TicketAmountDetails();
          }
          if (this._printVisitorTicket.visitorsTicketDetails == null) {
            this._printVisitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
          }

          if (this._printVisitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly === true).length > 0) {
            this.isHourly = true;
          }
          if (this._printVisitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.lateFee === true).length > 0) {
            this.isLateCharges = true;
          }
          if (this.isHourly) {
            this.numberOfColumnSpan = this.numberOfColumnSpan + 1;
          }
          if (this.isLateCharges) {
            this.numberOfColumnSpan = this.numberOfColumnSpan + 3;
          }
          this.showLoader = false;
          this.searchTicketNo = "";
          this.printPirview();
        }
        else {
          if (m.statusCode == 404) {
            setTimeout(() => {
              this.messageService.add(
                { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message visitor type does not map with company. Please contact with Developer.' });
            })
          }
          this.showLoader = false;
        }
      }, err => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
        })
      })
    }
    else {
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Please enter Ticket No' });
      })
    }
  }
  SearchTicketNo() {
    if (this.searchTicketNo) {
      this.numberOfColumnSpan = 2;
      this._placeMapping = [];
      this.isHourly = false;
      this.isLateCharges = false;
      this.showLoader = true;

      this._visitorTicket= new VisitorTickets();
      this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
      this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
      this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();

      this._visitorTicketService.VisitorTicket(this.dropdowncompanyId, this.authService.userId, this.searchTicketNo).subscribe(m => {
        if (m.statusCode == 200) {
         
          this._visitorTicket.visitorCategoryTypeDetailCollection = m.result.visitorCategoryTypeDetailCollection;
          this._visitorTicket.ticketAmountDetails = m.result.ticketAmountDetails;
          this._visitorTicket.visitorsTicketDetails = m.result.visitorsTicketDetails;

          this.bookingDate = new Date(this._visitorTicket.visitorsTicketDetails.bookingDate);
          this.bookingTime = new Date(this._visitorTicket.visitorsTicketDetails.bookingTime);

          if (this._visitorTicket.ticketAmountDetails == null) {
            this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
          }
          if (this._visitorTicket.visitorsTicketDetails == null) {
            this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
          }
          
          if (this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly === true).length > 0) {
            this.isHourly = true;
          }
          if (this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.lateFee === true).length > 0) {
            this.isLateCharges = true;
          }
          if (this.isHourly) {
            this.numberOfColumnSpan = this.numberOfColumnSpan + 1;
          }
          if (this.isLateCharges) {
            this.numberOfColumnSpan = this.numberOfColumnSpan + 3;
          }
          this.showLoader = false;
          this.searchTicketNo = "";
        }
        else {
          if (m.statusCode == 204) {
            this.GetAllPlaceVisitorFee(this.dropdowncompanyId); this.searchTicketNo = "";
            setTimeout(() => {
              this.messageService.add(
                { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
            })
          }
          this.showLoader = false;
        }
      }, err => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
        })
      })
    }
    else {
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Please enter Ticket No' });
      })
    }
  }

  GetPrintSetting(placeId: number) {
    this._printSetting = new PrintSetting();
    this._printSetting.printHeader = new Array<PrintHeader>();
    this._printSetting.signatureHeaderName = new Array<Signature>();
    this._printSetting.notes = new Array<Notes>();

    this.showLoader = true;
    this._printSetting = new PrintSetting();
    console.log(placeId);
    this.printSettingService.GetPrintSetting(placeId).subscribe(m => {
      console.log(m.statusCode);
      if (m.statusCode == 200) {
        this._printSetting = m.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get Print Setting succefully.' });
        })
      }
      else {
        this._printSetting = new PrintSetting();
        this._printSetting.printHeader = new Array<PrintHeader>();
        this._printSetting.signatureHeaderName = new Array<Signature>();
        this._printSetting.notes = new Array<Notes>();
      }

    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }

  printPirview() {
    var topHeader = "";
    var body = '<html><head>  <link href="dist/css/font-awesome/css/font-awesome.min.css" rel="stylesheet" /> <style>.orderdetail td{padding: 0px;}.table12 {border-collapse: collapse;} .table12 th, .table12 td {border: 1px solid black;}.orderdetailatt{font-weight:bold;border-right:1px solid grey;text-align:right}</style><title>Print Window</title><style type="text/css" media="print">\
                        @page {size: auto;   /* auto is the initial value */ margin: 0mm;  /* this affects the margin in the printer settings */}</style></head><body onload="window.print()" style="font-family:verdana;margin: 0px;font-size: 9px;">\ '
    body += '<div style="width:270px;">';
    this._printSetting.printHeader.forEach(function (value) {
      body += '<div style = "text-align:center;font-size:10px;font-weight:bold;text-transform: uppercase;" > ' + value.headerName + ' </div>';
    });
    if (this._printSetting.showCommentonTop) {
      body += '<div style="font-size: 10px;font-weight: bold;"><div style="border-top: solid 1px black;" >' + this._printSetting.showCommentonTop + '</div></div>'
    }
    body += '<table class="table12" style="font-size:9px;margin-left: 0px;width:270px;font-weight: bold;">'
    body += '<tr> <td colspan="2"> Date:' + this.convertDateToString(this._printVisitorTicket.visitorsTicketDetails.bookingDate); + ' </td>'
    body += '<td colspan="2">Time:' + this.datepipe.transform(this._printVisitorTicket.visitorsTicketDetails.bookingTime, 'hh:mm:ss a')+'</td> </tr>'
    body += '<tr> <td colspan="2"> Ticket No </td><td colspan="2">' + this._printVisitorTicket.visitorsTicketDetails.ticketNo+'</td> </tr>'
    body += '<tr> <td colspan="2"> Name </td><td colspan="2">' + this._printVisitorTicket.visitorsTicketDetails.visitorName+'</td> </tr>'
    body += '<tr>'
    body += '<td colspan="2"> Mobile No </td>'
    body += '<td colspan = "2" > ' + this._printVisitorTicket.visitorsTicketDetails.visitorMobileNo+' </td>'
    body += '</tr>'
    if (this._printSetting.showVisitorAddress) {
      body += '<tr><td colspan="4">Address: ' + this._printVisitorTicket.visitorsTicketDetails.visitorAddress+'</td></tr>'
    }
    body += '<tr><td colspan="2">No of visitors</td><td colspan="2">' + this._printVisitorTicket.ticketAmountDetails.totalVisitor+'</td></tr>'
    body += '<tr> <td colspan="2">No of Equipment </td><td colspan="2">' + this._printVisitorTicket.ticketAmountDetails.totalEquipment + '</td> </tr>'

    if (this._printVisitorTicket.ticketAmountDetails.totalEquipment > 0) {
      body += '<tr><td colspan="4" style="text-align:center;">Equipment Details</td></tr>'
      body += '<tr> <td>Type </td><td>No</td> <td>Tariff </td><td>Total</td> </tr>';
      this._printVisitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly == true || x.visitorCategoryTypeId==2).forEach(function (value) {
        body += '<tr> <td>' + value.visitorCategory + ' </td><td>' + value.numberofVisitor + '</td > <td>' + value.visitorFee + '(per/' + value.hoursInMint / 60 + ' HR)</td><td>' + value.totalAmount + '</td> </tr>'
      });
    }
    body += '<tr><td colspan="4" style="text-align:center;">Visitor Details</td></tr>'
    body += '<tr> <td>Category </td><td>No</td> <td>Tariff </td><td>Total</td> </tr>';

    this._printVisitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly == false && x.visitorCategoryTypeId == 1).forEach(function (value) {
      body += '<tr> <td>' + value.visitorCategory + ' </td><td>' + value.numberofVisitor + '</td > <td>' + value.visitorFee + ' </td><td>' + value.totalAmount+'</td> </tr>'
    });
    body += '<tr> <td colspan="3"> Total Amount </td><td>' + this._printVisitorTicket.ticketAmountDetails.totalFinalAmount+'</td> </tr>'
    body += '</table>'
    if (this._printSetting.showRemark && this._printSetting.remarkComment) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;"><div>' + this._printSetting.remarkComment + '</div></div>'
    }
    if (this._printSetting.showSignature) {
      body += '<div style="text-align: right;margin-top: 4px;font-size: 11px;font-weight: bold;width:130px;width: 198px;margin-left: 70px;margin-bottom: 15px;margin-top: 10px;">'
      body += '<div>'
      this._printSetting.signatureHeaderName.forEach(function (value) {
        body += '<div> ' + value.signatureHeaderName + ' </div>';
      });
      body += '</div>'
      body += '</div>'
    }
    if (this._printSetting.showAnyNotes) {
      this._printSetting.notes.forEach(function (value) {
        body += '<div style="text-align: left;margin-top: 4px;font-size: 11px;font-weight: bold;">' + value.notes + '</div>'
      });
    }
    if (this._printSetting.showWebsiteName) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;font-weight: bold;">Website: ' + this._printSetting.showWebsiteName + '</div>'
    }
    if (this._printSetting.showPhoneNumber) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;font-weight: bold;">Phone No: ' + this._printSetting.showPhoneNumber + '</div>'
    }
    body += "</div></body></html>";

   
    this.printContent(body);
    this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
  }

  printContent(html) {
    var newWin = window.open('', 'Print Window', 'height=600,width=800');
    newWin.document.open();
    newWin.document.write(html);
    newWin.document.close();

    //newWin.focus();
    //newWin.print();
    //newWin.close();

    //setTimeout(() => {
    //  newWin.close();
    //},1000)

  
  }

  Clear() {
    this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
  }

  GenerateInvoice(ticketId: number) {
    this._ticketInvoice = new VisitorTickets();
    this._ticketInvoice.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._ticketInvoice.ticketAmountDetails = new TicketAmountDetails();
    this._ticketInvoice.visitorsTicketDetails = new VisitorsTicketDetails();
    this.TicketInvoice = false;
    this.showLoader = true;
    this._visitorTicketService.GenerateInvoice(this.dropdowncompanyId, this.authService.userId, ticketId).subscribe(m => {
      if (m.statusCode == 200) {
        this._ticketInvoice.visitorCategoryTypeDetailCollection = m.result.visitorCategoryTypeDetailCollection;
        this._ticketInvoice.ticketAmountDetails = m.result.ticketAmountDetails;
        this._ticketInvoice.visitorsTicketDetails = m.result.visitorsTicketDetails;

        this._tempInvoiceHourlyCategory = this._ticketInvoice.visitorCategoryTypeDetailCollection.filter(x => x.hourly == true && x.numberofVisitor > 0);
        this._tempInvoiceNoneHourlyCategory = this._ticketInvoice.visitorCategoryTypeDetailCollection.filter(x => x.hourly == false && x.numberofVisitor > 0);

        this.TicketInvoice = true;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Invoice has benn generate succefully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Please enter Ticket No' });
        })
      }
    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }

  CloseInvoice() {
    this.TicketInvoice = false;
    this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
    this._ticketInvoice = new VisitorTickets();
    this._ticketInvoice.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._ticketInvoice.ticketAmountDetails = new TicketAmountDetails();
    this._ticketInvoice.visitorsTicketDetails = new VisitorsTicketDetails();
  }

  SendSMS() {
    this._visitorTicketService.SendSMS(this.dropdowncompanyId, this.authService.userId, this._visitorTicket.visitorsTicketDetails.ticketNo).subscribe(m => {
      console.log(m);
      if (m.statusCode == 200) {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'SMS has been sent succefully.' });
        })
        this.Clear();
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Error', detail: 'SMS not sent, Please try again.' });
        })
      }
    })
  }

  @HostListener('window:keydown.alt.i', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this._visitorTicket.visitorsTicketDetails.ticketId > 0 && this._visitorTicket.visitorsTicketDetails.isTicketClosed==false) {
      this.GenerateInvoice(this._visitorTicket.visitorsTicketDetails.ticketId);
    }
  }
}
