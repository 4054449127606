<div class="container-scroller">
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end" style="width: calc(100% - 0px);">
      <div class="col-12">
        <div class="col-6" style="float:left;">
          <span style="font-size: 30px; color: #fff;">WILDLIFE DIVISION SHIMLA (H.P.)</span>
        </div>
        <div class="col-5" style="float:left;">
          <ul class="navbar-nav mr-lg-2" style="float: right;">
            <li class="nav-item nav-search d-none d-lg-block">
              <a style="font-size: 21px; color: #fff;cursor:pointer;" (click)="Navigate()">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="container-fluid page-body-wrapper">
    <div class="main-panel" style="width: calc(100% - 0px);">
      <div class="content-wrapper">
        <div class="card cardheight">
          <div style="text-align:center;">
            <img style="width: 181px;" src="../../../../assets/images/thank-you.png" />
          </div>
          <div style="text-align:center;">
            <span>
              Your payment was successful.
            </span>
          </div>

          <div class="col-lg-12" style="flex:0;">
            <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;">
              <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                <tr>
                  <td>Order Number</td>
                  <td>{{_visitorTicket.cCVResponse.order_id}}</td>
                </tr>
                <tr>
                  <td>Tracking Id</td>
                  <td>{{_visitorTicket.cCVResponse.tracking_id}}</td>
                </tr>
                <tr>
                  <td>Referance Number</td>
                  <td>{{_visitorTicket.cCVResponse.bank_ref_no}}</td>
                </tr>
                <tr>
                  <td>Payment Mode</td>
                  <td>{{_visitorTicket.cCVResponse.payment_mode}}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>{{_visitorTicket.cCVResponse.amount}}</td>
                </tr>

              </table>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="col-12">
            <button type="button" class="float-right m-2" pButton (click)="printPirview()" label="Print Ticket"></button>
          </div>
        </div>

        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
