<div class="container-scroller">
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end" style="width: calc(100% - 0px);">
      <div class="col-12">
        <div class="col-6" style="float:left;">
          <span style="font-size: 30px; color: #fff;">WILDLIFE DIVISION SHIMLA (H.P.)</span>
        </div>
        <div class="col-5" style="float:left;">
          <ul class="navbar-nav mr-lg-2" style="float: right;">
            <li class="nav-item nav-search d-none d-lg-block">
              <a style="font-size: 21px; color: #fff;cursor:pointer;" (click)="Navigate()">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="container-fluid page-body-wrapper">
    <div class="main-panel" style="width: calc(100% - 0px);">
      <div class="content-wrapper">
        <div class="card cardheight">
          <div style="text-align:center;">
            <img style="width: 600px;" src="../../../../assets/images/Paymentfailedforweb.png" />
          </div>
        </div>

        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
