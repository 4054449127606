import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { DashboardComponent } from './dashboard.component';

import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';
import { DatePipe } from '@angular/common';
@NgModule({
  declarations: [DashboardComponent],
  imports: [
    FormsModule,
    BrowserModule,
    DashboardRoutingModule
    , SharedModule
    , ChartModule
    , CalendarModule
  ],
  providers: [DatePipe]
})
export class DashboardModule {
    constructor() {
      console.log("DashboardModule");
    }}



