export class roleModel {
  roleName: string;
  isManager: boolean=false;
}

export class roleViewModel {
  roleId: number;
  roleName: string;
  isManager: boolean;
  createdDate: Date;
  isLock: boolean= false;
}
