export class VisitorCategoryModel {
  visitorCategoryId: number;
  visitorCategory: string;
  visitorCategoryTypeId: number=0;
  isLateFeeApplicable: boolean = false;
  isHourly: boolean = false;
  loginUserId: number;
}

export class VisitorCategoryTypeModel {
  visitorCategoryTypeId: number;
  visitorCategoryType:string
}

export class VisitorCategoryViewModel {
  visitorCategoryId: number;
  visitorCategory: string;
  visitorCategoryTypeId: number;
  visitorCategoryType: string;
  isLateFeeApplicable: boolean
  isHourly: boolean
  createdBy: string;
  updatedBy: string;
  createdDate: Date;
  updatedDate: Date;
}
