import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import { VisitorTickets } from '../../model/Tickets';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
 

  header: HttpHeaders;
  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });
  }

  VisitorTicket(companyId:number,loginUserId:number,ticketNo:string) {
    const url = `${this._auth.baseURL}Ticket/VisitorTicket/` + companyId + '/' + loginUserId + '/' + ticketNo;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  SaveUpdateTicket(_visitorTicket: VisitorTickets) {
    const url = `${this._auth.baseURL}Ticket/SaveUpdateTicket`;
    let headers = this.header;
    return this.http.post<any>(url, _visitorTicket, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  GenerateInvoice(companyId: number, loginUserId: number, ticketId: number) {
    const url = `${this._auth.baseURL}Ticket/GenerateInvoice/` + companyId + '/' + loginUserId + '/' + ticketId;
    let headers = this.header;
    return this.http.post<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }));
  }
  SendSMS(companyId: number, loginUserId: number, ticketNo: string) {
    const url = `${this._auth.baseURL}Ticket/SendSMS/` + companyId + '/' + loginUserId + '/' + ticketNo;
    let headers = this.header;
    return this.http.post<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }));
  }
}
