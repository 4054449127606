import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
  dashboardState = {
    navbarToggle: false,
    sidebarToggle: true,
    sidebarMiniToggle: false
  };

  sidebarToggle(): void {
    this.dashboardState.sidebarToggle = !this.dashboardState.sidebarToggle;
    var isMobileVersion = document.getElementsByClassName('sidebar-icon-only');
    if (isMobileVersion.length>0) {
      document.body.className = "";
    }
    else {
      document.body.className = "sidebar-icon-only";
    }
  }

  sidebarMiniToggle(): void {
    this.dashboardState.sidebarMiniToggle = !this.dashboardState.sidebarMiniToggle; console.log("sidebarMiniToggle");
    document.body.className = "";  
  }

  navbarToggle(): void {
    this.dashboardState.navbarToggle = !this.dashboardState.navbarToggle; console.log("navbarToggle");
    document.body.className = "";  
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
