import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./style.css']
})
export class HomeComponent implements OnInit {
  //slideImage1: boolean = true;
  slideImage2: boolean = false;
  //slideImage3: boolean = false;
  //slideImage4: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.slideImage2 = true;
  }

  Navigate(e) {
    if (e == 'empLogin') {
      this.router.navigateByUrl("/empLogin");
    }
    else if (e == 'onlineticket') {
      this.router.navigateByUrl("/online-ticket");
    }
  }
}
