import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import {Company } from '../../model/comapny';
import { PlaceMapping } from '../../model/place-mapping';
import { SNoPlaceMapping } from '../../model/SNoPlaceMapping';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  header: HttpHeaders;
  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });
  }

  SaveUpdateCompany(company: Company) {
    console.log(company);
    const url = `${this._auth.baseURL}Company/SaveUpdateCompany`;
    let headers = this.header;
    return this.http.post<any>(url, company, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  SaveUpdatePlaceMappingCategory(placeMapping: Array<PlaceMapping>) {
    const url = `${this._auth.baseURL}Company/SaveUpdatePlaceMappingCategory`;
    let headers = this.header;
    return this.http.post<any>(url, placeMapping, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  getAllCompany(loginUserId:number) {
    const url = `${this._auth.baseURL}Company/GetAllCompany/` + loginUserId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  getAllCompanyForOnline() {
    const url = `${this._auth.baseURL}Company/GetAllCompanyForOnline`;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  getSingelCompany(companyId: number) {
    const url = `${this._auth.baseURL}Company/GetSingelCompany/` + companyId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  Delete(companyId: number,loginuserId:number) {
    const url = `${this._auth.baseURL}Company/DeleteCompany/` + companyId + '/' + loginuserId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  GetAllPlaceMappingData(companyId: number,loginUserId:number) {
    const url = `${this._auth.baseURL}Company/GetAllPlaceMappingData/` + companyId + '/' + loginUserId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  SaveUpdateVisitorFee(loginUserId:number,placeMapping: Array<PlaceMapping>) {
    const url = `${this._auth.baseURL}Company/SaveUpdateVisitorFee?loginUserId=` + loginUserId;
    let headers = this.header;
    return this.http.post<any>(url, placeMapping, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  GetSNoMapping(userId:number) {
    const url = `${this._auth.baseURL}Company/GetSerialNoMappings/` + userId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  SaveUpdateSerialNo(loginUserId: number, placeMapping: Array<SNoPlaceMapping>) {
    const url = `${this._auth.baseURL}Company/SaveUpdateSerialNo?loginUserId=` + loginUserId;
    let headers = this.header;
    return this.http.post<any>(url, placeMapping, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

}
