
<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" class="toastZindex" key="tr"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2 border-primary-top">
          <div class="card-body">
            <div class="float-lg-left">
              <h4 class="card-title">Home > Visitor Category > Create</h4>
            </div>
            <div class="float-lg-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="GoToVisitorcategoryPage()">Back</button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body border-primary-top">
            <form class="form-sample" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
              <div class="row">
                <div class="form-group col-4">
                  <label>Visitor Category</label>
                  <input type="text" name="visitorCategory" class="form-control" [(ngModel)]="visitorCategoryModel.visitorCategory" #visitorCategory="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && visitorCategory.invalid }" required>
                  <div *ngIf="f.submitted && visitorCategory.invalid" class="invalid-feedback">
                    <div *ngIf="visitorCategory.errors.required">Visitor Category is required</div>
                  </div>
                </div>
                <div class="form-group col-4">
                  <label>Visitor Category</label>
                  <select name="visitorCategoryTypeId" class="form-control" [(ngModel)]="visitorCategoryModel.visitorCategoryTypeId" #visitorCategoryTypeId="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && visitorCategoryModel.visitorCategoryTypeId==0 }"
                          required>
                    <option value="0">Select Category Type</option>
                    <option *ngFor="let obj of visitorCategoryTypeModel" [ngValue]="obj.visitorCategoryTypeId">{{obj.visitorCategoryType}}</option>
                  </select>
                  <div *ngIf="f.submitted && visitorCategoryModel.visitorCategoryTypeId==0" class="invalid-feedback">
                    <div *ngIf="visitorCategoryModel.visitorCategoryTypeId==0">Visitor Category Type is required</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="validationServer05" class="form-label">Hourly</label>
                  <div style="margin-top: 12px;">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline15" name="isHourly" class="custom-control-input"
                             [(ngModel)]="visitorCategoryModel.isHourly"
                             #isHourly="ngModel"
                             [value]="true">
                      <label class="custom-control-label" for="customRadioInline15">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline24" name="isHourly" class="custom-control-input"
                             [(ngModel)]="visitorCategoryModel.isHourly"
                             #isHourly="ngModel"
                             [value]="false">
                      <label class="custom-control-label" for="customRadioInline24">No</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <label for="validationServer05" class="form-label">Late Fee Applicable</label>
                  <div style="margin-top: 12px;">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline13" name="isLateFeeApplicable" class="custom-control-input"
                             [(ngModel)]="visitorCategoryModel.isLateFeeApplicable"
                             #isLateFeeApplicable="ngModel"
                             [value]="true">
                      <label class="custom-control-label" for="customRadioInline13">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline22" name="isLateFeeApplicable" class="custom-control-input"
                             [(ngModel)]="visitorCategoryModel.isLateFeeApplicable"
                             #isLateFeeApplicable="ngModel"
                             [value]="false">
                      <label class="custom-control-label" for="customRadioInline22">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">

                <button class="btn btn-primary float-right ml-2" (click)="GoToVisitorcategoryPage()" type="button">Cancel</button>
                <button class="btn btn-primary float-right" type="submit">Save</button>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

