export class UserModel {
  public userId: number;
  public firstName: string; 
  public lastName: string;
  public email: string;
  public mobileNo: string;
  public roleId: number=0;
  public isActive: boolean = true; 
  public userName: string;
  public passKey: string;
  public loginUserId: number;
  public adminUserId: number;
}

export class UserViewModel {
  public userId: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public mobileNo: string; 
  public placeName: string;
  public roleName: string;
  public isActive: boolean;
  public userName: string;
  public isShowDelete: boolean; 
  public createdBy: string;
  public updatedBy: string;
  public createdDate: Date; 
  public updatedDate: Date; 
}
export class UserPlaceMapping {
  public companyId: number;
  public companyName: string;
  public userId: number;
  public isSelected: boolean=false;
  public isNotAvilable: boolean = false;
}
