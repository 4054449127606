export class ReportParam {
  public loginUserId: number = 0;
  public startDate: string;
  public endDate: string;
  public companyId: number = 0;
  public monthId: number;
  public selectedType: number;
}

export class TicketReport {
  public ticketId: number;
  public ticketNo: string;
  public companyName: string;
  public visitorName: string;
  public visitorMobileNo: string;
  public visitorAddress: string;
  public bookingDate: Date;
  public bookingTime: Date;
  public totalVisitor: number;
  public totalEquipment: number;
  public totalFinalAmount: number;
  public createdDate: Date;
  public firstName: string;
  public lastName: string;
  public isTicketClosed: boolean;
  public ticketBuyFrom: number;
}
