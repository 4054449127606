import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { authenticationService } from './authenticationService.service';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  showLoader: boolean = false;
  loginForm: FormGroup;
  submitted = false;
  error: string = '';
  errorMessage: string = '';
  screenStep: string = '';
  userName: string = '';
  successMessage: string = "";

  constructor(@Inject(DOCUMENT) private document: Document
    , private router: Router
    , private formBuilder: FormBuilder
    , private authenticationService: authenticationService,private http: HttpClient) { }

  ngOnInit(): void {
    this.screenStep = "login";
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      passkey: ['', Validators.required],
    });
    //this.PaymentSuccessful();
  }
  //PaymentSuccessful() {
  //  console.log("11");
  //  this.authenticationService.PaymentSuccessful().subscribe(m => {
  //    console.log(m)
  //  })
  //}
  Navigate(e) {
    this.router.navigate(["dashboard"]);
    //setTimeout(() => {
    // this.document.location.reload(); 
    //},
    //  100);
    
  }
 

  get f() { return this.loginForm.controls; }

  onSubmit() {

    this.successMessage = "";
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.showLoader = true;
    console.log(this.f.passkey.value);
    this.authenticationService.login(this.f.username.value, this.f.passkey.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.statusCode == 200) {
            console.log(data);
            this.router.navigate(["dashboard"]);
            this.showLoader = false;
          }
          else {
            this.showLoader = false;
            this.errorMessage = data.result;
            console.log(data);
          }
        },
        error => {
          try {
            this.error = error.error.message;
            this.showLoader = false;
          }
          catch{

          }

        });
  }

  ScreenEvent(step) {
    this.successMessage = "";
    this.errorMessage = "";
    if (step == "Forgot") {
      this.screenStep = "Forgot";
    }
    else if (step == "GoToLogin") {
      this.screenStep = "login";
    }
    else if (step == "login") {

    }
  }

  ForgotPassword() {
    this.showLoader = true;
    this.errorMessage = ""; this.successMessage = "";
    if (this.userName) {
      this.authenticationService.OnForgotPasswordSubmit(this.userName).subscribe(x => {
        if (x.statusCode == 200) {
          this.successMessage = "One time password has been sent successfully, Check your mail or sms.";
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
          this.errorMessage = x.result;
        }
      },
        error => {
          try {
            this.error = error.error.message;
            this.showLoader = false;
          }
          catch{

          }

        })
    }
  }
   
}
