import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { VisitorCategoryModel,VisitorCategoryTypeModel } from '../../../model/VisitorCategory';
import { VisitorCetgoryService } from '../../Service/VisitorCategoryService';
import { authenticationService } from '../../../login/authenticationService.service';

@Component({
  selector: 'app-create-visitorcategory',
  templateUrl: './create-visitorcategory.component.html',
  styleUrls: ['./create-visitorcategory.component.css']
})
export class CreateVisitorcategoryComponent implements OnInit {
  showLoader: boolean = false;
  visitorCategoryModel = new VisitorCategoryModel();
  visitorCategoryTypeModel = new Array<VisitorCategoryTypeModel>();
  constructor(private messageService: MessageService
    , private router: Router
    , private route: ActivatedRoute
    , private _visitorCetgoryService: VisitorCetgoryService
    , private _authenticationService: authenticationService) { }

  ngOnInit(): void {
    this.visitorCategoryModel = new VisitorCategoryModel();
    this.visitorCategoryTypeModel = new Array<VisitorCategoryTypeModel>();
    document.body.className = "sidebar-icon-only";
    this.getAllVisitorCategoryType();
    var id = this.route.snapshot.params['id'];
    if (id) {
      this.getSingelVisitorCategory(id);
    }
  }

  GoToVisitorcategoryPage() {
    this.router.navigate(['visitorcategory']);
  }

  getAllVisitorCategoryType() {
    this.showLoader = true;
    this._visitorCetgoryService.getAllVisitorCategoryType().subscribe(m => {
      if (m.statusCode == 200) {
        this.visitorCategoryTypeModel = m.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Visitor Type', detail: 'Get All Visitor Type Successfully ' });
        })
      }
    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }

  onSubmit() {
    this.showLoader = true;
    this.visitorCategoryModel.loginUserId = this._authenticationService.userId;
    this._visitorCetgoryService.saveUpdateVisitorCategory(this.visitorCategoryModel).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Visitor Type', detail: m.result });
          this.visitorCategoryModel = new VisitorCategoryModel();
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: m.result });
        })
      }
      
    }, err => {
        this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  getSingelVisitorCategory(visitorCategoryId: number) {
    this.showLoader = true;
    this.visitorCategoryModel = new VisitorCategoryModel();
    this._visitorCetgoryService.getSingelVisitorCategory(visitorCategoryId).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
        this.visitorCategoryModel = m.result;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

}
