<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper ">
        <div class="card mb-1 border-primary-top" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Home </span> ><span>Ticket</span></h4>
            </div>
            <div class="float-lg-right" style="padding: 15px;" *ngIf="dropdowncompanyId>0">
              <div class="search">
                <input type="text" placeholder="Ticket No.." style="text-transform: uppercase;" name="search2" [(ngModel)]="searchTicketNo" (keyup.enter)="SearchTicketNo();" class="ticket-input">
              </div>
            </div>
            <div class="float-lg-right" style="padding: 15px;" *ngIf="this._visitorTicket.visitorsTicketDetails.ticketId>0">
              <div *ngIf="this._visitorTicket.visitorsTicketDetails.isTicketClosed">
                <button pButton pRipple type="button" label="Status:Close" pTooltip="Ticket Status" class="p-button-secondary"></button>
              </div>
              <div *ngIf="!this._visitorTicket.visitorsTicketDetails.isTicketClosed">
                <button pButton pRipple type="button" label="Status:Active" pTooltip="Ticket Status" class="p-button-success"></button>
              </div>
            </div>
            <div class="float-lg-right" style="padding: 15px;" *ngIf="!_visitorTicket.visitorsTicketDetails.isTicketClosed && _visitorTicket.visitorsTicketDetails.ticketId>0">
              <div>
                <button pButton pRipple type="button" label="Invoice" pTooltip="Generate Invoice" (click)="GenerateInvoice(this._visitorTicket.visitorsTicketDetails.ticketId)" class="p-button-secondary"></button>
              </div>
            </div>
            <!--<div class="float-lg-right" style="padding: 15px;" *ngIf="_visitorTicket.visitorsTicketDetails.ticketId>0">
              <div>
                <button pButton pRipple type="button" label="Delete Ticket" pTooltip="Send request for delete ticket" class="p-button-danger"></button>
              </div>
            </div>-->
            <div class="float-lg-right" style="padding: 15px;" *ngIf="_visitorTicket.visitorsTicketDetails.ticketId>0">
              <div>
                <button pButton pRipple type="button" label="Send SMS" (click)="SendSMS()" pTooltip="Send booked ticket" class="p-button-danger"></button>
              </div>
            </div>
          </div>
        </div>

        <p-confirmDialog [style]="{width: '40vw'}" [baseZIndex]="10000"></p-confirmDialog>
        <div class="card cardheight">
          <div class="card-body main-content border-primary-top">
            <div class="row" *ngIf="dropdowncompanyId>0">
              <div class="form-group col-2 ">
                <input type="text" name="companyName" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.visitorName" placeholder="Visitor Name" pTooltip="Enter Visitor Name">
              </div>
              <div class="form-group col-2 ticket-label">
                <input type="text" name="companyName" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.visitorMobileNo" placeholder="Visitor Mobile No" pTooltip="Enter Visitor Mobile No">
              </div>
              <div class="form-group col-2 ticket-label">
                <input type="text" name="companyName" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" class="form-control ticket-input" [(ngModel)]="_visitorTicket.visitorsTicketDetails.visitorAddress" placeholder="Visitor Address" pTooltip="Enter Visitor Address">
              </div>
              <div class="form-group col-1 ticket-label">
                <p-calendar [readonlyInput]="true" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" placeholder="Booking Date" [(ngModel)]="bookingDate" dateFormat="dd-mm-yy" yearRange="2021:2022" (onSelect)="setDate($event)">
                </p-calendar>
              </div>
              <div class="form-group col-1 ticket-label">
                <p-calendar showTime="true" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" [timeOnly]="true" placeholder="Time" [(ngModel)]="bookingTime" (onSelect)="setTime($event)" inputId="timeonly" hourFormat="12"></p-calendar>
              </div>
              <div class="form-group col-2 ticket-label">
                <select name="PlaceId" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" class="form-control ticket-input-dropdown" [(ngModel)]="dropdowncompanyId" (change)="PlaceChangeEvent()">
                  <option value="0">Select Place</option>
                  <option *ngFor="let obj of _companyViewModel" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                </select>
              </div>
            </div>
            <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;" *ngIf="dropdowncompanyId>0">
              <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                <thead>
                  <tr>
                    <th rowspan="2">S.No</th>
                    <th rowspan="2">Visitor Type</th>
                    <th [attr.colspan]="numberOfColumnSpan" style="text-align:center;">
                      Visitor Charges Details
                    </th>

                    <th rowspan="2"># of Visitor</th>
                    <th rowspan="2">Total Amount</th>
                  </tr>
                  <tr>
                    <th>Fee</th>
                    <th>Hourly</th>
                    <th *ngIf="this.isHourly">Hours (in Minutes)</th>
                    <th *ngIf="this.isLateCharges">Late Charges</th>
                    <th *ngIf="this.isLateCharges">Time (in Minutes)</th>
                    <th *ngIf="this.isLateCharges">Late Charges(Amt)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of _visitorTicket.visitorCategoryTypeDetailCollection; index as i">
                    <td>{{i+1}}</td>
                    <td>{{obj.visitorCategory}}</td>
                    <td>&#x20b9; {{obj.visitorFee}}.00</td>
                    <td>
                      <span *ngIf="obj.hourly">
                        Yes
                      </span>
                      <span *ngIf="!obj.hourly">
                        No
                      </span>
                    </td>
                    <td *ngIf="obj.hourly"><div style="width:70px;float:left">
  <input type="number" [disabled]="obj.hourly" class="form-control ticket-input" [(ngModel)]="obj.hoursInMint" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" />
 
</div>
                                           <div style="float:left;margin-top:9px;margin-left:2px;">
                                             {{obj.hoursInMint/60 |number:'0.2-3' }} hr
                                           </div>
                    </td>
                    <td *ngIf="!obj.hourly && isHourly">
                    </td>
                    <td *ngIf="obj.lateFee">
                      <span *ngIf="obj.lateFee">
                        Yes
                      </span>
                      <span *ngIf="!obj.lateFee">
                        No
                      </span>
                    </td>
                    <td *ngIf="!obj.lateFee && isLateCharges">
                    </td>
                    <td *ngIf="obj.lateFee">
                      <input type="number" [disabled]="obj.lateFee" class="form-control ticket-input" [(ngModel)]="obj.lateFeeInMint" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" />
                    </td>
                    <td *ngIf="!obj.lateFee && isLateCharges">
                    </td>
                    <td *ngIf="obj.lateFee">
                      <input type="number" [disabled]="obj.lateFee" class="form-control ticket-input" [(ngModel)]="obj.visitorLateFee" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" />
                    </td>
                    <td *ngIf="!obj.lateFee && isLateCharges">
                    </td>
                    <td>
                      <input type="number" class="form-control ticket-input" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" [(ngModel)]="obj.numberofVisitor" (keyup)="CalculateVisitorAmount()" (blur)="CalculateVisitorAmount()" numbersOnly="false" pattern="[0-9]*"
                             (keypress)="numberOnly($event);" pTooltip="# of Visitor" style="width: 60px;" maxlength="4" />
                    </td>
                    <td>&#x20b9;  {{obj.totalAmount}}</td>
                  </tr>
                  <tr>
                    <td [attr.colspan]="numberOfColumnSpan+2" style="text-align:right;"><b>Total</b></td>
                    <td>
                      {{_visitorTicket.ticketAmountDetails.totalVisitor}}
                    </td>
                    <td>
                      &#x20b9;  {{_visitorTicket.ticketAmountDetails.totalVisitorAmount}}
                    </td>
                  </tr>
                  <tr>
                    <td [attr.colspan]="numberOfColumnSpan+2" style="text-align:right;"><b>Late Payment</b></td>
                    <td>
                    </td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td [attr.colspan]="numberOfColumnSpan+2" style="text-align:right;"><b>Grand Total</b></td>
                    <td>
                    </td>
                    <td>
                      &#x20b9;  {{_visitorTicket.ticketAmountDetails.totalFinalAmount}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <div class="float-md-right">
                        <button type="button" class="float-right m-2 btn btn-primary" pButton (click)="SaveTicket()" [disabled]="this._visitorTicket.visitorsTicketDetails.ticketId" label="Submit"></button>
                        <button type="button" class="float-right m-2 btn btn-primary" pButton (click)="Clear()" label="Clear"></button>
                        <!--<button type="button" class="float-right m-2" pButton (click)="printPirview()"  label="Print"></button>-->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>


    <p-dialog [contentStyle]="{'height': '500px','width':'950px' }" [(visible)]="TicketConfmBeforeSave" header="CAUTION"
              [closable]="false" [modal]="true">
      <div style="margin-top: 10px;">
        <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;">
          <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
            <tbody>
              <tr>
                <td>
                  Name
                </td>
                <td>
                  {{_visitorTicket.visitorsTicketDetails.visitorName}}
                </td>
                <td colspan="2">
                  Mobile No
                </td>
                <td colspan="2">
                  {{_visitorTicket.visitorsTicketDetails.visitorMobileNo}}
                </td>
              </tr>
              <tr>
                <td>
                  Address
                </td>
                <td>
                  {{_visitorTicket.visitorsTicketDetails.visitorAddress}}
                </td>
                <td colspan="2">
                  Date/Time
                </td>
                <td colspan="2">
                  {{_visitorTicket.visitorsTicketDetails.bookingDate}} {{_visitorTicket.visitorsTicketDetails.bookingTime}}
                </td>
              </tr>
              <tr>
                <td>
                  No of Visitor
                </td>
                <td>
                  {{totalVisitor}}
                </td>
                <td colspan="2">
                  No of Equipment
                </td>
                <td colspan="2">
                  {{totalEquipment}}
                </td>
              </tr>
              <tr *ngIf="TempvisitorCategoryTypeDetailCollection.length>0">
                <th colspan="5"><div style="text-align:center;">Equipment Details</div></th>
              </tr>
              <tr *ngIf="TempvisitorCategoryTypeDetailCollection.length>0">
                <th>
                  S.No
                </th>
                <th>
                  Equipment Type
                </th>
                <th>No of Equipment</th>
                <th>
                  Tariff
                </th>
                <th>
                  Amount
                </th>
              </tr>
              <tr *ngFor="let obj of TempvisitorCategoryTypeDetailCollection; index as i">
                <td>{{i+1}}</td>
                <td>{{obj.visitorCategory}}</td>
                <td>{{obj.numberofVisitor}}</td>
                <td>&#x20b9; {{obj.visitorFee}} (per/{{obj.hoursInMint/60}} HR)</td>
                <td>&#x20b9; {{obj.totalAmount}}</td>
              </tr>
              <tr *ngIf="totalVisitor>0">
                <th colspan="5"><div style="text-align:center;">Visitor Details</div></th>
              </tr>
              <tr *ngIf="totalVisitor>0">
                <th>S.No</th>
                <th>Visitor Type</th>
                <th>No of Visitor</th>
                <th>Tariff</th>
                <th>Amount</th>
              </tr>
              <tr *ngFor="let obj of TempvisitorCategoryTypeDetailCollectionNoneHourly; index as i">
                <td>{{i+1}}</td>
                <td>{{obj.visitorCategory}}</td>
                <td>{{obj.numberofVisitor}}</td>
                <td>&#x20b9; {{obj.visitorFee}} </td>
                <td>&#x20b9; {{obj.totalAmount}}</td>
              </tr>
              <tr>
                <td colspan="4"><div style="text-align:right;"><b>Total</b></div> </td>
                <td> &#x20b9;  {{_visitorTicket.ticketAmountDetails.totalFinalAmount}}</td>
              </tr>
              <tr>
                <td colspan="5">
                  <div style="font-size: 18px; color: red;">
                    Be alert! You are about to book & print this ticket. After that you can't edit or delete.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p-footer>
        <button type="button" pButton label="Save/Print" (click)="SaveTicketPrint()"></button>
        <button type="button" pButton (click)="TicketConfmBeforeSave=false" label="Close"></button>
      </p-footer>
    </p-dialog>
    <p-dialog [contentStyle]="{'height': '500px','width':'950px' }" [(visible)]="TicketInvoice" header="INVOICE"
              [closable]="false" [modal]="true">
      <div style="margin-top: 10px;">
        <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;" *ngIf="_ticketInvoice.ticketAmountDetails.totalVisitor">
          <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
            
              <tr>
                <td>
                  Name
                </td>
                <td>
                  {{_ticketInvoice.visitorsTicketDetails.visitorName}}
                </td>
                <td colspan="2">
                  Mobile No
                </td>
                <td colspan="2">
                  {{_ticketInvoice.visitorsTicketDetails.visitorMobileNo}}
                </td>
              </tr>
              <tr>
                <td>
                  Address
                </td>
                <td>
                  {{_ticketInvoice.visitorsTicketDetails.visitorAddress}}
                </td>
                <td colspan="2">
                  Date/Time
                </td>
                <td colspan="2">
                  {{_ticketInvoice.visitorsTicketDetails.bookingDate | date:'dd-MM-yyyy'}} @{{_ticketInvoice.visitorsTicketDetails.bookingTime| date:'hh:mm:ss a'}}
                </td>
              </tr>
              <tr>
                <td>
                  No of Visitor
                </td>
                <td>
                  {{_ticketInvoice.ticketAmountDetails.totalVisitor}}
                </td>
                <td colspan="2">
                  No of Equipment
                </td>
                <td colspan="2">
                  {{_ticketInvoice.ticketAmountDetails.totalEquipment}}
                </td>
              </tr>
              </table>
          <table  id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
              <tr *ngIf="_tempInvoiceHourlyCategory.length>0">
                <th colspan="10"><div style="text-align:center;">Equipment Details</div></th>
              </tr>
              <tr *ngIf="_tempInvoiceHourlyCategory.length>0">
                <th>
                  S.No
                </th>
                <th>
                  Equipment Type
                </th>
                <th>No of Equipment</th>
                <th>
                  Start Time
                </th>
                <th>
                  End Time
                  </th>
                <th>
                  Total Time
                </th>
                <th>
                  Total Late(Hr)
                </th>
                <th>
                   Late Charges
                </th>
                <th>
                  Tariff
                </th>
                <th>
                  Amount
                </th>
              </tr>
              <tr *ngFor="let obj of _tempInvoiceHourlyCategory; index as i">
                <td>{{i+1}}</td>
                <td>{{obj.visitorCategory}}</td>
                <td>{{obj.numberofVisitor}}</td>
                <td>{{obj.startTime| date:'hh:mm:ss a'}}</td>
                <td>{{obj.endTime| date:'hh:mm:ss a'}}</td>
                <td>{{obj.totalMinutes}}</td>
                <td>{{obj.totalLateMinutes}}</td>
                <td>{{obj.totalLateAmount}}</td>
                <td>&#x20b9; {{obj.visitorFee}} (per/{{obj.hoursInMint/60}} HR)</td>
                <td>&#x20b9; {{obj.totalAmount}}</td>
              </tr>
            </table>
          <table>
            <tr>
              <th colspan="5"><div style="text-align:center;">Visitor Details</div></th>
            </tr>
            <tr *ngIf="totalVisitor>0">
              <th>S.No</th>
              <th>Visitor Type</th>
              <th>No of Visitor</th>
              <th>Tariff</th>
              <th>Amount</th>
            </tr>
            <tr *ngFor="let obj of _tempInvoiceNoneHourlyCategory; index as i">
              <td>{{i+1}}</td>
              <td>{{obj.visitorCategory}}</td>
              <td>{{obj.numberofVisitor}}</td>
              <td>&#x20b9; {{obj.visitorFee}} </td>
              <td>&#x20b9; {{obj.totalAmount}}</td>
            </tr>
            <tr>
              <td colspan="4"><div style="text-align:right;"><b>Total Late Charges</b></div> </td>
              <td> &#x20b9;  {{_ticketInvoice.ticketAmountDetails.totalLateCharges}}</td>
            </tr>
            <tr>
              <td colspan="4"><div style="text-align:right;"><b>Recivied Amount</b></div> </td>
              <td> &#x20b9;  {{_ticketInvoice.ticketAmountDetails.totalFinalAmount}}</td>
            </tr>
            <tr>
              <td colspan="4"><div style="text-align:right;"><b>Total Billed Amount</b></div> </td>
              <td> &#x20b9;  {{_ticketInvoice.ticketAmountDetails.totalFinalAmount+_ticketInvoice.ticketAmountDetails.totalLateCharges}}</td>
            </tr>
            <tr>
              <td colspan="4"><div style="text-align:right;"><b>Balance Amount</b></div> </td>
              <td>
                &#x20b9;  {{_ticketInvoice.ticketAmountDetails.totalLateCharges}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <p-footer>
        <button type="button" pButton (click)="CloseInvoice()" label="Close"></button>
      </p-footer>
    </p-dialog>
  </div>
</div>

