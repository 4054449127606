<div class="loading" *ngIf="showLoader">Loading&#8230;</div>
<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth px-0" style="background-color: #ffffff">
      <div class="row w-100 mx-0">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left py-5 px-4 px-sm-5">
            <div class="brand-logo" style="text-align: center; margin-top: -40px;margin-bottom: -1px;">
              <img src="assets/images/logo.png" style="width:125px;" alt="logo">
            </div>
            <div style="text-align:center;margin-bottom: 25px;">
              <h3>
                <label><p style="font-size: 20px;"></p></label>
              </h3>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="pt-3" style="margin-top: -30px;" *ngIf="screenStep=='login'">
              <div class="form-group">
                <input type="text" formControlName="username" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
              </div>
              <div class="form-group">
                <input type="password" formControlName="passkey" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password"
                       [ngClass]="{ 'is-invalid': submitted && f.passkey.invalid }">
                <label style="color:red;" *ngIf="errorMessage">{{errorMessage}}</label>
              </div>
              <div class="mt-3">
                <button class="btn btn-block btn-primary-login bg-orange btn-lg font-weight-medium auth-form-btn" style="background-color: #416a41!important;">SIGN IN</button>
              </div>

              <div class="my-2 d-flex justify-content-between align-items-center Save Wildlife">
                <div class="form-check">

                </div>
                <a class="auth-link text-black" style="cursor:pointer;" (click)="ScreenEvent('Forgot')">Forgot password?</a>
              </div>
            </form>

            <div *ngIf="screenStep=='Forgot' && successMessage==''">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" [(ngModel)]="userName" placeholder="Username">
              </div>
              <div class="mt-3">
                <button class="btn btn-block btn-primary bg-orange btn-lg font-weight-medium auth-form-btn" (click)="ForgotPassword()">Submit</button>
              </div>
              <label style="color:red;" *ngIf="errorMessage && successMessage==''">{{errorMessage}}</label>
              <div class="my-2 d-flex justify-content-between align-items-center Save Wildlife">
                <div class="form-check">

                </div>
                <a class="auth-link text-black" style="cursor:pointer;" (click)="ScreenEvent('GoToLogin')">Login Page</a>
              </div>
            </div>
            <div *ngIf="successMessage">
              <label style="color:green;">{{successMessage}}</label> <a style="cursor:pointer" (click)="ScreenEvent('GoToLogin')">Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div id="wraper">-->
  <!--full body wraper-->
  <!--<div class=" backgroun-iamge">-->
    <!--tiger side-div struture-->
    <!--<div class="left-side">
      <div class="content-box">-->
        <!--this content use only desktop 990px screen-->
        <!--<h1>
          Welcome to <span>
            Wildlife
            Division
          </span> Hamirpur
        </h1>
        <p>Humans have historically tended to separate civilization from wildlife in a number of ways, including the legal, social, and moral senses</p>
      </div>

      <div class="mobile-use-class">-->
        <!--this content used in after 990px resulation-->
        <!--<img src="assets/images/ssss.png" alt="image">
        <div class="content-box mobilie-use">
          <h1>
            Welcome to <span>
              Wildlife
              Division
            </span> Hamirpur
          </h1>
          <p>Humans have historically tended to separate civilization from wildlife in a number of ways, including the legal, social, and moral senses</p>
        </div>
      </div>



    </div>
  </div>
  <div class="right-side-conatct-form">
    <div class="logo-image"><figure><img src="assets/images/hamirpur 2.svg" alt="image"></figure></div>
    <h2>log In to your account 👋 </h2>
    <from  [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="field">
        <label for="">Name</label>
        <input type="text" placeholder="First Name">
      </div>
      <div class="field">
        <label for="">password</label>
        <input type="password" placeholder="password">
      </div>
      <div class="forgert-paasword">
        <div class="checkbox">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
          <label for="vehicle3"> I have a boat</label><br><br>
        </div>
        <div class="forgotpaswod">
          <a href="#">Forgot Password?</a>
        </div>
      </div>
      <button type="submit">Log In</button>

    </from> 
    <h5>or</h5>
    <div class="socila-link">
      <a href=""><img style="width: 220px;" src="assets/images/Group 107.png" alt="image"></a>
      <a href=""><img style="width: 220px;" src="assets/images/Group 106.png" alt="image"></a>
    </div>
    <p>Don’t have an account? <a href="">Sign up</a></p>
  </div>
</div>-->


<style>
  .btn-primary-login {
    color: #fff;
    background-color: rgb(229 90 47);
    border-color: rgb(229 90 47);
  }

    .btn-primary-login:not(:disabled):not(.disabled):active, .btn-primary-login:not(:disabled):not(.disabled).active, .show > .btn-primary-login.dropdown-toggle {
      color: #fff;
      background-color: #cfa21d;
      border-color: #cfa21d;
    }


  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }

  #wraper {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .backgroun-iamge {
    width: 45%;
  }

  .content-box {
    width: 100%;
    max-width: 400px;
    padding-left: 98px;
    padding-top: 44px;
  }

  .left-side {
    background-image: url("assets/images/Group\ 111.png");
    width: 100%;
    height: 804px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom left;
  }

  .content-box h1 {
    font-size: 36px;
    line-height: 54px;
    max-width: 381px;
    width: 100%;
    color: #fff;
    font-weight: 400;
  }

    .content-box h1 span {
      font-weight: 800;
    }

  .content-box p {
    color: #fff;
    font-size: 17px;
    line-height: 28px;
  }

  .mobile-use-class {
    display: none;
  }

  .right-side-conatct-form {
    width: 50%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
  }

    .right-side-conatct-form figure {
      text-align: center;
    }

    .right-side-conatct-form h2 {
      font-size: 32px;
      font-weight: 600;
      color: #000;
      text-transform: capitalize;
      text-align: center;
    }

    .right-side-conatct-form from {
      width: 100%;
    }

      .right-side-conatct-form from .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
      }

      .right-side-conatct-form from .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
      }

        .right-side-conatct-form from .field input {
          background: #FFFFFF;
          box-shadow: -2px 13px 18px rgba(146, 154, 185, 0.11);
          border-radius: 40px;
          height: 51px;
          border: none;
          border: solid 4px rgba(0,0,0, 0.08);
          padding: 1px 19px;
        }

      .right-side-conatct-form from .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
      }

        .right-side-conatct-form from .field label {
          font-size: 16px;
          padding-left: 16px;
          padding-bottom: 9px;
        }

  .forgert-paasword {
    display: flex;
    justify-content: space-between;
  }

  .checkbox {
    padding-left: 15px;
  }

  .forgert-paasword a {
    color: #029163;
    text-decoration: navajowhite;
    font-weight: 600;
  }

    .forgert-paasword a:hover {
      text-decoration: underline;
    }

  .right-side-conatct-form from button {
    background: #029163;
    width: 100%;
    height: 63px;
    color: #fff;
    font-size: 21px;
    border-radius: 50px;
    margin-top: 23px;
    font-weight: 500;
  }

  .right-side-conatct-form h5 {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .right-side-conatct-form p {
    text-align: center;
    font-size: 20px;
  }

    .right-side-conatct-form p a {
      color: #029163;
    }





  /*******************************meedia qyery***************************/


  @media(min-width:1600px) {
    .left-side {
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom left;
    }
  }

  @media(max-width:1230px) {
    .content-box {
      padding-left: 10px;
      padding-top: 95px;
    }

      .content-box h1 {
        font-size: 24px;
        line-height: 30px;
      }

    .backgroun-iamge {
      width: 69%;
    }
  }

  @media(max-width:990px) {

    #wraper {
      flex-direction: column;
    }

    .backgroun-iamge {
      width: 100%;
    }

    .right-side-conatct-form {
      width: 100%;
    }

    .right-side-conatct-form {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      overflow: hidden;
    }

    .right-side-conatct-form {
      margin-top: 40px;
    }

    .content-box {
      display: none;
    }

    .mobile-use-class {
      display: block;
    }

    .left-side {
      background-image: none;
      width: 100%;
      height: auto;
    }

    .mobile-use-class img {
      width: auto;
      height: auto;
      object-fit: contain;
    }

    .mobile-use-class .content-box.mobilie-use {
      display: block;
    }

      .mobile-use-class .content-box.mobilie-use h1, .mobile-use-class .content-box.mobilie-use p {
        color: #000;
      }

    .content-box {
      padding-left: 10px;
      padding-top: 26px;
    }

    .right-side-conatct-form {
      width: 85%;
      margin: 0 auto;
    }
  }


  @media(max-width:750px) {
    .right-side-conatct-form h2 {
      font-size: 20px;
    }

    .right-side-conatct-form from .field label {
      font-size: 16px;
      padding-left: 16px;
      padding-bottom: 5px;
      padding-top: 16px;
    }

    .right-side-conatct-form from .field input {
      height: 43px;
    }

    .right-side-conatct-form from .field {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }

    .forgert-paasword {
      margin-top: 30px;
    }

    .right-side-conatct-form from button {
      height: 50px;
      font-size: 16px;
      font-weight: 500;
    }

    .socila-link a img {
      width: 100%;
      overflow: hidden;
      max-width: 200px;
      margin: 0 auto;
    }

    .right-side-conatct-form p {
      text-align: center;
      font-size: 16px;
    }

    body {
      overflow-x: hidden;
    }
  }
</style>
