import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CompanyService } from '../../Service/CompanyService';
import { MessageService } from 'primeng/api';
import { TicketService } from '../../Service/TicketService';
import { DatePipe } from '@angular/common';
import { PrintSettingService } from '../../Service/PrintSettingService';
import { PlaceMapping } from '../../../model/place-mapping';
import { VisitorTickets, VisitorCategoryTypeDetail, TicketAmountDetails, VisitorsTicketDetails } from '../../../model/Tickets';
import { CompanyModelView } from '../../../model/comapny';
import { PrintSetting, PrintHeader, Signature, Notes } from '../../../model/Setting';
import { OnlineTicketService } from '../../Service/onlineticketService';
import { Router } from '@angular/router';


@Component({
  selector: 'app-onlineticket',
  templateUrl: './onlineticket.component.html',
  styleUrls: ['./onlineticket.component.css']
})
export class OnlineticketComponent implements OnInit {
  @ViewChild('form1') form: ElementRef;
  @ViewChild('phone') phone: ElementRef
  CheckoutUrl: string = "";
  EncryptionRequest: string = "";
  AccessCode: string = "";

  showLoader: boolean = false;
  _placeMapping: Array<PlaceMapping>;
  _visitorTicket: VisitorTickets;
  _companyViewModel: Array<CompanyModelView>;
  TicketInvoice: boolean = false;
  _ticketInvoice: VisitorTickets;

  isHourly: boolean = false;
  isLateCharges: boolean = false;
  numberOfColumnSpan: number = 8;
  dropdowncompanyId: number;
  invalidDates: Array<Date>

  totalVisitor: number = 0;
  totalEquipment: number = 0;
  public TicketConfmBeforeSave: boolean;
  TempvisitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
  TempvisitorCategoryTypeDetailCollectionNoneHourly = new Array<VisitorCategoryTypeDetail>();

  _tempInvoiceHourlyCategory = new Array<VisitorCategoryTypeDetail>();
  _tempInvoiceNoneHourlyCategory = new Array<VisitorCategoryTypeDetail>();

  bookingDate: Date;
  bookingTime: Date;
  searchTicketNo: string;
  _printSetting: PrintSetting;

  accessCode: any;
  minimumDate = new Date();
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public captchaValue:string = "";

  constructor(private _companyService: CompanyService
    , private messageService: MessageService
    , private _visitorTicketService: TicketService, public datepipe: DatePipe
    , private printSettingService: PrintSettingService, private onlineTicketService: OnlineTicketService, private router: Router) { }

  ngOnInit(): void {
    this.captchaValue = "";
    this.accessCode = '';
    document.body.className = "sidebar-icon-only";
    this.dropdowncompanyId = 0;

    this.bookingDate = new Date();
    this.bookingTime = new Date();
    this._visitorTicket = new VisitorTickets();
    this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();

    this._ticketInvoice = new VisitorTickets();
    this._ticketInvoice.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._ticketInvoice.ticketAmountDetails = new TicketAmountDetails();
    this._ticketInvoice.visitorsTicketDetails = new VisitorsTicketDetails();

    this.TempvisitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this.TempvisitorCategoryTypeDetailCollectionNoneHourly = new Array<VisitorCategoryTypeDetail>();

    this._tempInvoiceHourlyCategory = new Array<VisitorCategoryTypeDetail>();
    this._tempInvoiceNoneHourlyCategory = new Array<VisitorCategoryTypeDetail>();

    document.body.className = "sidebar-icon-only";

    this._printSetting = new PrintSetting();
    this._printSetting.printHeader = new Array<PrintHeader>();
    this._printSetting.signatureHeaderName = new Array<Signature>();
    this._printSetting.notes = new Array<Notes>();

    this.GetUserPlaceMapping();
  }
  GetUserPlaceMapping() {

    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompanyForOnline().subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
       
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Place not map with you. Please contact your Admin.' });
        })
      }
    })
  }


  GetAllPlaceVisitorFee(companyId: number) {
    this.numberOfColumnSpan = 2;
    this._placeMapping = [];
    this.isHourly = false;
    this.isLateCharges = false;
    this.showLoader = true;
    let ticketNo: string = "-1";
    this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
    this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();

    this.bookingDate = new Date();
    this.bookingTime = new Date();
    this.captchaValue = "";
    this._visitorTicketService.VisitorTicket(companyId, -1, ticketNo).subscribe(m => {
      if (m.statusCode == 200) {
        console.log("result", m.result);
        this._visitorTicket.visitorCategoryTypeDetailCollection = m.result.visitorCategoryTypeDetailCollection;
        this._visitorTicket.ticketAmountDetails = m.result.ticketAmountDetails;
        this._visitorTicket.visitorsTicketDetails = m.result.visitorsTicketDetails;

        if (this._visitorTicket.ticketAmountDetails == null) {
          this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
        }
        if (this._visitorTicket.visitorsTicketDetails == null) {
          this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
        }
        console.log(this._visitorTicket);
        if (this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly === true).length > 0) {
          this.isHourly = true;
        }
        if (this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.lateFee === true).length > 0) {
          this.isLateCharges = true;
        }
        if (this.isHourly) {
          this.numberOfColumnSpan = this.numberOfColumnSpan + 1;
        }
        if (this.isLateCharges) {
          this.numberOfColumnSpan = this.numberOfColumnSpan + 3;
        }

        console.log(this._placeMapping);
        this.showLoader = false;
      }
      else {
        if (m.statusCode == 404) {
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message visitor type does not map with company. Please contact with Developer.' });
          })
        }
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  setDate(e) {
    this._visitorTicket.visitorsTicketDetails.bookingDate = this.convertDateToString(e);
  }

  setTime(e) {
    this._visitorTicket.visitorsTicketDetails.bookingTime = this.ConvertDateTimeToString(e);
  }
  convertDateToString(date: string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }

  ConvertDateTimeToString(date: string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy hh:mm:ss a');
  }

  Clear() {
    this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
  }
  PlaceChangeEvent() {
    if (this.dropdowncompanyId > 0) {
      this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
    }
  }
 
  CalculateVisitorAmount() {
    this._visitorTicket.visitorCategoryTypeDetailCollection.forEach(function (value) {
      value.totalAmount = (value.numberofVisitor * value.visitorFee);
    });
    this._visitorTicket.ticketAmountDetails.totalVisitor = this._visitorTicket.visitorCategoryTypeDetailCollection.reduce((subtotal, item) => subtotal + item.numberofVisitor, 0);
    this._visitorTicket.ticketAmountDetails.totalVisitorAmount = this._visitorTicket.visitorCategoryTypeDetailCollection.reduce((subtotal, item) => subtotal + item.totalAmount, 0);

    this._visitorTicket.ticketAmountDetails.totalFinalAmount = this._visitorTicket.ticketAmountDetails.totalVisitorAmount;
  }

   Payment() {
     this.onlineTicketService.payment().subscribe(m => {
      console.log(m)
      this.CheckoutUrl = m.result.checkoutUrl;
      this.EncryptionRequest = m.result.encryptionRequest;
      this.AccessCode = m.result.accessCode;
      setTimeout(() => {
        this.form.nativeElement.submit();
      }, 1000)

    })
  }

  onSubmit() {
    if (this.captchaValue) {
      this.TempvisitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
      this.TempvisitorCategoryTypeDetailCollectionNoneHourly = new Array<VisitorCategoryTypeDetail>();
      this.totalVisitor = 0;
      this.totalEquipment = 0;
      this._visitorTicket.visitorsTicketDetails.companyId = this.dropdowncompanyId;
      this._visitorTicket.visitorsTicketDetails.loginUserId = 0;
      this.totalEquipment = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => (x.visitorCategoryTypeId == 2)).reduce((subtotal, item) => subtotal + item.numberofVisitor, 0);
      if (this.totalEquipment > 0) {
        this.TempvisitorCategoryTypeDetailCollection = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly == true || x.visitorCategoryTypeId == 2 && x.numberofVisitor > 0);
      }

      this.totalVisitor = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => (x.visitorCategoryTypeId == 1)).reduce((subtotal, item) => subtotal + item.numberofVisitor, 0);

      if (this.totalVisitor > 0) {
        this.TempvisitorCategoryTypeDetailCollectionNoneHourly = this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => (x.hourly == false && x.visitorCategoryTypeId == 1) && x.numberofVisitor > 0);
      }

      if (!this._visitorTicket.visitorsTicketDetails.bookingDate) {
        this._visitorTicket.visitorsTicketDetails.bookingDate = this.convertDateToString(this.bookingDate.toLocaleDateString());
      }
      if (!this._visitorTicket.visitorsTicketDetails.bookingTime) {
        this._visitorTicket.visitorsTicketDetails.bookingTime = this.bookingDate.toLocaleTimeString();
      }
      else {
        this._visitorTicket.visitorsTicketDetails.bookingTime = this.bookingDate.toLocaleTimeString();
      }

      if (this.totalVisitor > 0 || this.totalEquipment > 0) {
        this.TicketConfmBeforeSave = true;
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Please enter visitor details.' });
        })
      }
    }
    else {
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Please Validate Captcha.' });
      })
    }
  }

  SaveTicketPrint() {
    this.showLoader = true;
    this.onlineTicketService.SaveUpdateTicket(this._visitorTicket).subscribe(m => {
      if (m.statusCode == 200) {
        this.CheckoutUrl = m.result.checkoutUrl;
        this.EncryptionRequest = m.result.encryptionRequest;
        this.AccessCode = m.result.accessCode;
        this.TicketConfmBeforeSave = false;
        setTimeout(() => {
          this.form.nativeElement.submit();
          this.showLoader = false;
        }, 1000)
       
        
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else if (event.target.value.length > 3) {
      return false;
    }
    return true;

  }

  Navigate() {
    this.router.navigateByUrl("/");
  }

  handleSuccess(e) {
    this.captchaValue = e;
  }
}
