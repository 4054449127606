import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RoleRoutingModule } from './role-routing.module';
import { RolesComponent } from './roles/roles.component';
import { SharedModule } from '../../shared/shared.module';
import { RoleCreateComponent } from './role-create/role-create.component';
import { CommonService } from '../Service/CommonService';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [RolesComponent, RoleCreateComponent],
  imports: [
    CommonModule
    ,RoleRoutingModule
    , SharedModule
    , FormsModule
    , ToastModule
    , BrowserAnimationsModule
  ],
  providers: [CommonService, MessageService]
})
export class RoleModule { }
