import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import {VisitorCategoryTypeModel,VisitorCategoryModel } from '../../model/VisitorCategory';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class VisitorCetgoryService {
  header: HttpHeaders;
  baseApiUrl: string;
  constructor(@Inject(BASE_API_URL) private readonly _baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });

    this.baseApiUrl = (_auth.username == environment.userName) ? environment.baseApiUrlV1 : environment.baseApiUrl;
  }


  getAllVisitorCategoryType() {
    const url = `${this._auth.baseURL}VisitorCategory/GetAllVisitorCategoryType`;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }),
        catchError(err => {
          return throwError(err);
        }))
  }

  getAllVisitorCategory() {
    const url = `${this._auth.baseURL}VisitorCategory/GetAllVisitorCategory`;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }),
        catchError(err => {
          return throwError(err);
        }))
  }

  saveUpdateVisitorCategory(visitorCategoryModel: VisitorCategoryModel) {
    const url = `${this._auth.baseURL}VisitorCategory/SaveUpdateVisitorCategory`;
    let headers = this.header;
    return this.http.post<any>(url, visitorCategoryModel, { headers })
      .pipe(
        map(x => {
          return x;
        }),
        catchError(err => {
          return throwError(err);
        }));
  }


  getSingelVisitorCategory(visitorCategoryId: number) {
    const url = `${this._auth.baseURL}VisitorCategory/GetSingelVisitorCategory/` + visitorCategoryId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }),
        catchError(err => {
          return throwError(err);
        }))
  }

  Delete(visitorCategoryId: number, LoginUserId: number) {
    const url = `${this._auth.baseURL}VisitorCategory/DeleteVisitorCategory/` + visitorCategoryId + '/' + LoginUserId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }),
        catchError(err => {
          return throwError(err);
        }))
  }
}
