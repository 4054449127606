import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import { VisitorTickets } from '../../model/Tickets';

@Injectable({
  providedIn: 'root'
})
export class OnlineTicketService {
  header: HttpHeaders;
  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });
  }

  payment() {
    const url = `${this._auth.baseURL}Authentication/Payment`;
    return this.http.post<any>(url, {})
      .pipe(map(user => {
        return user;
      }));
  }

  SaveUpdateTicket(_visitorTicket: VisitorTickets) {
    const url = `${this._auth.baseURL}OnlineTicket/SaveOnlineTicket`;
    let headers = this.header;
    return this.http.post<any>(url, _visitorTicket, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  GetOnlineTicket(order_id:number) {
    const url = `${this._auth.baseURL}OnlineTicket/GetOnlineTicket/` + order_id;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  //PaymentSuccessful() {
  //  const url = `${this.baseApiUrl}Authentication/PaymentSuccessful`;
  //  return this.http.post<any>(url, {})
  //    .pipe(map(user => {
  //      return user;
  //    }));
  //}

  //encryptdata(request) {
  //  let url = `${this.baseApiUrl}orders/encryptFormData`;
  //  let data = {
  //    request: request
  //  }
  //  return this.http.get(url, { params: data })
  //}
}
