<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" class="toastZindex"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2" style="height: 60px;">
          <div>
            <div class="float-lg-left" style="padding: 23px;">
              <h4 class="card-title"><span>Home </span> ><span>Settings</span></h4>
            </div>

          </div>
        </div>

        <div class="card cardheight">
          <div class="card-body main-content">
            <p-tabView styleClass="tabview-custom" (onChange)="handleChange($event)">
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="pi pi-money-bill"></i>
                  <span>Visitor Fee</span>
                </ng-template>
                <div class="row">
                  <div class="form-group col-4">
                    <label>Place Name</label>
                    <select name="PlaceId" class="form-control" [(ngModel)]="dropdowncompanyId" (change)="PlaceChangeEvent()">
                      <option value="0">Select Place</option>
                      <option *ngFor="let obj of _companyViewModel" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;" *ngIf="_placeMapping.length>0">
                    <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                      <thead>
                        <tr>
                          <th class="tabltth">Visitor Category</th>
                          <th>Visitor Fee</th>
                          <th>Hours in Minutes</th>
                          <th>Late Hours in Minutes</th>
                          <th>Late Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let obj of _placeMapping">
                          <td>
                            <div class="form-check">
                              {{obj.visitorCategory}}
                            </div>
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="obj.visitorFee" numbersOnly="false" pTooltip="Enter visitor fee" style="width: 60px;" (keypress)="onKeypressEvent($event)" />
                          </td>
                          <td>
                            <div>
                              <input type="number" [(ngModel)]="obj.hoursInMint" pTooltip="Enter hours in minutes" [disabled]="!obj.isHourly"
                                     (keypress)="onKeypressEvent($event)" numbersOnly="false" style="width: 60px;" maxlength="4" />
                            </div>
                          </td>
                          <td>
                            <div>
                              <input type="number" [(ngModel)]="obj.lateFeeInMint" [disabled]="!obj.isLateFeeApplicable"
                                     pTooltip="Enter late amount" (keypress)="onKeypressEvent($event)" numbersOnly="false" style="width: 60px;" maxlength="4" />
                            </div>
                          <td>
                            <div>
                              <input type="number" [(ngModel)]="obj.visitorLateFee" [disabled]="!obj.isLateFeeApplicable"
                                     pTooltip="Enter late amount" (keypress)="onKeypressEvent($event)" numbersOnly="false" style="width: 60px;" maxlength="4" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row" *ngIf="_placeMapping.length>0">
                  <div class="form-group col-4 float-md-right">
                    <button type="button" class="float-right m-2" pButton (click)="SaveUpdateVisitorFee()" label="Save"></button>
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Header II">
                <ng-template pTemplate="header">
                  <i class="pi pi-user"></i>
                  <span>Set Serial No</span>
                </ng-template>
                <div class="row">
                  <div class="order-report" style="padding-right: 0px;max-height: 865px; overflow-x: overlay;font-size:12px;" *ngIf="_sNoPlaceMapping.length>0">
                    <table id="table" cellspacing="0" cellpadding="0" class="scanList" ts-wrapper style="margin-top: 0px!important;">
                      <thead>
                        <tr>
                          <th class="tabltth">Place Name</th>
                          <th>Start With Alphabet</th>
                          <th>Start With S.No</th>
                          <th>Current S.No</th>
                          <th>Online Start With Alphabet</th>
                          <th>Online Start With S.No</th>
                          <th>Online Current S.No</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let obj of _sNoPlaceMapping">
                          <td>
                            <div class="form-check">
                              {{obj.companyName}}
                            </div>
                          </td>
                          <td>
                            <input type="text" [(ngModel)]="obj.startWithAlphabet" (ngModelChange)="obj.startWithAlphabet = $event.toUpperCase()" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" />
                          </td>
                          <td>
                            <div>
                              <input type="number" [(ngModel)]="obj.numericSNo" pTooltip="Number S.No Start From"
                                     (keypress)="onKeypressEvent($event)" numbersOnly="false" style="width: 60px;" maxlength="4" />
                            </div>
                          </td>
                          <td>
                            {{obj.currentSNo}}
                          </td>

                          <td>
                            <input type="text" [(ngModel)]="obj.onlineStartWithAlphabet" (ngModelChange)="obj.onlineStartWithAlphabet = $event.toUpperCase()" pTooltip="Alphabet S.No Start From" style="width: 60px;" maxlength="4" />
                          </td>
                          <td>
                            <div>
                              <input type="number" [(ngModel)]="obj.onlineNumericSNo" pTooltip="Number S.No Start From"
                                     (keypress)="onKeypressEvent($event)" numbersOnly="false" style="width: 60px;" maxlength="4" />
                            </div>
                          </td>
                          <td>
                            {{obj.onlineCurrentSNo}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row" *ngIf="_sNoPlaceMapping.length>0">
                  <div class="form-group col-4 float-md-right">
                    <button type="button" class="float-right m-2" pButton (click)="SaveUpdateSNo()" label="Save"></button>
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Header III">
                <ng-template pTemplate="header">
                  <i class="pi pi-search"></i>
                  <span>Print Setting</span>
                  <i class="pi pi-cog"></i>
                </ng-template>
                <div class="card cardheight">
                  <div class="card-body main-content">
                    <div class="row">
                      <div class="col-12" style="float:right;">
                        <button type="button" value="Print" class="btn btn-primary float-right" (click)="printPirview()">Print Pirview</button>
                      </div>

                    </div>
                    <div class="row">
                      <div class="form-group col-4">
                        <label>Place Name</label>
                        <select name="PlaceId" class="form-control" [(ngModel)]="dropdowncompanyId" (change)="PlaceChangeEventPrintSetting()">
                          <option value="0">Select Place</option>
                          <option *ngFor="let obj of _companyViewModel" [ngValue]="obj.companyId">{{obj.companyName}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6" style="border: 1px gray solid;margin-right: 77px;padding: 5px;">
                        <div class="col-12">
                          <table style="width: 100%;">
                            <tr>
                              <td colspan="2">
                                <div style=" text-align: center; font-size: 16px; font-weight: bold;">Top Header</div>
                              </td>
                            </tr>
                            <tr *ngFor="let obj of _printSetting.printHeader; let i = index">
                              <td>
                                <input type="text" [(ngModel)]="obj.headerName" style="text-transform: uppercase;text-align:center;" class="form-control ticket-input" pTooltip="Add Header" />
                              </td>
                              <td style="font-size: 30px; font-weight: bold; text-align: center;width: 60px;cursor: pointer;">
                                <div *ngIf="i === _printSetting.printHeader.length - 1" (click)="AddMoreRow()">+</div>
                                <div *ngIf="_printSetting.printHeader.length === 0" (click)="AddMoreRow()">+</div>
                                <div *ngIf="_printSetting.printHeader.length !== 0 && i !== _printSetting.printHeader.length - 1" class="ti-trash deleteIcon" (click)="DeleteRow(i)"></div>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-12">
                          <table style="width: 100%;">
                            <tr>
                              <td colspan="2">
                                <div>
                                  Any Comment On Top Line
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input type="text" [(ngModel)]="_printSetting.showCommentonTop" style="text-transform: uppercase;text-align:center;" class="form-control ticket-input" pTooltip="Any Comment On Top Line" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label for="validationServer05" class="form-label">Show Visitor Address</label>
                                <div style="margin-top: 12px;">
                                  <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1" name="showVisitorAddress" class="custom-control-input"
                                           [(ngModel)]="_printSetting.showVisitorAddress"
                                           #showVisitorAddress="ngModel"
                                           [value]="true">
                                    <label class="custom-control-label" for="customRadioInline1">Yes</label>
                                  </div>
                                  <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2" name="showVisitorAddress" class="custom-control-input"
                                           [(ngModel)]="_printSetting.showVisitorAddress"
                                           #showVisitorAddress="ngModel"
                                           [value]="false">
                                    <label class="custom-control-label" for="customRadioInline2">No</label>
                                  </div>
                                </div>

                              </td>
                            </tr>

                          </table>

                        </div>
                        <div class="col-12">
                          <table style="width:100%;">
                            <tr>
                              <td>
                                <div>
                                  <label for="validationServer05" class="form-label">Show If Any Remark</label>
                                  <div style="margin-top: 12px;">
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="customRadioInline11" name="showRemark" class="custom-control-input"
                                             [(ngModel)]="_printSetting.showRemark"
                                             #showRemark="ngModel"
                                             [value]="true">
                                      <label class="custom-control-label" for="customRadioInline11">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="customRadioInline21" name="showRemark" class="custom-control-input"
                                             [(ngModel)]="_printSetting.showRemark"
                                             #showRemark="ngModel"
                                             [value]="false">
                                      <label class="custom-control-label" for="customRadioInline21">No</label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr *ngIf="_printSetting.showRemark">
                              <td colspan="2">
                                <div>
                                  Add Remark
                                </div>
                              </td>
                            </tr>
                            <tr *ngIf="_printSetting.showRemark">
                              <td>
                                <input type="text" [(ngModel)]="_printSetting.remarkComment" style="text-align:center;" class="form-control ticket-input" pTooltip="Add Remark" />
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div class="col-12">
                          <table style="width:100%;">
                            <tr>
                              <td colspan="4">
                                <div>
                                  <label for="validationServer05" class="form-label">Show Signature</label>
                                  <div style="margin-top: 12px;">
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="customRadioInline112" name="showSignature" class="custom-control-input"
                                             [(ngModel)]="_printSetting.showSignature"
                                             #showSignature="ngModel"
                                             [value]="true">
                                      <label class="custom-control-label" for="customRadioInline112">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="customRadioInline212" name="showSignature" class="custom-control-input"
                                             [(ngModel)]="_printSetting.showSignature"
                                             #showSignature="ngModel"
                                             [value]="false">
                                      <label class="custom-control-label" for="customRadioInline212">No</label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table style="width:100%;" *ngIf="_printSetting.showSignature">
                            <tr>
                              <td colspan="2">
                                <div>
                                  Add Signature Address
                                </div>
                              </td>
                            </tr>
                            <tr *ngFor="let obj of _printSetting.signatureHeaderName; let i = index">
                              <td>
                                <input type="text" [(ngModel)]="obj.signatureHeaderName" style="text-align:center;" class="form-control ticket-input" pTooltip=" Add Signature Address" />
                              </td>
                              <td style="font-size: 30px; font-weight: bold; text-align: center;width: 60px;cursor: pointer;">
                                <div *ngIf="i === _printSetting.signatureHeaderName.length - 1" (click)="AddMoreSignatureRow()">+</div>
                                <div *ngIf="_printSetting.signatureHeaderName.length === 0" (click)="AddMoreSignatureRow()">+</div>
                                <div *ngIf="_printSetting.signatureHeaderName.length !== 0 && i !== _printSetting.signatureHeaderName.length - 1" class="ti-trash deleteIcon" (click)="DeleteSignatureRow(i)"></div>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div class="col-12">
                          <table style="width:100%;">
                            <tr>
                              <td colspan="4">
                                <div>
                                  <label for="validationServer05" class="form-label">Show If Any Notes</label>
                                  <div style="margin-top: 12px;">
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="customRadioInline1121" name="showAnyNotes" class="custom-control-input"
                                             [(ngModel)]="_printSetting.showAnyNotes"
                                             #showAnyNotes="ngModel"
                                             [value]="true">
                                      <label class="custom-control-label" for="customRadioInline1121">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="customRadioInline2121" name="showAnyNotes" class="custom-control-input"
                                             [(ngModel)]="_printSetting.showAnyNotes"
                                             #showAnyNotes="ngModel"
                                             [value]="false">
                                      <label class="custom-control-label" for="customRadioInline2121">No</label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table style="width:100%;" *ngIf="_printSetting.showAnyNotes">
                            <tr>
                              <td colspan="2">
                                <div>
                                  Add Notes
                                </div>
                              </td>
                            </tr>
                            <tr *ngFor="let obj of _printSetting.notes; let i = index">
                              <td>
                                <input type="text" [(ngModel)]="obj.notes" style="text-align:left;" class="form-control ticket-input" pTooltip="Notes" />
                              </td>
                              <td style="font-size: 30px; font-weight: bold; text-align: center;width: 60px;cursor: pointer;">
                                <div *ngIf="i === _printSetting.notes.length - 1" (click)="AddMoreNoteRow()">+</div>
                                <div *ngIf="_printSetting.notes.length === 0" (click)="AddMoreNoteRow()">+</div>
                                <div *ngIf="_printSetting.notes.length !== 0 && i !== _printSetting.notes.length - 1" class="ti-trash deleteIcon" (click)="DeleteNoteRow(i)"></div>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-12">
                          <table style="width:100%;">
                            <!--<tr>
                              <td>
                                <input type="text" [(ngModel)]="_printSetting.showWebsiteName" placeholder="Enter Website Name and show on Ticket."
                                       style="text-align:center;" class="form-control ticket-input" pTooltip="Show Website Name" />
                              </td>
                            </tr>-->
                            <tr>
                              <td>
                                <input type="text" [(ngModel)]="_printSetting.showPhoneNumber" placeholder="Enter Phone Number and show on Ticket."
                                       style="text-align:center;" class="form-control ticket-input" pTooltip="Show Phone Number" />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="col-5" style="border:1px gray solid;">
                        <html>
                        <head>
                          <link rel="stylesheet" />
                          <style>
                            .orderdetail td {
                              padding: 0px;
                            }

                            .table12 {
                              border-collapse: collapse;
                            }

                              .table12 th, .table12 td {
                                border: 1px solid black;
                              }

                            .orderdetailatt {
                              font-weight: bold;
                              border-right: 1px solid grey;
                              text-align: right
                            }
                          </style>
                          <style type="text/css" media="print">
                            @page {
                              size: auto;
                              margin: 0mm;
                            }
                          </style>
                        </head>
                        <body style="font-family:verdana;margin: 0px;font-size: 9px;">
                          <div style="width:270px;border: 1px solid black;">
                            <div style=" text-align: center;font-size: 10px;font-weight: bold;text-transform: uppercase;" *ngFor="let obj of _printSetting.printHeader">{{obj.headerName}}</div>
                            <div style="font-size: 10px;font-weight: bold;" *ngIf="_printSetting.showCommentonTop">
                              <div style="border-top: solid 1px black;">
                                {{_printSetting.showCommentonTop}}
                              </div>
                            </div>
                            <!--<div style="text-align:center;font-size:10px;font-weight:bold;">CONSERVATION SOCIETY</div>-->
                            <table class="table12" style="font-size:11px;margin-left: 0px;width:270px;font-weight: bold;">
                              <tr><td colspan="2">Date:XX-XX-XXXX</td><td colspan="2">Time:XX:XX PM</td></tr>
                              <tr><td colspan="2">Ticket No</td><td colspan="2">XXXXXX</td></tr>
                              <tr><td colspan="2">Name</td><td colspan="2">XXX</td></tr>
                              <tr>
                                <td colspan="2">Mobile No</td>
                                <td colspan="2">XXXXXXX</td>
                              </tr>
                              <tr *ngIf="_printSetting.showVisitorAddress">
                                <td colspan="4">
                                  Address:- Example XXXXXXXXX.
                                </td>
                              </tr>
                              <tr><td colspan="2">No of visitors</td><td colspan="2">XX</td></tr>
                              <tr> <td>Category</td><td>No</td><td>Tariff</td><td>Total</td></tr>
                              <tr><td>Visitor</td><td>1</td><td>XX</td><td>XX</td></tr>
                              <tr><td>Student With I Card</td><td>1</td><td>XX</td><td>XX</td></tr>
                              <tr><td colspan="3">Total Amount</td><td>XX</td></tr>
                            </table>
                            <div style="text-align: center;margin-top: 4px;font-size: 12px;font-weight: bold;" *ngIf="_printSetting.showRemark">
                              <div>
                                {{_printSetting.remarkComment}}
                              </div>
                            </div>
                            <div *ngIf="_printSetting.showSignature" style="text-align: right;margin-top: 4px;font-size: 12px;font-weight: bold;width:130px;width: 198px;margin-left: 70px;margin-bottom: 15px;    margin-top: 10px;">
                              <div style="text-align:center;">
                                <div *ngFor="let obj of _printSetting.signatureHeaderName">
                                  {{obj.signatureHeaderName}}
                                </div>
                              </div>
                            </div>
                            <div style="text-align: left;margin-top: 4px;font-size: 12px;font-weight: bold;" *ngFor="let obj of _printSetting.notes">{{obj.notes}}</div>
                            <div style="text-align: left;margin-top: 4px;font-size: 12px;font-weight: bold;" *ngIf="_printSetting.showWebsiteName">{{_printSetting.showWebsiteName}}</div>
                            <div style="text-align: left;margin-top: 4px;font-size: 12px;font-weight: bold;" *ngIf="_printSetting.showPhoneNumber">{{_printSetting.showPhoneNumber}}</div>
                          </div>
                        </body>
                      </html>

                    </div>
                  </div>
                    <div class="row">
                      <div class="form-group col-4 float-md-right">
                        <button type="button" class="float-right m-2" pButton (click)="SavePrintSetting()" label="Save"></button>
                      </div>
                    </div>
                  </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
  </div>
</div>
</div>
