import { Component, OnInit } from '@angular/core';
import { Company } from '../../../model/comapny';
import { MessageService } from 'primeng/api';
import { CompanyService} from '../../Service/CompanyService'
import { Router, ActivatedRoute } from '@angular/router';
import { authenticationService} from '../../../login/authenticationService.service';
@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css']
})
export class CreateCompanyComponent implements OnInit {
  company = new Company();
  constructor(private messageService: MessageService, private companyService: CompanyService
    , private router: Router, private route: ActivatedRoute, private authService: authenticationService) { }
  showLoader: boolean = false;
  ngOnInit(): void {
    document.body.className = "sidebar-icon-only";  
    this.company = new Company();

    var id = this.route.snapshot.params['id'];
    if (id) {
      this.GetSingleCompany(id);
    }

  }

  GoToCompanyPage() {
    this.router.navigate(['company']);
  }

  onSubmit() {
    this.showLoader = true;
    this.company.loginUserId = this.authService.userId;
    console.log(this.company);
    this.companyService.SaveUpdateCompany(this.company).subscribe(m => {
      if (m.statusCode == 200) {
        this.company = new Company();
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Company', detail: m.result });
        })
        this.router.navigate(['company']);
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'warn', summary: 'warn', detail: m.result });
        })
        this.showLoader = false;
      }
    })
  }

  GetSingleCompany(companyId: number) {
    this.company = new Company();
    this.companyService.getSingelCompany(companyId).subscribe(m => {
      if (m.statusCode == 200) {
        this.company = m.result;
      }
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
