import { Component, OnInit } from '@angular/core';
import { authenticationService } from '../../../login/authenticationService.service';

import { MessageService } from 'primeng/api';
import { CompanyService } from '../../Service/CompanyService';
import { CompanyModelView } from '../../../model/comapny';
import { Observable } from 'rxjs';
import { PlaceMapping } from '../../../model/place-mapping';
import { SNoPlaceMapping } from '../../../model/SNoPlaceMapping';
import { PrintHeader, PrintSetting, Signature, Notes } from '../../../model/Setting';
import { PrintSettingService} from '../../Service/PrintSettingService';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  showLoader: boolean = false;
  dropdowncompanyId: number = 0;
  _companyViewModel: Array<CompanyModelView>;
  _placeMapping: Array<PlaceMapping>;
  _sNoPlaceMapping: Array<SNoPlaceMapping>;
  _printSetting: PrintSetting;
  _printContent: string;
  constructor(private authService: authenticationService
    , private _companyService: CompanyService
    , private messageService: MessageService
    , private printSettingService: PrintSettingService) { }

  ngOnInit(): void {
    document.body.className = "sidebar-icon-only";
    this._companyViewModel = new Array<CompanyModelView>();
    this._placeMapping = new Array<PlaceMapping>();
    this._sNoPlaceMapping = new Array<SNoPlaceMapping>();
  
    this._printSetting = new PrintSetting();
    this._printSetting.printHeader = new Array<PrintHeader>();
    this._printSetting.signatureHeaderName = new Array<Signature>();
    this._printSetting.notes = new Array<Notes>();

    this.GetUserPlaceMapping();
    //this.printPirview();

    //this._printSetting.printHeader.push({ id: 1, headerName: '' });
    //this._printSetting.signatureHeaderName.push({ id: 1, signatureHeaderName: '' });
    //this._printSetting.notes.push({ id: 1, notes: '' });
  }

  GetUserPlaceMapping() {
    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompany(this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
        if (this._companyViewModel != null && this._companyViewModel.length > 0) {
          this.dropdowncompanyId = this._companyViewModel[0].companyId;
          this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
        }

        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    })
  }

  GetAllPlaceVisitorFee(companyId: number) {
    this._placeMapping = [];
    this.showLoader = true;
    this._companyService.GetAllPlaceMappingData(companyId, this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._placeMapping = m.result;
        this.showLoader = false;
      }
      else {
        if (m.statusCode == 404) {
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message visitor type does not map with company. Please contact with Developer.' });
          })
        }
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  PlaceChangeEvent() {
    console.log(this.dropdowncompanyId)
    this.GetAllPlaceVisitorFee(this.dropdowncompanyId);
  }


  SaveUpdateVisitorFee() {
    this.showLoader = true;
    this._companyService.SaveUpdateVisitorFee(this.authService.userId,this._placeMapping).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  onKeypressEvent(event: any) {
    if (event.target.value.length > 3) {
      return false;
    }
  }

  SaveUpdateSNo() {
    this.showLoader = true;
    this._companyService.SaveUpdateSerialNo(this.authService.userId, this._sNoPlaceMapping).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  GetSNoWithPlace() {
    this.showLoader = true;
    this._companyService.GetSNoMapping(this.authService.userId).subscribe(x => {
      if (x.statusCode == 200) {
        this._sNoPlaceMapping = x.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail:'Get all S. No Place.' });
        })
      }
    }, error => {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
        })
    })
  }

  handleChange(e) {
    if (e.index == 0) {

    }
    else if (e.index == 1) {
      this.GetSNoWithPlace();
    }
    else if (e.index == 2) {
      this.GetPrintSetting(this.dropdowncompanyId);
    }
  }

  printPirview() {
    var topHeader = "";
    var body = '<html><head>  <link href="dist/css/font-awesome/css/font-awesome.min.css" rel="stylesheet" /> <style>.orderdetail td{padding: 0px;}.table12 {border-collapse: collapse;} .table12 th, .table12 td {border: 1px solid black;}.orderdetailatt{font-weight:bold;border-right:1px solid grey;text-align:right}</style><title>Print Window</title><style type="text/css" media="print">\
                        @page {size: auto;   /* auto is the initial value */ margin: 0mm;  /* this affects the margin in the printer settings */}</style></head><body onload="window.print()" style="font-family:verdana;margin: 0px;font-size: 9px;">\ '
    body += '<div style="width:270px;">';
    this._printSetting.printHeader.forEach(function (value) {
      body += '<div style = "text-align:center;font-size:10px;font-weight:bold;text-transform: uppercase;" > ' + value.headerName + ' </div>';
    });
    if (this._printSetting.showCommentonTop) {
      body += '<div style="font-size: 10px;font-weight: bold;"><div style="border-top: solid 1px black;" >' + this._printSetting.showCommentonTop + '</div></div>'
    }
    body += '<table class="table12" style="font-size:11px;margin-left: 0px;width:270px;font-weight: bold;">'
    body += '<tr> <td colspan="2"> Date: XX - XX - XXXX </td><td colspan="2">Time:XX:XX PM</td> </tr>'
    body += '<tr> <td colspan="2"> Ticket No </td><td colspan="2">XXXXX</td> </tr>'
    body += '<tr> <td colspan="2"> Name </td><td colspan="2">XXXXXX</td> </tr>'
    body += '<tr>'
    body += '<td colspan="2"> Mobile No </td>'
    body += '<td colspan = "2" >XXXXXXXXXXX</td>'
    body += '</tr>'
    if (this._printSetting.showVisitorAddress) {
      body += '<tr><td colspan="4">Address: Example XXXXXX,XXX,XXXXXXXX.</td></tr>'
    }
    body += '<tr><td colspan="2">No of visitors</td><td colspan="2">XX</td></tr>'
    body += '<tr> <td colspan="2"> No of visitors </td><td colspan="2">XX</td> </tr>'
    body += '<tr> <td>Category </td><td>No</td> <td>Tariff </td><td>Total</td> </tr>'
    body += '<tr> <td>Visitor </td><td>XX</td > <td>XX </td><td>XX</td> </tr>'
    body += '<tr> <td>Student With I Card </td><td>XX</td> <td>XX </td><td>XX</td> </tr>'
    body += '<tr> <td colspan="3"> Total Amount </td><td>XX</td> </tr>'
    body += '</table>'
    if (this._printSetting.showRemark && this._printSetting.remarkComment) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;"><div>' + this._printSetting.remarkComment+'</div></div>'
    }
    if (this._printSetting.showSignature) {
      body += '<div style="text-align: right;margin-top: 4px;font-size: 11px;font-weight: bold;width:130px;width: 198px;margin-left: 70px;margin-bottom: 15px;margin-top: 10px;">'
      body += '<div>'
      this._printSetting.signatureHeaderName.forEach(function (value) {
      body += '<div> ' + value.signatureHeaderName + ' </div>';
      });
      body += '</div>'
      body += '</div>'
    }

    if (this._printSetting.showAnyNotes) {
      this._printSetting.notes.forEach(function (value) {
        body += '<div style="text-align: left;margin-top: 4px;font-size: 11px;font-weight: bold;">'+value.notes+'</div>'
      });
     
    }
    if (this._printSetting.showWebsiteName) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;font-weight: bold;">Website: ' + this._printSetting.showWebsiteName+'</div>'
    }
    if (this._printSetting.showPhoneNumber) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;font-weight: bold;">Phone No: ' + this._printSetting.showPhoneNumber + '</div>'
    }
    body += "</div></body></html>";

    this._printContent = body;
    this.printContent(body);
  }

  printContent(html) {
    
    var newWin = window.open('', 'Print Window', 'height=600,width=800');
    newWin.document.write(html);
    newWin.document.close();
  }

  AddMoreRow() {
    if (this._printSetting.printHeader.length <= 10) {
      let index = this._printSetting.printHeader.length;
      this._printSetting.printHeader.push({ id: index, headerName: '' });
    }
  }

  DeleteRow(index) {
    this._printSetting.printHeader.splice(index, 1);
  }

  AddMoreSignatureRow() {
    if (this._printSetting.signatureHeaderName.length <= 10) {
      let index = this._printSetting.signatureHeaderName.length;
      this._printSetting.signatureHeaderName.push({ id: index, signatureHeaderName: '' });
    }
  }

  DeleteSignatureRow(index) {
    this._printSetting.signatureHeaderName.splice(index, 1);
  }

  AddMoreNoteRow() {
    if (this._printSetting.notes.length <= 10) {
      let index = this._printSetting.notes.length;
      this._printSetting.notes.push({ id: index, notes: '' });
    }
  }

  DeleteNoteRow(index) {
    this._printSetting.notes.splice(index, 1);
  }

  GetPrintSetting(placeId: number) {
    this._printSetting = new PrintSetting();
    this._printSetting.printHeader = new Array<PrintHeader>();
    this._printSetting.signatureHeaderName = new Array<Signature>();
    this._printSetting.notes = new Array<Notes>();

    this.printSettingService.GetPrintSetting(placeId).subscribe(m => {
      console.log(m.statusCode);
      if (m.statusCode == 200) {
        this._printSetting = m.result;
      
        if (this._printSetting.printHeader.length==0) {
          this._printSetting.printHeader = new Array<PrintHeader>();
          this._printSetting.printHeader.push({ id: 1, headerName: '' });
        }
        if (this._printSetting.signatureHeaderName.length==0) {
          this._printSetting.signatureHeaderName = new Array<Signature>();
          this._printSetting.signatureHeaderName.push({ id: 1, signatureHeaderName: '' });
        }
        if (this._printSetting.notes.length==0) {
          this._printSetting.notes = new Array<Notes>();
          this._printSetting.notes.push({ id: 1, notes: '' });
        }
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get Print Setting succefully.' });
        })
      }
      else {
        this._printSetting = new PrintSetting();
        this._printSetting.printHeader = new Array<PrintHeader>();
        this._printSetting.printHeader.push({ id: 1, headerName: '' });
        this._printSetting.signatureHeaderName = new Array<Signature>();
        this._printSetting.signatureHeaderName.push({ id: 1, signatureHeaderName: '' });
        this._printSetting.notes = new Array<Notes>();
        this._printSetting.notes.push({ id: 1, notes: '' });
      }
     
    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }


  PlaceChangeEventPrintSetting() {
    this.GetPrintSetting(this.dropdowncompanyId);
  }


  SavePrintSetting() {
    this._printSetting.placeId = this.dropdowncompanyId;
    this.printSettingService.SavePrintSetting(this._printSetting).subscribe(x => {
      if (x.statusCode == 200) {
        this._sNoPlaceMapping = x.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Print Setting has been save succefully.' });
        })
      }
    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })

  }


}
