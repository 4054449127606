import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SessionService } from '../../core/storage/sessionservice';
import { BASE_API_URL } from '../../core/environment.tokens';
import { authenticationService } from '../../login/authenticationService.service';
import {UserModel,UserViewModel,UserPlaceMapping } from '../../model/userModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  header: HttpHeaders;
  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string, private http: HttpClient,
    private sessionService: SessionService, private _auth: authenticationService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this._auth.userToken
    });
  }

  saveUpdateUser(userModel: UserModel) {
    const url = `${this._auth.baseURL}User/SaveUpdateUser`;
    let headers = this.header;
    return this.http.post<any>(url, userModel, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  UpdatePassword(userModel: any) {
    const url = `${this._auth.baseURL}User/UpdatePassword`;
    let headers = this.header;
    return this.http.post<any>(url, userModel, { headers })
      .pipe(map(x => {
        return x;
      }));
  }

  GetAllUser(loginUserId:number) {
    const url = `${this._auth.baseURL}User/GetAllUser/` + loginUserId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  GetUserByuserId(userId: number) {
    const url = `${this._auth.baseURL}User/GetUserByUserId/` + userId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  GetUserPlaceMapping(userId: number,logingUserId:number) {
    const url = `${this._auth.baseURL}User/GetUserPlaceMapping/` + userId + '/' + logingUserId;
    let headers = this.header;
    return this.http.get<any>(url, { headers })
      .pipe(map(x => {
        return x;
      }))
  }

  saveUpdatePlaceUserMapping(userPlaceMappingModel: Array<UserPlaceMapping>) {
    const url = `${this._auth.baseURL}User/SaveUpdatePlaceUserMapping`;
    let headers = this.header;
    return this.http.post<any>(url, userPlaceMappingModel, { headers })
      .pipe(map(x => {
        return x;
      }));
  }
}
