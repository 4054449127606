import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VisitorcategoryComponent } from './visitorcategory/visitorcategory.component';
import { CreateVisitorcategoryComponent } from './create-visitorcategory/create-visitorcategory.component';


const routes: Routes = [
  { path: 'visitorcategory', component: VisitorcategoryComponent },
  { path: 'visitorcategory-create', component: CreateVisitorcategoryComponent }
  , { path: 'visitorcategory-create/:id', component: CreateVisitorcategoryComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VisitorCategoryRoutingModule { }
