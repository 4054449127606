
<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-navmenu></app-navmenu>
    <div class="loading" *ngIf="showLoader">Loading&#8230;</div>
    <div class="main-panel">
      <p-toast position="top-right" key="tr" style="z-index:999999"></p-toast>
      <div class="content-wrapper">
        <div class="card mb-2">
          <div class="card-body">
            <div class="float-lg-left">
              <h4 class="card-title">Home > Create Role</h4>
            </div>
            <div class="float-lg-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="GoToRolesPage()">Back</button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form class="form-sample" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
              <div class="row">
                <div class="form-group col-4">
                  <label>Role Name</label>
                  <input type="text" name="roleName" class="form-control" [(ngModel)]="_roleModel.roleName" #roleName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && roleName.invalid }" required>
                  <div *ngIf="f.submitted && roleName.invalid" class="invalid-feedback">
                    <div *ngIf="roleName.errors.required">First Name is required</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="validationServer05" class="form-label">Manager</label>
                  <div style="margin-top: 12px;">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline1" name="isManager" class="custom-control-input" required
                             [(ngModel)]="_roleModel.isManager"
                             #isManager="ngModel"
                             [value]="true">
                      <label class="custom-control-label" for="customRadioInline1">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline2" name="isManager" class="custom-control-input" required
                             [(ngModel)]="_roleModel.isManager"
                             #isManager="ngModel"
                             [value]="false">
                      <label class="custom-control-label" for="customRadioInline2">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-primary" type="submit">Save</button>
                <button class="btn btn-primary ml-2" (click)="GoToRolesPage()" type="button">Cancel</button>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

