import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from './../../Service/CompanyService';
import { CompanyModelView, Company } from '../../../model/comapny';
import { PlaceMapping} from '../../../model/place-mapping';
import { ConfirmationService,MessageService } from 'primeng/api';
import {authenticationService } from '../../../login/authenticationService.service';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  providers: [ConfirmationService]
})  
export class CompanyComponent implements OnInit {
  showLoader: boolean = false;
  _companyViewModel: Array<CompanyModelView>;
  _placeMapping: Array<PlaceMapping>;
  public showDialog: boolean;
 
  roleName: string;
  constructor(
    private router: Router
    , private _companyService: CompanyService, private confirmationService: ConfirmationService
    , private messageService: MessageService, private authService: authenticationService) { }

  ngOnInit(): void {
    this.roleName = this.authService.userRole;
    document.body.className = "sidebar-icon-only";  
    this._companyViewModel = new Array<CompanyModelView>();
    this._placeMapping = new Array<PlaceMapping>();
    this.showLoader = false;
    this.GetAllCompany();
  }

  Navigate(e,id) {
    if (e == 'company-create' && id == 0) {
      this.router.navigate(['company-create']);
    }
    else if (e == 'company-create' && id > 0) {
      this.router.navigate(['company-create', id]);
    }
  }

  GetAllCompany() {
    this.showLoader = true;
    this._companyViewModel = new Array<CompanyModelView>();
    this._companyService.getAllCompany(this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._companyViewModel = m.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    })
  }

  EditCompany(id:number) {
    this.Navigate('company-create', id);
  }

  DeleteCompanyconfirm(companyId: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.DeleteCompany(companyId);
      },
      reject: () => {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'info', summary: 'Rejected', detail: 'You have rejected' });
        })
      }
    });
  }

  DeleteCompany(companyId: number) {
    this.showLoader = true;
    let loginuserid: number=this.authService.userId;
    this._companyService.Delete(companyId, loginuserid).subscribe(m => {
      if (m.statusCode == 200) {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
        this.showLoader = false;
        this.GetAllCompany();
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: m.result });
        })
        this.showLoader = false;
      }
    })
  }

  GetAllPlaceMappingData(companyId: number) {
    this.showLoader = true;
    this._companyService.GetAllPlaceMappingData(companyId, this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._placeMapping = m.result;
        this.showLoader = false;
        this.showDialog = true;
       
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  GetAllPlaceVisitorFee(companyId: number) {
    this.showLoader = true;
    this._companyService.GetAllPlaceMappingData(companyId,this.authService.userId).subscribe(m => {
      if (m.statusCode == 200) {
        this._placeMapping = m.result;
        this.showLoader = false;
        
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }



  SaveUpdatePlaceMappingCategory() {
    this.showLoader = true;
    this._companyService.SaveUpdatePlaceMappingCategory(this._placeMapping).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
        this.showDialog = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

  SaveUpdateVisitorFee() {
    this.showLoader = true;
    this._companyService.SaveUpdatePlaceMappingCategory(this._placeMapping).subscribe(m => {
      if (m.statusCode == 200) {
        this.showLoader = false;
       
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
      }
      else {
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }

}
