export class Company {
  companyId: number;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  companyState: string;
  zipCode: number;
  email: string;
  phoneNo: string;
  website: string;
  isActive: boolean=true;
  loginUserId: number;
}

export class CompanyModelView {
  companyId: number;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  companyState: string;
  zipCode: number;
  email: string;
  phoneNo: string;
  website: string;
  isActive: boolean = true;
  createdBy: string;
  updateBy: string;
  createdDate: Date;
  updatedDate: Date;
  isPlaceLock: boolean = false;
}
