import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../Service/CompanyService';
import { MessageService } from 'primeng/api';
import { TicketService } from '../../Service/TicketService';
import { DatePipe } from '@angular/common';
import { OnlineTicketService } from '../../Service/onlineticketService';
import { PrintSettingService } from '../../Service/PrintSettingService';
import { ActivatedRoute, Router } from '@angular/router';
import { VisitorTickets, VisitorCategoryTypeDetail, TicketAmountDetails, VisitorsTicketDetails, CCVResponse } from '../../../model/Tickets';
import { PrintSetting, PrintHeader, Signature, Notes } from '../../../model/Setting';

@Component({
  selector: 'app-payment-successful',
  templateUrl: './payment-successful.component.html',
  styleUrls: ['./payment-successful.component.css']
})
export class PaymentSuccessfulComponent implements OnInit {
  _visitorTicket: VisitorTickets;
  showLoader: boolean = false;
  _printSetting: PrintSetting;

  constructor(private _companyService: CompanyService
    , private messageService: MessageService
    , private _visitorTicketService: TicketService, public datepipe: DatePipe
    , private printSettingService: PrintSettingService, private onlineTicketService: OnlineTicketService, private route: ActivatedRoute, private router: Router ) { }

  ngOnInit(): void {
    this._visitorTicket = new VisitorTickets();
    this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();

    this._printSetting = new PrintSetting();
    this._printSetting.printHeader = new Array<PrintHeader>();
    this._printSetting.signatureHeaderName = new Array<Signature>();
    this._printSetting.notes = new Array<Notes>();

    this._visitorTicket.cCVResponse = new CCVResponse();
    var order_id = this.route.snapshot.params['order_id'];
    if (order_id) {
      this.PrintTicket(order_id);
    }
  }

  PrintTicket(order_id: number) {
    this._visitorTicket = new VisitorTickets();
    this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
    this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
    this._visitorTicket.visitorCategoryTypeDetailCollection = new Array<VisitorCategoryTypeDetail>();
    this._visitorTicket.cCVResponse = new CCVResponse();

    this.onlineTicketService.GetOnlineTicket(order_id).subscribe(m => {
      if (m.statusCode == 200) {

        this._visitorTicket.visitorCategoryTypeDetailCollection = m.result.visitorCategoryTypeDetailCollection;
        this._visitorTicket.ticketAmountDetails = m.result.ticketAmountDetails;
        this._visitorTicket.visitorsTicketDetails = m.result.visitorsTicketDetails;
        this._visitorTicket.cCVResponse = m.result.ccvResponse;

        this.GetPrintSetting(this._visitorTicket.visitorsTicketDetails.companyId);

        if (this._visitorTicket.ticketAmountDetails == null) {
          this._visitorTicket.ticketAmountDetails = new TicketAmountDetails();
        }
        if (this._visitorTicket.visitorsTicketDetails == null) {
          this._visitorTicket.visitorsTicketDetails = new VisitorsTicketDetails();
        }

       
        this.showLoader = false;
        
        //this.printPirview();
      }
      else {
        if (m.statusCode == 404) {
          setTimeout(() => {
            this.messageService.add(
              { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message visitor type does not map with company. Please contact with Developer.' });
          })
        }
        this.showLoader = false;
      }
    }, err => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + err.message });
      })
    })
  }


  GetPrintSetting(placeId: number) {
    this.showLoader = true;
    this._printSetting = new PrintSetting();
    console.log(placeId);
    this.printSettingService.GetPrintSetting(placeId).subscribe(m => {
      console.log(m.statusCode);
      if (m.statusCode == 200) {
        this._printSetting = m.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get Print Setting succefully.' });
        })
      }
      else {
        this._printSetting = new PrintSetting();
        this._printSetting.printHeader = new Array<PrintHeader>();
        this._printSetting.signatureHeaderName = new Array<Signature>();
        this._printSetting.notes = new Array<Notes>();
      }

    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }
  convertDateToString(date: string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }
  printPirview() {

    var topHeader = "";
    var body = '<html><head>  <link href="dist/css/font-awesome/css/font-awesome.min.css" rel="stylesheet" /> <style>.orderdetail td{padding: 0px;}.table12 {border-collapse: collapse;} .table12 th, .table12 td {border: 1px solid black;}.orderdetailatt{font-weight:bold;border-right:1px solid grey;text-align:right}</style><title>Print Window</title><style type="text/css" media="print">\
                        @page {size: auto;   /* auto is the initial value */ margin: 0mm;  /* this affects the margin in the printer settings */}</style></head><body onload="window.print()" style="font-family:verdana;margin: 0px;font-size: 9px;">\ '
    body += '<div style="width:270px;">';
    this._printSetting.printHeader.forEach(function (value) {
      body += '<div style = "text-align:center;font-size:10px;font-weight:bold;text-transform: uppercase;" > ' + value.headerName + ' </div>';
    });
    if (this._printSetting.showCommentonTop) {
      body += '<div style="font-size: 10px;font-weight: bold;"><div style="border-top: solid 1px black;" >' + this._printSetting.showCommentonTop + '</div></div>'
    }
    body += '<table class="table12" style="font-size:9px;margin-left: 0px;width:270px;font-weight: bold;">'
    body += '<tr> <td colspan="2"> Date:' + this.convertDateToString(this._visitorTicket.visitorsTicketDetails.bookingDate); + ' </td>'
    body += '<td colspan="2">Time:' + this.datepipe.transform(this._visitorTicket.visitorsTicketDetails.bookingTime, 'hh:mm:ss a') + '</td> </tr>'
    body += '<tr> <td colspan="2"> Ticket No </td><td colspan="2">' + this._visitorTicket.visitorsTicketDetails.ticketNo + '</td> </tr>'
    body += '<tr> <td colspan="2"> Name </td><td colspan="2">' + this._visitorTicket.visitorsTicketDetails.visitorName + '</td> </tr>'
    body += '<tr>'
    body += '<td colspan="2"> Mobile No </td>'
    body += '<td colspan = "2" > ' + this._visitorTicket.visitorsTicketDetails.visitorMobileNo + ' </td>'
    body += '</tr>'
    if (this._printSetting.showVisitorAddress) {
      body += '<tr><td colspan="4">Address: ' + this._visitorTicket.visitorsTicketDetails.visitorAddress + '</td></tr>'
    }
    body += '<tr><td colspan="2">No of visitors</td><td colspan="2">' + this._visitorTicket.ticketAmountDetails.totalVisitor + '</td></tr>'
    body += '<tr> <td colspan="2">No of Equipment </td><td colspan="2">' + this._visitorTicket.ticketAmountDetails.totalEquipment + '</td> </tr>'

    if (this._visitorTicket.ticketAmountDetails.totalEquipment > 0) {
      body += '<tr><td colspan="4" style="text-align:center;">Equipment Details</td></tr>'
      body += '<tr> <td>Type </td><td>No</td> <td>Tariff </td><td>Total</td> </tr>';
      this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly == true || x.visitorCategoryTypeId==2).forEach(function (value) {
        body += '<tr> <td>' + value.visitorCategory + ' </td><td>' + value.numberofVisitor + '</td > <td>' + value.visitorFee + '(per/' + value.hoursInMint / 60 + ' HR)</td><td>' + value.totalAmount + '</td> </tr>'
      });
    }
    body += '<tr><td colspan="4" style="text-align:center;">Visitor Details</td></tr>'
    body += '<tr> <td>Category </td><td>No</td> <td>Tariff </td><td>Total</td> </tr>';

    this._visitorTicket.visitorCategoryTypeDetailCollection.filter(x => x.hourly == false && x.visitorCategoryTypeId == 1).forEach(function (value) {
      body += '<tr> <td>' + value.visitorCategory + ' </td><td>' + value.numberofVisitor + '</td > <td>' + value.visitorFee + ' </td><td>' + value.totalAmount + '</td> </tr>'
    });
    body += '<tr> <td colspan="3"> Total Amount </td><td>' + this._visitorTicket.ticketAmountDetails.totalFinalAmount + '</td> </tr>'
    body += '</table>'
    if (this._printSetting.showRemark && this._printSetting.remarkComment) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;"><div>' + this._printSetting.remarkComment + '</div></div>'
    }
    if (this._printSetting.showSignature) {
      body += '<div style="text-align: right;margin-top: 4px;font-size: 11px;font-weight: bold;width:130px;width: 198px;margin-left: 70px;margin-bottom: 15px;margin-top: 10px;">'
      body += '<div>'
      this._printSetting.signatureHeaderName.forEach(function (value) {
        body += '<div> ' + value.signatureHeaderName + ' </div>';
      });
      body += '</div>'
      body += '</div>'
    }
    if (this._printSetting.showAnyNotes) {
      this._printSetting.notes.forEach(function (value) {
        body += '<div style="text-align: left;margin-top: 4px;font-size: 11px;font-weight: bold;">' + value.notes + '</div>'
      });
    }
    if (this._printSetting.showWebsiteName) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;font-weight: bold;">Website: ' + this._printSetting.showWebsiteName + '</div>'
    }
    if (this._printSetting.showPhoneNumber) {
      body += '<div style="text-align: center;margin-top: 4px;font-size: 10px;font-weight: bold;">Phone No: ' + this._printSetting.showPhoneNumber + '</div>'
    }
    body += "</div></body></html>";


    this.printContent(body);

  }

  printContent(html) {
    var newWin = window.open('', 'Print Window', 'height=600,width=800');
    newWin.document.open();
    newWin.document.write(html);
    newWin.document.close();
  }

  Navigate() {
    this.router.navigateByUrl("/online-ticket");
  }
}
