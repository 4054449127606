import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { VisitorCategoryViewModel } from '../../../model/VisitorCategory';
import { VisitorCetgoryService } from '../../Service/VisitorCategoryService';
import { authenticationService} from '../../../login/authenticationService.service';

@Component({
  selector: 'app-visitorcategory',
  templateUrl: './visitorcategory.component.html',
  styleUrls: ['./visitorcategory.component.css'],
  providers: [ConfirmationService]
})
export class VisitorcategoryComponent implements OnInit {
  showLoader: boolean = false;
  visitorCategoryViewModel = new Array<VisitorCategoryViewModel>();
  constructor(private router: Router
    , private confirmationService: ConfirmationService
    , private messageService: MessageService
    , private _visitorCetgoryService: VisitorCetgoryService
    , private _authenticationService: authenticationService) { }

  ngOnInit(): void {
    this.visitorCategoryViewModel = new Array<VisitorCategoryViewModel>();
    document.body.className = "sidebar-icon-only"; this.showLoader = false;
    this.getAllVisitorCategory();
  }

  Navigate(e, id) {
    if (e == 'visitorcategory-create' && id == 0) {
      this.router.navigate(['visitorcategory-create']);
    }
    else if (e == 'visitorcategory-create' && id > 0) {
      this.router.navigate(['visitorcategory-create', id]);
    }
  }

  getAllVisitorCategory() {
    this.showLoader = true;
    this.visitorCategoryViewModel = new Array<VisitorCategoryViewModel>();
    this._visitorCetgoryService.getAllVisitorCategory().subscribe(m => {
      if (m.statusCode == 200) {
        this.visitorCategoryViewModel = m.result;
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: 'Get all company successfully.' });
        })
      }
      else {
        this.showLoader = false;
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: 'Data not found.' });
        })
      }
    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }

  EditVisitorCategory(id) {
    this.Navigate('visitorcategory-create', id);
  }

  DeletevisitorCategory(visitorCategoryId: number) {
    this.showLoader = true;
    let loginuserid: number = this._authenticationService.userId;
    this._visitorCetgoryService.Delete(visitorCategoryId, loginuserid).subscribe(m => {
      if (m.statusCode == 200) {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'success', summary: 'Success', detail: m.result });
        })
        this.showLoader = false;
        this.getAllVisitorCategory();
      }
      else {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'error', summary: 'Error', detail: m.result });
        })
        this.showLoader = false;
      }
    }, error => {
      this.showLoader = false;
      setTimeout(() => {
        this.messageService.add(
          { key: 'tr', severity: 'error', summary: 'Error', detail: 'Error Message ' + error.message });
      })
    })
  }

  Deleteconfirm(visitorCategoryId: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.DeletevisitorCategory(visitorCategoryId);
      },
      reject: () => {
        setTimeout(() => {
          this.messageService.add(
            { key: 'tr', severity: 'info', summary: 'Rejected', detail: 'You have rejected' });
        })
      }
    });
  }
}
