import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CommonService } from '../../Modules/Service/CommonService';
import { authenticationService} from '../../login/authenticationService.service';
@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavmenuComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, public cmnSrv: CommonService, private authService: authenticationService) { }
  roleName: string;

  ngOnInit(): void {
    this.roleName = this.authService.userRole;
    if (this.roleName == undefined) {
      this.roleName = '';
    }
    console.log(this.roleName);
  }
  Navigate(e) {
    console.log(e);
    if (e == 'dashboard')
    {
      this.router.navigateByUrl("/dashboard");
    }
    else if (e == 'User') {
      this.router.navigateByUrl("/User");
    }
    else if (e == 'roles') {
      this.router.navigateByUrl("/roles");
    }
    else if (e == 'role-create') {
      this.router.navigateByUrl("/role-create");
    }
    else if (e == 'company') {
      this.router.navigateByUrl("/company");
    }
    else if (e == 'visitorcategory') {
      this.router.navigateByUrl("/visitorcategory");
    }
    else if (e == 'users') {
      this.router.navigateByUrl("/users");
    } else if (e == 'setting') {
      this.router.navigateByUrl("/setting");
    } else if (e == 'ticket') {
      this.router.navigateByUrl("/ticket");
    } else if (e == 'reports') {
      this.router.navigateByUrl("/reports");
    } else if (e == 'delete-ticket') {
      this.router.navigateByUrl("/delete-ticket");
    }

  }
}
